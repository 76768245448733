import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default class ListCreatives extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignData: 'loading' }
    }


render(){

  let listItems = []
  let mm_creatives = this.props.mm_creatives
    if (this.props.mm_creatives){

        for (let key in mm_creatives) {

            let imageIcon
            let imageColor
            if (mm_creatives[key].saved_in_tb === true){
              imageIcon = 'check'
              imageColor = 'green'
            } else if (mm_creatives[key].saved_in_tb === 'queued'){
              imageIcon = 'queue'
              imageColor = '#FFD923'
            } else {
              imageIcon = 'error'
              imageColor = 'red'
            }

            //set backgroundColor grey to selected item
            let backgroundColor = this.props.selectedCreative && this.props.selectedCreative.creative_id === mm_creatives[key].creative_id ? '#ddd' : ''

            listItems.push(
                <ListItem key={key} button onClick={ ()=> this.props.passedSetState('selectedCreative', mm_creatives[key]) }
                  style={{border: '1px #ddd solid', borderRadius: '3px', backgroundColor: backgroundColor, overflow: 'hidden'}}>
                  <ListItemIcon><i style={{color: imageColor, cursor: 'pointer'}}className="material-icons d-flex align-items-center" >{imageIcon}</i></ListItemIcon>
                  <ListItemText primary={mm_creatives[key].creative_name} />
                </ListItem>
                          )
        }


      } else {
        listItems.push(<ListItem style={{border: '1px #ddd solid', borderRadius: '3px'}}>
                          <ListItemIcon>
                            <i className="material-icons d-flex align-items-center" >access_time</i>
                          </ListItemIcon>
                          <ListItemText  primary='No creatives. Check back a day after you add the media math campaign id.' />
                       </ListItem>
                    )
          }

      return (
        <div>
            <List>
              {listItems}
            </List>
        </div>
      )
  }
}

import React from 'react';


export default function EditPageHeader(props){

    return(
      <div className='col-12' style={{ paddingBottom: '10px'}} >
        <h4 style={{margin: '0px'}}>{props.campaignName}</h4>
        <b style={{color: 'grey', fontSize: '12px'}}>{props.pageTitle}</b>
      </div>
    );

}

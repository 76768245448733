import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import Button from '@material-ui/core/Button';

export default class UploadStorageFile extends Component {

    constructor(props) {
      super(props);
      this.state = { fileUploads: 'loading', loading: false}
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

    componentDidMount(){

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.campaignDetails.ssx_id + '/externalReports/' + this.props.reportId + '/fileUploads' )
        firebaseListener.on('value', (snap)=> {
          let fileUploads = snap.val() ? snap.val() : []
          this.setState({ fileUploads: fileUploads, firebaseListener: firebaseListener})
        });

      }


      uploadReport = (e) =>{

        console.log('uploadReport')

        this.setState({loading: true})
        let today = new Date().getTime()
        let fileUploads = this.state.fileUploads
        let reportID = this.props.reportId
        let ssxID = this.props.campaignDetails.ssx_id
        let file = e.target.files[0]
        let storageref = firebase.storage().ref('externalReports/' + reportID + '/fileUploads/' + today +  '_' + file.name )
        let task = storageref.put(file)
        //not sure how to do fat arrow functions, so need to set this to self
        let self = this

        task.on('state_changed',
        function progress (snapshot) {

        }, function error (err){

        console.log(err)

        }, function complete (data){

          let currentIndex =  fileUploads.length ? fileUploads.length : 0

          storageref.getDownloadURL().then( url => {
            firebase.database().ref('campaigns/' +  ssxID + '/externalReports/' + reportID  + '/fileUploads/' + currentIndex ).update({url: url, fileName: file.name}, () => {
              self.setState({loading: false})
            });

            })

        }

        )
      }


render(){
//  {this.state.loading === true ?
  if (this.state.fileUploads === 'loading'){
    return(
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    )
  } else {

    const styles = {
      uploadInput: { display: 'none'},
                  };
    const fileUploads = this.state.fileUploads.map((current, index) => {
          return (
          <li key={current.url}>
            <a href={current.url} target='_blank' >{current.fileName}</a>
          </li>
          )
      })

      return (
    <div className='row boxShadow' style={{padding: '15px', margin: '0px', height: '100%'}}>
        <div className='col-12' style={{padding: '0px', margin: '0px'}}>
            <b style={{fontSize: '17px'}}>Upload Files For Storage:</b><br />
            <label className='label'>Store excels, csvs, pdfs, images, etc. for quick reference later.</label>
        </div>

        <div className='col-12' style={{padding: '0px 0px 40px 0px', margin: '0px'}}>
          {fileUploads}
        </div>

        <div style={{position: 'absolute', bottom: '0px', left: '0px', right: '0px'}}>
            {this.state.loading === true ?
              <div style={{padding: '20px', width: '100%'}}>
                    <LinearProgress  />
              </div>
              :

              <div style={{padding: '10px'}}>

                <input
                   style={styles.uploadInput}
                   id="flat-button-file"
                   multiple
                   type="file"
                   onChange={ this.uploadReport}
                 />

                <label htmlFor="flat-button-file">
                  <Button component="span" >
                  <i className="material-icons" >file_upload</i> Upload File(s)
                  </Button>
                </label>
              </div>
              }
          </div>

      </div>

      )
    }
  }
}

import React, {Component} from 'react';
import Users from './users/users'

export default class Admin extends Component {

    constructor(props) {
      super(props);
      this.state = { test: null}
    }

render(){

      return (
      <div className='row no-gutters' style={{margin: '0px', padding: '10px'}}>
          <Users />
      </div>
      )
      
    }
}

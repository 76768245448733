import React, {Component} from 'react';
import * as firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import LoadingButton from './loadingbutton';
import Button from '@material-ui/core/Button';

export default class AccountBox extends Component {

    constructor(props) {
      super(props);

      this.state = { email : '' , password : '', emailstatus: 'Email' , passwordstatus: 'Password', buttonStatus: 'regular', errormessage: '' };

    }


    clickedLogInCreateAccount(e, clicked){
      //prevent the form from submitting
      e.preventDefault()

      var email = this.state.email
      var password = this.state.password



      if (password === '') {
       this.setState({
         errormessage: <span className="red-text">Insert Password</span>
       })
     }

      if (  email === ''){
          this.setState({
            errormessage: <span className="red-text">Insert Email</span>
          })
      }


      if (email !== '' && password !== '')  {

        this.setState({ buttonStatus: 'loading', errormessage: '', })

          if (clicked === 'LogIn' ){

            firebase.auth().signInWithEmailAndPassword(email, password).then(user => {
            }, error => {
                this.setState({
                  loading: false,
                  errormessage: error.message,
                  emailstatus: null,
                  passwordstatus: null
                })
              });
          } else {

         }
      }
    }

    resetPassword(){

      var auth = firebase.auth();
      if (this.state.email === ''){
        this.setState({ errormessage: <span className="red-text">Insert Email</span> })
      } else {

        auth.sendPasswordResetEmail(this.state.email).then( ()  => {
          this.setState({ errormessage: <span className="red-text">Email Sent</span> })
        }).catch( (error) => {
            this.setState({ errormessage: <span className="red-text">{error.message}</span> })
          console.log(error)
        });

      }

    }


render(){

  if (this.props.firebaseUID == null) {

return (

    <div id="loginbox" className="col-12"   >
        <div className="row" style={{ padding:'5px'}}>

        <form onSubmit={ event => this.clickedLogInCreateAccount(event,'LogIn')} style={{width: '100%'}}>
          <div className="col-12">
          <TextField
              placeholder="john@gmail.com"
              fullWidth={true}
              label={this.state.emailstatus}
              type="email"
              value={this.state.email}
              onChange={ e => this.setState({ email : e.target.value})}
            />
          </div>

          <div className="col-12">
          <TextField
              placeholder="***"
              fullWidth={true}
              label={this.state.passwordstatus}
              type="password"
              value={this.state.password}
              onChange={ e => this.setState({ password : e.target.value})}
            />
          </div>

          <div>
          <span className='col-12 red-text center'><b>
          {this.state.errormessage}
          </b></span>
          </div>

          <div className="col-12 d-flex justify-content-center">
            <LoadingButton buttonStatus={this.state.buttonStatus} variant='raised' regularLabel='Log In' savedLabel='Logged In!' regularButtonClick={event => this.clickedLogInCreateAccount(event,'LogIn')} />
          </div>

          <div className="col-12 d-flex justify-content-center" style={{padding: '20px'}}>
            <span onClick={ ()=> this.resetPassword() } style={{cursor: 'pointer', fontSize: '12px', color: 'blue'}}>Reset password</span>
          </div>

          </form>
       </div>

      </div>

      );

  } else {

    return (
      <div style={{ padding: '20px 40px'}}>
      <h5 style={{margin: '0px'}}>{this.props.email}</h5><br />
      <Button variant='raised' color='primary' onClick={event => firebase.auth().signOut() }>
        Sign Out
      </Button>
      </div>

    );
  }
}
}

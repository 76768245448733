import React, { Component } from 'react';
import './grid.css'
import './style.css'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import * as firebase from 'firebase';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { createMuiTheme } from '@material-ui/core/styles';
import  CoreMuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import Navbar from './components/navbar/navbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import AccountBox from './components/util/accountbox';
import Admin from './components/admin/admin';
import AdminParameters from './components/admin/parameters/parameters';
import BAForm from './components/util/baform';
import ListPartners from './components/partners/listPartners';
import Partner from './components/partners/partner/partner';
import Items from './components/campaigns/edit/items/items'
import ListSQLUploads from './components/uploadFileSQL/listUploads'
import UploadFileSQL from './components/uploadFileSQL/uploadFileSQL'
import ListCampaigns from './components/campaigns/listcampaigns/listcampaigns';
import Campaign from './components/campaigns/campaign';
import ListNationalBrandCampaigns from './components/nationalbrandcampaigns/listcampaigns/listcampaigns';
import EditCampaignDetails from './components/campaigns/edit/campaignDetails/editcampaigndetails'
import EditNationalBrandCampaignDetails from './components/nationalbrandcampaigns/edit/nationalbrandcampaignDetails/editcampaigndetails'
import EditStrategyCreative from './components/campaigns/edit/strategycreative'
import EditOptimizations from './components/campaigns/edit/optimizations/editoptimizations';
import ListExternalReports from './components/campaigns/edit/externalreports/listexternalreports';
import ExternalReport from './components/campaigns/edit/externalreports/externalreport';
import MediaCD from './components/campaigns/edit/mediacd/mediacd';
import MediaPlan from './components/campaigns/edit/mediaplan/mediaplan';
import UploadFolder from './components/campaigns/edit/uploadFolder/uploadFolder';
import ListBudgetPlanner from './components/campaigns/edit/budgetPlanner/list/listBudgetPlanner';
import BudgetPlanner from './components/campaigns/edit/budgetPlanner/budgetPlanner';
import EditRedirect from './components/campaigns/edit/redirect/redirect';

const muiTheme = getMuiTheme({
      palette: {
        primary1Color: '#3E8EDE',
        accent1Color: '#EB0028',
      }
    });

    // Initialize Firebase
    var config = {
     apiKey: "AIzaSyCXXfQfJJndTxuRtu2q-XeEepx4g5cB3B4",
     authDomain: "tableau-180613.firebaseapp.com",
     databaseURL: "https://tableau-180613.firebaseio.com",
     projectId: "tableau-180613",
     storageBucket: "tableau-180613.appspot.com",
     messagingSenderId: "810298745787"
   };

//theme={theme}
//import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
  const theme = createMuiTheme({
    palette: {
       primary: {
         main: '#3E8EDE',
         contrastText: '#fff',
       },
       secondary: {
         main: '#EB0028',
         contrastText: '#fff',
       }
 },
 });
   firebase.initializeApp(config);



export default class App extends Component {


  constructor(props) {
    super(props);
    this.state = { firebaseUID : 'loading', mediaMathSessionId: 'loading' };
  }

  componentDidMount(){

        firebase.auth().onAuthStateChanged((user) => {
              if (user) { this.setState( { firebaseUID: user.uid, email: user.email })
              } else {  this.setState( { firebaseUID: null } )
                }
              });

    }

  passedSetState = (state, value) => {
    console.log(state, value)
    this.setState({[state]: value })
  }

  render() {

    if (this.state.firebaseUID ==='loading'){
      return (
        <Router>
        <div>

        <CoreMuiThemeProvider theme={theme}>
          <Navbar firebaseUID={this.state.firebaseUID} />
            <div style={{padding: '20px'}}>
              <LinearProgress  />
            </div>
        </CoreMuiThemeProvider>

        </div>
        </Router>
      );
    }  if (this.state.firebaseUID == null){
      return (
        <Router>
        <div>

        <CoreMuiThemeProvider theme={theme}>
          <div>
          <Navbar firebaseUID={this.state.firebaseUID} />
            <div style={{padding: '20px'}}>
            <AccountBox firebaseUID={this.state.firebaseUID} />
            </div>
          </div>
        </CoreMuiThemeProvider>

        </div>
        </Router>
      );
    } else  {

    return (
      <Router>
      <div>

          <Route path="/" render={(props) => (
            <CoreMuiThemeProvider theme={theme}>
              <Navbar firebaseUID={this.state.firebaseUID}  email={this.state.email }/>
            </CoreMuiThemeProvider>
              )} />

          <Route exact path="/" render={(props) => (
            <MuiThemeProvider muiTheme={muiTheme}>
              <ListCampaigns firebaseUID={this.state.firebaseUID} email={this.state.email} {...props}  />
            </MuiThemeProvider>
            )} />

            <Route path="/campaigns" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <ListCampaigns firebaseUID={this.state.firebaseUID} email={this.state.email} {...props}   />
              </MuiThemeProvider>
            )} />

            <Route exact path="/admin" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <Admin {...this.state} {...props}   />
              </MuiThemeProvider>
            )} />

            <Route exact path="/admin/parameters" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <AdminParameters {...this.state} {...props}   />
              </MuiThemeProvider>
            )} />

            <Route path="/baform" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <BAForm />
              </MuiThemeProvider>
            )} />

            <Route path="/partners" render={(props) => (
              <CoreMuiThemeProvider theme={theme}>
                <ListPartners {...this.props}/>
              </CoreMuiThemeProvider>
                )} />

            <Route path="/uploadfile" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <UploadFileSQL firebaseUID={this.state.firebaseUID} />
              </MuiThemeProvider>
            )} />

            <Route path="/listuploads" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <ListSQLUploads firebaseUID={this.state.firebaseUID} />
              </MuiThemeProvider>
            )} />

            <Route path="/campaign/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <Campaign firebaseUID={this.state.firebaseUID} ssx_id={props.match.params.campaignid}  />
              </MuiThemeProvider>
            )} />

            <Route path="/partner/:parnterId" render={(props) => (
              <CoreMuiThemeProvider theme={theme}>
                <Partner {...this.props} parnterId={props.match.params.parnterId}  />
              </CoreMuiThemeProvider>
            )} />

            <Route path="/nationalbrandcampaigns" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <ListNationalBrandCampaigns firebaseUID={this.state.firebaseUID} email={this.state.email}  />
              </MuiThemeProvider>
            )} />

            <Route path="/edit/campaigndetails/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <EditCampaignDetails ssx_id={props.match.params.campaignid} {...props} />
              </MuiThemeProvider>
            )} />

            <Route path="/edit/nationalbrandcampaigndetails/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <EditNationalBrandCampaignDetails ssx_id={props.match.params.campaignid} />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/externalreports/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <ListExternalReports ssx_id={props.match.params.campaignid} />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/uploadfolder/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <UploadFolder ssxId={props.match.params.campaignid} />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/items/:campaignid" render={(props) => (
              <CoreMuiThemeProvider theme={theme}>
                <Items ssxId={props.match.params.campaignid}  />
              </CoreMuiThemeProvider>
            )} />

            <Route exact path="/edit/mediacd/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <MediaCD ssxId={props.match.params.campaignid}  />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/mediaplan/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <MediaPlan ssxId={props.match.params.campaignid}  />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/budgetplanners/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <ListBudgetPlanner ssx_id={props.match.params.campaignid} />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/budgetplanner/:ssxId/:planId" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <BudgetPlanner planId={props.match.params.planId} ssxId={props.match.params.ssxId}  />
              </MuiThemeProvider>
            )} />

            <Route exact path="/edit/externalreport/:campaignid/:reportid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <ExternalReport ssxId={props.match.params.campaignid} reportId={props.match.params.reportid} />
              </MuiThemeProvider>
            )} />

            <Route path="/edit/strategycreative/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <EditStrategyCreative ssx_id={props.match.params.campaignid} />
              </MuiThemeProvider>
            )} />

            <Route path="/edit/redirect/:ssxId" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <EditRedirect ssxId={props.match.params.ssxId} />
              </MuiThemeProvider>
            )} />

            <Route path="/edit/optimizations/:campaignid" render={(props) => (
              <MuiThemeProvider muiTheme={muiTheme}>
                <EditOptimizations ssx_id={props.match.params.campaignid} email={this.state.email}/>
              </MuiThemeProvider>
            )} />



      </div>
      </Router>
    );
    }
  }
}

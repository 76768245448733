import React, { Component } from 'react';
import  {Link} from 'react-router-dom';
import NavbarDrawer from './navbardrawer';
import Dialog from '@material-ui/core/Dialog';
import AccountBox from '../util/accountbox';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

export default class Navbar extends Component {

  constructor(props) {
    super(props);
    this.state = { accountBoxOpen : false };
  }

  handleToggle = () => this.setState({open: !this.state.open});
  handleClose = () => this.setState({open: false});
  passedSetState = (value) => this.setState({ open: value}) ;
  handleDialogClose = () => this.setState({accountBoxOpen: false}) ;

  render() {

    if ( this.props.firebaseUID === 'loading') {

      return(
      <div>
          <AppBar position="static">
              <Toolbar>
                <IconButton color="inherit" aria-label="Menu">
                  <i className="material-icons d-flex align-items-center" style={{color: '#fff'}} >menu</i>
                </IconButton>
              </Toolbar>
          </AppBar>
        <NavbarDrawer open={this.state.open} handleToggle={this.handleToggle} passedSetState={this.passedSetState} />
      </div>
    );

    } else {

        return (
          <div>
            <AppBar position="static">
                <Toolbar>
                  <IconButton color="inherit" aria-label="Menu" onClick={ ()=> this.setState({open: true})}>
                    <i className="material-icons d-flex align-items-center" style={{color: '#fff'}} >menu</i>
                  </IconButton>
                  <Typography variant="title" color="inherit" style={{flex: 1}}>
                    <Link style={{color:'white'}} to='/'><img alt='logo' style={{maxHeight: '35px'}} src='/img/SSX_logo_2018.png' /></Link>
                  </Typography>
                  <Button onClick={ ()=> this.setState({accountBoxOpen: true})} style={{color: '#fff'}} >
                    {this.props.firebaseUID == null ? 'Login' : 'Account'}
                  </Button>

                </Toolbar>
            </AppBar>

            <NavbarDrawer open={this.state.open} handleToggle={this.handleToggle} passedSetState={this.passedSetState} handleClose={this.handleClose}/>

            <Dialog
                open={ this.state.accountBoxOpen === true  }
                onClose={this.handleDialogClose} >
            <AccountBox firebaseUID={this.props.firebaseUID} email={this.props.email}/>
            </Dialog>

          </div>
        );
      }
  }
}

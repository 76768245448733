import React, {Component} from 'react';
import * as firebase from 'firebase';
import LoadingButton from '../../util/loadingbutton';
import TextField from '@material-ui/core/TextField';

export default class BoxCreateParameterOption extends Component {

    constructor(props) {
      super(props);
      this.state = { errorMessage: null, value: '', label:'' };
    }


    addOption = (e) =>{
      e.preventDefault()

      if (!this.state.value || this.state.label === ''){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide label and SQL value</b>})
      } else {
        this.setState({errorMessage: null, buttonStatus: 'loading'})


      let newPostKey  = firebase.database().ref().child('posts').push().key;
      let optionDate = {label: this.state.label, value: this.state.value, id: newPostKey}

      firebase.database().ref('/util/parameters/' + this.props.tabOpen + '/parameters/' + this.props.selectedParameterId + '/options/' + newPostKey).set(optionDate).then( ()=>{

            this.setState({errorMessage: null, buttonStatus: 'saved'}, ()=> this.props.passedSetState('createParameterOption', false))

      }).catch( (err)=> {
          this.setState({errorMessage: err, buttonStatus: 'regular'})
          console.log(err)
      })

      }
    }


render(){

    return(

      <form className='row no-gutters' style={{padding: '0px 20px 20px 20px'}} onSubmit={this.addOption}>

                  <div className='col-12 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        onChange={ (e) => this.setState({label: e.target.value}) }
                        value={this.state.label}
                        label="Label"
                        helperText="This value will show in Campdash"
                        placeholder='Audiences'
                        fullWidth
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        onChange={ (e) => this.setState({value: e.target.value}) }
                        value={this.state.value}
                        label="SQL Value"
                        placeholder='AUD'
                        fullWidth
                        helperText="This value will show in dashboards and raw SQL tables. Keep it short with no spaces"
                    />

                  </div>

                  <div className="col-12">
                      {this.state.errorMessage}
                  </div>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '0px'}}>
                      <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Add' savedLabel='Added!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' regularButtonClick={this.addOption} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
                  </div>

      </form>

      )
    }
}

/*
{this.state.client === 'PGWGCT' || this.state.client === 'PGSams' ?
<div className='col-6 d-flex align-items-center'>
        <FormControlLabel
          label="Notify Morgan/WMX"
          control={
              <Switch
                checked={this.state.wmxEnabled}
                onChange={ ()=> this.setState({wmxEnabled : !this.state.wmxEnabled})}
              />
            }
          />
      </div>
: null }
*/

import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import  {Link} from 'react-router-dom';
import Dialog from 'material-ui/Dialog';
import BoxCreateExternalReport from './boxcreateexternalreport';

export default class ExternalReports extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignData: 'loading', ssx_id: this.props.ssx_id, createExternalReportBoxOpen: false }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
      this.state.firebaseListener.off()
    }

    componentDidMount(){

      let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssx_id)
      firebaseListener.on('value', (snap)=> {
        let tempArray = []
        let externalReports = snap.val() && snap.val().externalReports ? snap.val().externalReports : null

          for (let current in externalReports){
            tempArray.push(externalReports[current])
          }

        this.setState({campaignData: snap.val(), externalReports: tempArray, firebaseListener: firebaseListener})
      });

    }

    handleDialogClose = () => this.setState({createExternalReportBoxOpen: false}) ;

    passedSetState = (state, value) => {

      this.setState({[state]: value})
    }

render(){

  if (this.state.campaignData =='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      const externalReports = this.state.externalReports.map((current, index) => {
            return (
              <Link to={'/edit/externalreport/' + this.props.ssx_id + '/' + current.id} className='row' key={index} style={{margin: '0px', border: '1px solid', borderColor: '#ddd', backgroundColor:'', borderRadius:'1px', padding: '10px'}} >
                {current.reportName}
              </Link>
            )
        })

      return (
        <div className='row' style={{margin: '0px', padding: '30px'}}>

              <div className='col-12' style={{padding: '0px 0px 0px 0px', margin: '0px'}}>
              <h4>{this.state.campaignData.details.campaignName}</h4>
                <b style={{fontSize: '20px'}}>External Reports:</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({createExternalReportBoxOpen: true})}>add_box</i> <br />
              </div>

              <div className='col-12' style={{padding: '0px'}}>
                    {externalReports}
              </div>

              <Dialog
                  title='Add External Strategy'
                  modal={false}
                  open={ this.state.createExternalReportBoxOpen == true  }
                  onRequestClose={this.handleDialogClose} >
              <BoxCreateExternalReport email={this.props.email} ssx_id={this.props.ssx_id} campaignOptimizations={this.state.campaignOptimizations} passedSetState={this.passedSetState} email={this.props.email}   />
              </Dialog>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
//import Metrics from './metrics'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import BoxCreativeDetails from './boxcreativedetails';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from 'material-ui/IconButton';

export default class CreativeItem extends Component {
  constructor(props) {
    super(props);
    this.state = { boxCreativeDetails: false }
  }

handleDialogClose = () => this.setState({boxCreativeDetails: false}) ;

passedSetState = (state, value) => { this.setState({[state]: value}) };

render(){

let currentCreative = this.props.currentCreative
let details = currentCreative.details
let name = details.creativeId + "_" + details.creativeImage + "-" + details.cta + "-" +details.mediaFormat+ "-" +details.size+ "-" + details.landingPage+ "-" +details.customOne+ "-" + details.customTwo

  return (
    <div className='row' style={{margin: '0px', border: '1px solid', borderColor: '#ddd', backgroundColor:'', borderRadius:'1px', padding: '10px', width: '100%'}} >

        <div className='col-12  d-flex align-items-center'  >
          <CopyToClipboard text={name}>
            <IconButton iconClassName="material-icons" tooltip="Copy">art_track</IconButton>
          </CopyToClipboard>
          <label className='label'>{name}</label>
          <i className="material-icons" style={{padding: '5px',cursor: 'pointer', color: '#3E8EDE', fontSize: '18px'}} onClick={ ()=> this.setState({ boxCreativeDetails: !this.state.boxCreativeDetails  }) } >edit</i>
        </div>

        <Dialog open={ this.state.boxCreativeDetails  } onClose={this.handleDialogClose} >
          <DialogTitle>{details.creativeImage + '-' + details.cta + '-' + details.mediaFormat + '-' +details.size + '-' +details.landingPage + '-' +details.customOne + '-' +details.customTwo + '-(' +details.creativeId + ')-' }</DialogTitle>
          <DialogContent>
            <BoxCreativeDetails creativeId={this.props.creativeId} campaignDetails={this.props.campaignDetails} currentCreative={currentCreative} strategyId={this.props.strategyId} reportId={this.props.reportId} passedSetState={this.props.passedSetState} parameters={this.props.creativeParameters.parameters}   />
          </DialogContent>
        </Dialog>

    </div> );
  }
}

//<Metrics campaignDetails={this.props.campaignDetails} currentStrategy={this.props.currentStrategy} reportId={this.props.reportId}/>

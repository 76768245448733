import React, {Component} from 'react';
import * as firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from 'material-ui/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import ListParameters from './labelparameters/listparameters'

export default class BAForm extends Component {

    constructor(props) {
      super(props);
      this.state = {  filenames: [], creativeParameters: 'loading', campaignName: '', sizes: [], size: '', creativeImage: '', cta: '', mediaFormat: '', landingPage: '', customTwo: '', customOne: '', snackbarOpen: false }
    }

    componentDidMount(){
      //go get creative parameters

      firebase.database().ref('/util/parameters/creatives/parameters/').once('value').then( snap => {
        this.setState({creativeParameters: snap.val() })

      }).catch( error => {
        this.setState({creativeParameters: '', errorMessage: error.message})
      })

    }


    itemChecked = (current) => {

        let tempArray = this.state.sizes
        let result = tempArray.indexOf(current)

        if (result > -1){
          return true
        } else {
          return false
        }
    }

    passedSetState = (state,value) => {
      this.setState({ [state]: value})
    }

    updateCampaignName = (value) => {
      let text = value.replace("-", "")

      this.setState({campaignName: text})
    }


render(){


  if (this.state.creativeParameters === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

  const filenames = this.state.sizes.map((current, index) => {
    let text = this.state.campaignName + "-" + this.state.creativeImage + "-" + this.state.cta + "-" + this.state.mediaFormat + "-" + this.state.sizes[index] + "-" + this.state.landingPage +  "-"  + this.state.customOne + "-" + this.state.customTwo
        return (
          <div key={index} style={{padding: '10px'}} className='d-flex align-items-center' >

            <CopyToClipboard text={text}
              onCopy={() => this.setState({snackbarOpen: true})}>
                  <IconButton iconClassName="material-icons" tooltip="Copy">content_copy</IconButton>
            </CopyToClipboard>

            <b style={{padding: '0px', margin: '0px'}}>{text}</b>
          </div>
        )
    })


      return (
        <div className='row' style={{maring: '0px', padding: '40px'}}>

              <div className='col-12'>
                <h4 style={{color: 'grey', margin: '0px'}}>BA Form</h4>
                <label style={{color: 'grey', fontSize: '10px'}}>CampaignName_CreativeImage_CTA_MediaFormat_Size_LandingPage_CustomOne_CustomTwo</label>
              </div>

              <div className='col-12 d-flex align-items-center' style={{padding: '0px 30px'}}>
                <TextField
                    fullWidth
                    label='Short Campagin Name (optional)'
                    onChange={ (e) => this.updateCampaignName( e.target.value) }
                    placeholder="GilletteJusticeLeague"
                    value={this.state.campaignName}
                />
              </div>

              <ListParameters {...this.state} parameters={this.state.creativeParameters} passedSetState={this.passedSetState} passedFromBaForm={true} itemChecked={this.itemChecked}/>


              <div className='col-12 '>
                {filenames}
              </div>

              <Snackbar
                open={this.state.snackbarOpen}
                style={{width:'50%'}}
                message="Copied to clipboard"
                autoHideDuration={700}
                onClose={ ()=> this.setState({snackbarOpen: false})}
              />

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import InputAdornment from '@material-ui/core/InputAdornment';
import LinearProgress from '@material-ui/core/LinearProgress';
import LoadingButton from '../../../util/loadingbutton'
import EditPageHeader from '../../../util/editpageheader'
import Item from './item'
import TextField from '@material-ui/core/TextField';
import * as algoliasearch from 'algoliasearch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Button from '@material-ui/core/Button';

var client = algoliasearch('FKWKD7663I', '75e62fadd51d3dd0357d74b338b4d4e3');
//var index = client.initIndex('pgwgct_sku_list');

export default class Items extends Component {

    constructor(props) {
      super(props);
      this.state = { items: [], campaignItems: 'loading', timeout: null, loading: false, buttonStatus: 'regular' }
    }

    componentDidMount(){

      let campaignName
      firebase.database().ref('/campaigns/' + this.props.ssxId + '/details/campaignName').once('value').then( topsnap => {
        if ( !topsnap.val()){ throw new Error ('Error, no campaign data.') }
        campaignName = topsnap.val()
        return firebase.database().ref('/campaigns/' + this.props.ssxId + '/itemList/campaign/').once('value')
      }).then ( snap=> {
        let campaignItems = snap.val() ? snap.val() : {}
        this.setState({ campaignItems: campaignItems, campaignName: campaignName })
      }).catch( error => {
        console.log(error)
      });

    }

    saveItems = () =>{
      this.setState({buttonStatus: 'loading'})
      firebase.database().ref('/campaigns/' + this.props.ssxId + '/itemList/campaign/').set(this.state.campaignItems).then( () => {

        this.setState({buttonStatus: 'saved'})

      }).catch( error => {
        this.setState({errorMessage: error.message})
        console.log(error)
      });
    }

    search = (data) =>{

      console.log('CALLING SEARCH')

      let commaString = ''
      let searchTermArray = []
      let queries = []
      //this will cover if people copy/paste from excel
      var items = data.split("\n");
      for(var key in items) {
          commaString += items[key] + ','
        }

      //remove the last comma
      commaString = commaString.slice(0, -1)
      //commaString = 'tide pods botanical'
      searchTermArray = commaString.split(',')

      //map through search array to build queries
      searchTermArray.forEach( current => {
        if (current === '' || current === ' '){ return }
        let query = { indexName: 'pgwgct_sku_list',
                      query: current,
                      params: { hitsPerPage: 800, distinct: 1 }
                    }
        queries.push( query )
      })

      client.search( queries , (err, content)=> {
            if (err) throw err;
            let results = content.results

            //console.log(results)

            let tempArray = []

            results.forEach( currentResult => {
              currentResult.hits.forEach( current => {
                tempArray.push(current)
              })
            })

            //make tempArray unique first, no need to have duplicate items shows, and this will throw key error
            //make arrays unique
            //let uniqueTempArray = [...new Set(tempArray)]

            this.setState({items: tempArray, loading: false})

          }
        );

    }

    //this is called, and waits before actually asking algolia to query the data
    typedQuery = (data) =>{

      clearTimeout(this.state.timeout);
      // Make a new timeout set to go off in 1000ms
      let tempTimeout = setTimeout( ()=> {
          this.search(data)
      }, 1000);

      this.setState({ timeout: tempTimeout, loading: true})


    }

    checkboxClicked = (item) => {

        let checked = this.state.campaignItems[item.upc] ? true : false
        let tempItemsSelected = this.state.campaignItems

        //if item is already checked, remove it from the list
        if (checked){
          delete tempItemsSelected[item.upc]
        } else {
          tempItemsSelected[item.upc] = item
          tempItemsSelected[item.upc]._highlightResult = null
        }

        this.setState({campaignItems: tempItemsSelected})

    }

    selectAll = () => {
      let tempItemsSelected = this.state.campaignItems
      this.state.items.forEach(item => {
        tempItemsSelected[item.upc] = item
        tempItemsSelected[item.upc]._highlightResult = null
      })

      this.setState({campaignItems: tempItemsSelected})

    }

    deselectAll = () => {
      this.setState({campaignItems: {} })
    }

    downloadItemList = () =>{

          //build out all possible iterations of strategy and creative
          //start with first strategy, and then go through all creatives
          var csvContent = "data:text/csv;charset=utf-8,Item Name,UPC,ItemId,url\r\n"
          for (let key in this.state.campaignItems ){
            let current = this.state.campaignItems[key]
            let item_name = current.item_name
            item_name = item_name.replace(/,/g, " ")
            csvContent +=  item_name + "," + current.upc + "," + current.wmt_item_id + ",www.walmart.com/ip/" + current.wmt_item_id + "\r\n"
          }

        let fileName = this.state.campaignName  + '_ItemList.csv'
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", fileName);
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".

        //window.open(encodedUri);
      }


render(){

    if (this.state.campaignItems === 'loading'){

      return (
        <div style={{padding: '20px'}}>
          <LinearProgress  />
        </div>
      );
    } else {



    let items = this.state.items.map( current => {
      let checked = this.state.campaignItems[current.upc] ? true : false
      return <Item checkboxClicked={this.checkboxClicked} current={current} key={current.upc} checked={checked}/>
    })

    let tempArraySelectedItem = []
    for (let current in this.state.campaignItems ){
      tempArraySelectedItem.push( this.state.campaignItems[current] )
    }

    let campaignUPCList = ''
    let campaignItems = tempArraySelectedItem.map( current => {
      let checked = this.state.campaignItems[current.upc] ? true : false
      campaignUPCList += current.upc + "\r\n"
      return <Item checkboxClicked={this.checkboxClicked} current={current} key={current.upc} checked={checked}/>
    })

      return (
        <div className='row no-gutters d-flex align-items-start' style={{margin: '0px', padding: '20px'}}>
            <EditPageHeader pageTitle='Campaign Item List' campaignName={this.state.campaignName} />

            <div className='col-12' >
                <TextField  placeholder='Try searching "tide pods" or by upc "33490234"'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                        <i className="material-icons" >search</i>
                     </InputAdornment>
                                 ),
                            }}
                multiline rowsMax='10' fullWidth={true} value={this.state.itemSearch}
                onChange={ (e)=> this.typedQuery(e.target.value)} />
            </div>

            <div className='col-6' style={{padding: '10px'}} >
              <b>Search Results ({this.state.items.length})</b><br />
              <Button color='default' onClick={ this.selectAll } style={{paddingLeft: '0px'}}>
                <i className="material-icons" style={{color: 'grey'}} >add_circle</i>
                  <span style={{textTransform: 'none'}}>Select All</span>
              </Button>
                {this.state.loading ? <LinearProgress color='secondary'  /> : items}
            </div>

            <div className='col-6 row no-gutters' style={{padding: '10px'}} >

                <div className='col-8' >
                  <b>Campaign Items ({campaignItems.length})</b><br />

                  <Button color='default' onClick={ this.deselectAll } style={{paddingLeft: '0px'}}>
                    <i className="material-icons" style={{color: 'grey'}} >remove_circle</i>
                      <span style={{textTransform: 'none'}}>Deselect All</span>
                  </Button>

                  {campaignItems.length === 0 ? null :
                  <Button color='default' onClick={ this.downloadItemList } >
                    <i className="material-icons"  >cloud_download</i>
                  </Button>
                  }

                  {campaignItems.length === 0 ? null :
                  <CopyToClipboard text={campaignUPCList}
                    onCopy={() => this.setState({snackbarOpen: true})}>
                      <Button color='default' >
                        <i className="material-icons" >content_copy</i>
                      </Button>
                  </CopyToClipboard>
                  }


                </div>

                <div className='col-4 d-flex justify-content-end' style={{padding: '10px'}} >
                  <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save Items' savedLabel='Items Saved!'  regularButtonClick={ this.saveItems } savedButtonClick={ ()=> { this.setState({buttonStatus: 'regular'})} } />
                </div>

                <div className='col-12 no-gutters'  >
                  {campaignItems}
                </div>

            </div>

        </div>
      );
    }
  }
}

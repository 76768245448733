import React, {Component} from 'react';
import * as firebase from 'firebase';
import  {Link} from 'react-router-dom';
import LinearProgress from 'material-ui/LinearProgress';

export default class HomePage extends Component {

    constructor(props) {
      super(props);
      this.state = { uploads: 'loading' };
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

    componentDidMount(){

      let firebaseListener = firebase.database().ref('/uploadFileSQL/uploads').orderByChild("inverseDateCreated").limitToFirst(25)
      firebaseListener.on('value', (snap)=> {
        console.log(snap.val())
            let tempUploads = []
            snap.forEach( current => {
              tempUploads.push(current.val())
            })

            console.log(tempUploads)
            this.setState({uploads: tempUploads, firebaseListener, firebaseListener})
      });
    }

    uploadItemIcon = (status) => {
        if (status === 'uploaded') {
          return ( <div className='col-1 d-flex align-items-center justify-content-center' style={{padding: '0px', margin: '0px'}} >
                      <i className="material-icons" style={{color:'green'}}>check_circle</i>
                   </div>)
        } else if (status === 'uploadingSQL') {
          return  (<div className='col-1' style={{padding: '0px', margin: '0px'}} >
                      <div style={{padding: '0px'}}>
                        <LinearProgress mode="indeterminate" />
                      </div>
                  </div>)
        } else {
          return  ( <div className='col-1 d-flex align-items-center justify-content-center' style={{padding: '0px', margin: '0px'}} >
                       <i className="material-icons" style={{color:'red'}}>warning</i>
                     </div>)
        }
      }


render(){

 if (this.state.uploads === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  } else {

    let uploadList = this.state.uploads.map(( current, index) => {
          return (
            <div key={current.id} className='' style={{margin: '0px', border: '1px solid', borderColor: '#ddd', borderRadius:'1px', padding: '7px'}} >

              <div  className='row d-flex align-items-center' style={{padding: '0px', margin: '0px'}}  >
                  {this.uploadItemIcon(current.status)}
                  <div className='col-11' >
                    <b style={{margin:'0px', color: 'black'}}>{current.fileName}</b><br></br>
                    <span style={{ color: 'black'}}>{current.status.toUpperCase()}: {current.message}</span><br></br>
                    <span style={{fontSize:'14px', color: 'grey'}}>{new Date(current.dateCreated).toLocaleString("en-US")}</span>
                  </div>
              </div>

            </div>
          )
          });

    return(


        <div className='row' style={{margin: '0px', padding:'20px', minHeight: '56px'}} >

          <div className='col-12 d-flex align-items-center' style={{paddingTop: '5px'}}>
            <div>
            <b style={{fontSize: '25px'}}>SQL Uploads:</b>
            </div>
            <Link to={'/uploadfile'} >
              <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons">add_box</i> <br />
            </Link>

          </div>

          <div className='col-12' style={{margin: '0px', padding:'0px'}} >
            {uploadList}
          </div>

        </div>
      );
    }
  }
}

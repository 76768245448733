import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import BoxCreateDataFile from './boxcreatedatafile'

export default class UploadDataFile extends Component {

    constructor(props) {
      super(props);
      this.state = { dataUploads: 'loading', boxDataFileOpen: false}
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }


    componentDidMount(){

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/dataUploads' )
        firebaseListener.on('value', (snap)=> {
          let tempArray = []

          snap.forEach( current=> {
            tempArray.push(current.val())
          })

          this.setState({ dataUploads: tempArray, firebaseListener: firebaseListener})
        });

      }

  downloadDataTemplate(){
    //  this.setState({downloadDataLoading: true, downloadHoverClass: 'hide'})
      let strategies = []
      let creatives = []
      let firebaseURL = '/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId
      firebase.database().ref(firebaseURL + '/strategies' ).once('value').then( snap => {

        if (!snap.val()){ throw new Error('No strategies exist yet.') }

        snap.forEach( current => {
          strategies.push( current.val() )
        })

        return firebase.database().ref(firebaseURL + '/creatives' ).once('value')
      }).then ( snap=> {

        if (!snap.val()){ throw new Error('No creatives exist yet.') }
        snap.forEach( current => {
          creatives.push( current.val() )
        })

      }).then ( ()=> {

        //build out all possible iterations of strategy and creative
        //start with first strategy, and then go through all creatives
        var csvContent = "data:text/csv;charset=utf-8,StrategyId,StrategyName,CreativeId,CreativeName,Impressions,Clicks,Uniques,CPM,TotalSpend\r\n"


        strategies.forEach( currentStrategy =>{

            let strategyDetails = currentStrategy.details
            let strategyName = strategyDetails.targetingChannel + "-" + strategyDetails.partner + "-" + strategyDetails.targetSegment + "-" + strategyDetails.device + "-" + strategyDetails.mediaType

            creatives.forEach( currentCreative =>{

              csvContent += strategyDetails.strategyId + "," + strategyName

              let creativeDetails = currentCreative.details
              let creativeName = creativeDetails.creativeImage + "-" + creativeDetails.cta + "-" + creativeDetails.mediaFormat + "-" + creativeDetails.size + "-" + creativeDetails.landingPage + "-" + creativeDetails.customOne + "-" + creativeDetails.customTwo
              csvContent += "," + creativeDetails.creativeId + "," + creativeName
              csvContent += "\r\n"

            })

        })


      let fileName = this.props.reportName + '_' + this.props.campaignName  + '.csv'
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link); // Required for FF

      link.click(); // This will download the data file named "my_data.csv".

      //window.open(encodedUri);

      }).catch( err => {
        console.log(err)
        this.setState({errorMessage: err.message})
      })


      /*
      var csvContent = "data:text/csv;charset=utf-8," + this.props.currentevent.sport + ": " + this.props.currentevent.title  + " on " +  this.props.currentevent.date + "\n" + "Data exported on: " + today.toString() + "\n" + "\n" + "\n"  + "EMAILS \n"  ;

                //loop through all of them and add to array
                snap.forEach( childSnapshot => {
                    csvContent += 'email') + "\n"
                });

        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
        */

    }

handleDialogClose = () => this.setState({boxDataFileOpen: false}) ;

passedSetState = (state, value) => { this.setState({[state]: value}) }


uploadItemIcon = (status) =>  {
        if (status == 'uploaded') {
          return  <i className="material-icons" style={{color:'green'}}>check_circle</i>
        } else if (status == 'uploadingSQL') {
          return  ( <LinearProgress mode="indeterminate" />  )
        } else {
          return   <i className="material-icons" style={{color:'red'}}>warning</i>
        }

      }




render(){

  if (this.state.dataUploads == 'loading'){
    return(
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    )
  } else {


    const styles = {
      uploadButton: { verticalAlign: 'middle'},
      uploadInput: { cursor: 'pointer', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, width: '100%', opacity: 0},
                  };


    const dataUploads = this.state.dataUploads.map((current, index) => {

          return (
          <div className='row' key={index} style={{padding: '5px 0px', margin: '0px'}}>
              <div className='col-1 d-flex align-items-center' key={index}>
                 {this.uploadItemIcon(current.status)}
              </div>
              <div className='col-11 row'>
                    <div className='col-12'>
                      <b  >{current.fileName}</b>
                    </div>
                    <div className='col-12'>
                      <label className='label' style={{fontSize: '12px'}} >{new Date(current.dateCreated).toLocaleString("en-US")}</label>
                    </div>
              </div>
          </div>
          )
      })

      return (
        <div className='row boxShadow' style={{padding: '15px', margin: '0px', height: '100%'}}>
            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                <b style={{fontSize: '17px'}}>Upload Report Data CSV:</b><br />
                <label className='label'>Upload the csv containg all the metrics (impressions, clicks, total spend) for this report. Data should be total campaign (not daily). New uploads will override existing report data.</label>
            </div>

            <div className="col-12" style={{padding: '0px 0px 30px 0px', margin: '0px'}}>
                {dataUploads}
            </div>

            <div style={{position: 'absolute', bottom: '0px', left: '0px'}}>
                      <div style={{padding: '10px'}}>
                        <FlatButton
                           label="Download Template"
                           style={styles.uploadButton}
                           labelPosition="after"
                           containerElement="label"
                           onClick={ ()=> this.downloadDataTemplate() }
                           icon={<i className="material-icons" >cloud_download</i>}
                           />
                        <FlatButton
                           label="Upload CSV"
                           labelPosition="after"
                           style={styles.uploadButton}
                           containerElement="label"
                           icon={<i className="material-icons" >file_upload</i>}
                           onClick={ () => this.setState({boxDataFileOpen: true}) }
                           />
                      </div>
              </div>

              <Dialog
                  title='Upload Data File'
                  modal={false}
                  open={ this.state.boxDataFileOpen == true  }
                  onRequestClose={this.handleDialogClose} >
              <BoxCreateDataFile ssxId={this.props.ssxId} reportId={this.props.reportId} passedSetState={this.passedSetState}  />
              </Dialog>

          </div>

      )
    }
  }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../../util/loadingbutton';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from 'material-ui/IconButton';
import Snackbar from '@material-ui/core/Snackbar';

export default class Redirect extends Component {

    constructor(props) {
      super(props);
      let { redirectURL = "", medium = "" } = this.props.current
      this.state = { medium, redirectURL }
    }

    saveItem = () => {

        if (this.state.redirectURL === "" || this.state.medium === ""){
          this.setState({errorMessage: <b style={{color: "red"}}>Please provide medium and redirect url</b>})
          return
        }

        if (!this.state.redirectURL.includes("https://")){
          this.setState({errorMessage: <b style={{color: "red"}}>Redirect url must contain "https://"</b>})
          return
        }


            this.setState({errorMessage: null, buttonStatus: 'loading'})
            let updates = {}
            let {id, gaId, fbPixelId, ssxId } = this.props.current
            let {redirectURL, medium } = this.state
            let redirectPath = '/campaigns/' + this.props.ssxId + '/redirects/' + id

            //save under campaign data
            updates[ redirectPath + "/id/" ] = id
            updates[ redirectPath + "/gaId/" ] = gaId
            updates[ redirectPath + "/fbPixelId/" ] = fbPixelId
            updates[ redirectPath + "/ssxId/" ] = ssxId
            updates[ redirectPath + "/redirectURL/" ] = redirectURL
            updates[ redirectPath + "/medium/" ] = medium

            //update main tree that link uses
            updates[ 'redirects/' + id + "/id/" ] = id
            updates[ 'redirects/' + id + "/gaId/" ] = gaId
            updates[ 'redirects/' + id + "/fbPixelId/" ] = fbPixelId
            updates[ 'redirects/' + id + "/ssxId/" ] = ssxId
            updates[ 'redirects/' + id + "/redirectURL/" ] = redirectURL
            updates[ 'redirects/' + id + "/medium/" ] = medium


            firebase.database().ref().update(updates).then( ()=>{
              this.setState({buttonStatus: 'saved', errorMessage: null})
            }).catch( (err)=> {
              this.setState({buttonStatus: 'regular', errorMessage: err.message})
              console.log(err)
            })
    }


render(){

        let {id, gaId, fbPixelId, ssxId } = this.props.current
        let {redirectURL, medium } = this.state

        let textLink = "https://qrscan.shop?medium=" + medium + "&ssxId=" + ssxId + "&id=" + id

        return (
          <div className='row col-12' key={id} style={{border: "1px #ddd solid", padding: "20px", margin: "5px 0px"}}>
            <div className='col-12'>

              <CopyToClipboard text={textLink}
                onCopy={() => this.setState({snackbarOpen: true})}>
                    <IconButton iconClassName="material-icons" tooltip="Copy">content_copy</IconButton>
              </CopyToClipboard>
              <a href={textLink} target="_blank" rel="noopener noreferrer">{textLink}</a>
            </div>
            <div className='col-12'>
              <TextField fullWidth label='Redirect ID' disabled value={id} style={{marginTop: "10px"}} />
              <TextField fullWidth label='SSX Campaign ID' disabled value={ssxId} style={{marginTop: "10px"}} />
              <TextField fullWidth label='FB Pixel Id' disabled value={fbPixelId} style={{marginTop: "10px"}} />
              <TextField fullWidth label='GA ID' disabled value={gaId} style={{marginTop: "10px"}} />

              <TextField fullWidth label='Medium' value={medium} style={{marginTop: "10px"}} onChange={ (e)=> this.setState({medium:e.target.value})} />
              <TextField fullWidth label='Redirect URL' value={redirectURL} style={{marginTop: "10px"}} onChange={ (e)=> this.setState({redirectURL:e.target.value})} />

            </div>
            <div className="col-12" style={{marginTop: "10px"}}>
              {this.state.errorMessage}
            </div>
            <div className="col-12" style={{marginTop: "10px"}}>
              <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save' savedLabel='Saved!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='save' regularButtonClick={ () => this.saveItem()} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
            </div>

            <Snackbar
              open={this.state.snackbarOpen}
              style={{width:'50%'}}
              message="Copied to clipboard"
              autoHideDuration={700}
              onClose={ ()=> this.setState({snackbarOpen: false})}
            />
            
          </div>
            )
  }
}

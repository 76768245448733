
export function parseCreativeName(creative_name) {

  // "PMP - GroundTruth - Walmart Neighborhood - Mobile - Display"
  let temp_creative_name = creative_name.replace(/ /g, '');
  let split_temp_creative_name = temp_creative_name.split("-");

  let creativeCampaignName =  split_temp_creative_name[0] ? split_temp_creative_name[0] : ''
  let creativeImage =  split_temp_creative_name[1] ? split_temp_creative_name[1] : ''
  let cta =  split_temp_creative_name[2] ? split_temp_creative_name[2] : ''
  let mediaFormat =  split_temp_creative_name[3] ? split_temp_creative_name[3] : ''
  let size =  split_temp_creative_name[4] ? split_temp_creative_name[4] : ''
  let landingPage =  split_temp_creative_name[5] ? split_temp_creative_name[5] : ''
  let customOne =  split_temp_creative_name[6] ? split_temp_creative_name[6] : ''
  let customTwo =  split_temp_creative_name[7] ? split_temp_creative_name[7] : ''

  //us this when debugging. originally our server wrote the creative names in the wrong format
  //let size =  split_temp_creative_name[5] ? split_temp_creative_name[5] : ''
  //let landingPage =  split_temp_creative_name[4] ? split_temp_creative_name[4] : ''

  let parsedValues = { creativeCampaignName : creativeCampaignName, creativeImage: creativeImage,
                       cta: cta, mediaFormat: mediaFormat, landingPage: landingPage,
                     size:size, customOne:customOne, customTwo: customTwo }
  return parsedValues
}

import React, {Component} from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import * as firebase from 'firebase';
import LinearProgress from 'material-ui/LinearProgress';
import MenuItem from 'material-ui/MenuItem';

export default class BoxCreateTracker extends Component {

    constructor(props) {
      super(props);
      let destinationURL = this.props.campaignDetails.destinationURL ? this.props.campaignDetails.destinationURL : ''
      this.state = { strategies: 'loading', loading: false, destinationURL: destinationURL, strategyId: '', creativeId: ''};
    }

    componentDidMount(){

      //need to get the names and values of the strategies and creative to allow users to pick from
      let creatives

      firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/creatives/').once('value').then( snap=> {
        let tempArray = []
        snap.forEach( current => {
          tempArray.push(current.val())
        })

        creatives = tempArray

        return firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/strategies/' ).once('value')

      }).then (snap => {

        let tempArray = []
        snap.forEach( current => {
          tempArray.push(current.val())
        })

        this.setState({creatives: creatives, strategies: tempArray})
      }).catch ( (err) => {
        console.log(err)
      })

    }



    submit(e){
      e.preventDefault()

      this.setState({loading: true})
      if (this.state.destinationURL === '' || this.state.strategyId === '' || this.state.creativeId === '' ){
        this.setState({errorMessage: 'Please insert all values'})
        return
      }

      //https://ssx-redurl-tracking.firebaseapp.com/?redurl=ip/910793227&wmx=10700271&Strategy=t2700

      let destinationURL = this.state.destinationURL
      let ssxId = this.props.ssxId
      let strategyId = this.state.strategyId
      let creativeId = this.state.creativeId


      let includeHttps =  destinationURL.includes('https://')
      let walmartURL =  destinationURL.includes('walmart.com/')

      if (!includeHttps){
        this.setState({errorMessage: 'Url must include "https://". PROTIP: copy the url directly from the browser address bar.'})
        return
      } else if (!walmartURL) {
        this.setState({errorMessage: 'Can only track www.walmart.com urls'})
        return
      }


      let redurl = encodeURIComponent(destinationURL)

      //clickTracker = 'https://ssx-redurl-tracking.firebaseapp.com/?redurl=' + redurl + '&version=2&wmx=' + wmxId + '&Strategy=' + strategyId + '&Creative=' + creativeId
      let clickTracker = 'http://linksynergy.walmart.com/deeplink?id=mZBF1JmBhNo&mid=2149&u1=' + strategyId  + '_' + creativeId + '&murl=' + redurl

      let object = {clickTracker: clickTracker}
      let mattId = strategyId + creativeId

        return firebase.database().ref('/campaigns/' + ssxId + '/externalReports/' + this.props.reportId + '/trackers/' + mattId).set({ creativeId: creativeId, strategyId: strategyId, destinationURL: destinationURL, trackers: object}).then( ()=>{
          this.setState({loading: false, errorMessage: null} , this.props.passedSetState('boxCreateTracker', false) )
        }).catch( (err)=> {
          this.setState({errorMessage: err})
          console.log(err)
        })

    }




render(){

  if (this.state.strategies ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

    const strategies = this.state.strategies.map((current, index) => {
      let details = current.details
          return (
              <MenuItem key={details.strategyId} value={details.strategyId} primaryText={details.targetingChannel + '-' + details.partner + '-' + details.targetSegment + '-' + details.device + '-' + details.mediaType + '-(' + details.strategyId + ')'} />
          )
      })

    const creatives = this.state.creatives.map((current, index) => {
      let details = current.details

            return (
                <MenuItem key={details.creativeId} value={details.creativeId} primaryText={details.creativeImage + '-' + details.CTA + '-' + details.mediaFormat + '-' + details.landingPage + '-' + details.customOne + '-(' + details.creativeId + ')'} />
            )
        })

    return(

      <div className='row' style={{padding: '20px', margin: '0px'}}>

                  <div className='col-12 d-flex align-items-center'>
                    <SelectField
                        floatingLabelText='Select Strategy'
                         multiple={false}
                         fullWidth={true}
                         value={this.state.strategyId}
                         onChange={ (event, index, value) => this.setState({ strategyId: value}) }
                       >
                      {strategies}
                    </SelectField>
                  </div>

                  <div className='col-12 d-flex align-items-center'>
                    <SelectField
                        floatingLabelText='Select Creative'
                         multiple={false}
                         fullWidth={true}
                         value={this.state.creativeId}
                         onChange={ (event, index, value) => this.setState({ creativeId: value}) }
                       >
                      {creatives}
                    </SelectField>
                  </div>

                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        onChange={ (event, value) => this.setState({ destinationURL: value}) }
                        hintText="destinationURL"
                        floatingLabelText="Destination URL"
                        value={this.state.destinationURL}
                        fullWidth={true}
                    />
                  </div>

                  <div className="col-12">
                    <b style={{color: 'red'}}>{this.state.errorMessage}</b>
                  </div>

                  <div className="col-12">
                  <RaisedButton
                      label="Add"
                      fullWidth={false}
                      secondary={true}
                      type="submit"
                      onClick={ event => this.submit(event) }
                      />
                  </div>


      </div>

      )
    }
  }
}

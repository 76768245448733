import React, {Component} from 'react';
import * as firebase from 'firebase';
import LoadingButton from '../../util/loadingbutton';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


export default class BoxCreateCampaign extends Component {

    constructor(props) {
      super(props);
      this.state = { errorMessage: null, email: '', password: '', company: '' };
    }


    addNewUser = () => {

      if (this.state.email === "" || this.state.password === "" || this.state.company === ""){
        this.setState({errorMessage: 'Insert email and password'})
        return
      }

      this.setState({buttonStatus: 'loading'})

      firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password ).then( (user)=> {
        console.log(user)
        let updates = {}

        //'users.undefined.meta.email'

        updates['users/' + user.uid + '/meta'] = {email: user.email, uid: user.uid, company: this.state.company}

        return firebase.database().ref().update(updates)


      }).then( ()=> {
        this.setState({buttonStatus: 'saved'})
      }).catch( err=> {
        this.setState({errorMessage: err.message, buttonStatus: null})
      })
  }


    updateCampaignName(parameter, value){

      this.setState({ [parameter]: value}, ()=> {
        let tempCampaignName = this.state.client + '-' + this.state.brand + '-' + this.state.retailer + '-' + this.state.initiative + '-' + this.state.qrtyr_date
        this.setState({campaignName: tempCampaignName})
      }
    )

    }


render(){

    return(

      <div className='row no-gutters' style={{padding: '10px'}}>

                  <div className='col-12 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        onChange={ (e) => this.setState({email: e.target.value}) }
                        value={this.state.email}
                        label="Email"
                        fullWidth
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        onChange={ (e) => this.setState({password: e.target.value}) }
                        value={this.state.password}
                        label="Password"
                        fullWidth
                    />
                  </div>

                  <div className='col-4 d-flex align-items-center' style={{padding: '10px'}}>
                  <FormControl  style={{width: '100%'}}>
                    <InputLabel>Company</InputLabel>
                    <Select value={this.state.company} onChange={ (e) => this.setState({ company: e.target.value}) }>
                      <MenuItem value={'ssx'}>SSX</MenuItem>
                      <MenuItem value={'mediaMath'}>MediaMath</MenuItem>
                      <MenuItem value={'muSigma'}>Mu Sigma</MenuItem>
                      <MenuItem value={'p&g'}>P&G</MenuItem>
                    </Select>
                  </FormControl>
                  </div>

                  <div className="col-12">
                      {this.state.errorMessage}
                  </div>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '0px'}}>
                      <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Add User' savedLabel='Added!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' regularButtonClick={this.addNewUser} savedButtonClick={ this.props.handleDialogClose } />
                  </div>

      </div>

      )
    }
}

import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from 'material-ui/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import  {Link} from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import UploadFiles from '../../util/uploadFiles.js';
import Contacts from './contacts';

export default class Campaign extends Component {

    constructor(props) {
      super(props);
      this.state = { partner: "loading", searchDescription: ''}
    }


    componentDidMount(){

      firebase.database().ref('/partners/' + this.props.parnterId).once('value').then( snap => {
        this.setState({partner: snap.val() })
      }).catch( error => {
        this.setState({errorMessage: error.message })
      })

    }


render(){

  if (this.state.partner === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  } if (this.state.partner === null){
    return (
      <div style={{padding: '20px'}}>
        No partner found
      </div>
    );
  }  else  {

      let {partner} = this.state
      let details = partner && partner.details

      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

            <div className='col-12 d-flex align-items-top'  style={{padding: '10px 10px'}}>
              <span style={{fontSize: '30px'}}>{details.name}</span>
            </div>

            <div className='col-12' style={{padding: '10px'}}>
            <TextField fullWidth
                value={details.searchDescription} onChange={ (e) => this.setState({ 'searchDescription': e.target.value }) }
                placeholder="Geofilters, millenials, shiny"
                label={'Search description'}
            />
            </div>

            <div className='col-12 row no-gutters' style={{padding: '10px'}}>

                <div className='col-6' style={{ padding: '0px'}}>
                  <b style={{fontSize: '20px'}}>Decks/Files</b>
                    <UploadFiles {...this.state} uploadPath={'partners/' + this.props.parnterId + '/' } firebasePath={'partners/' + this.props.parnterId + '/files/'} />
                </div>

                <div className='col-6' style={{ padding: '0px'}}>
                  <Contacts {...this.state} />
                </div>

            </div>

            <Snackbar
              open={this.state.snackbarOpen}
              style={{width:'50%'}}
              message="Copied to clipboard"
              autoHideDuration={700}
              onClose={ ()=> this.setState({snackbarOpen: false})}
            />

        </div>
      )
    }
  }
}

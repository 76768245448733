import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../../util/loadingbutton';
import Button from '@material-ui/core/Button';
import DatePicker from 'material-ui/DatePicker';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import EditPageHeader from '../../../util/editpageheader'
//<EditPageHeader pageTitle='Campaign Item List' campaignName={this.state.campaignName} />

export default class CampaignInfo extends Component {

    constructor(props) {
      super(props);
      let campaignData = this.props.campaignData ? this.props.campaignData : {}
      let campaignName = campaignData.campaignName ? campaignData.campaignName : ''
      let client = campaignData.client ? campaignData.client : ''
      let subgbu = campaignData.subgbu ? campaignData.subgbu : ''
      let MMbudget = campaignData.MMbudget ? campaignData.MMbudget : 0.00
      let brand = campaignData.brand ? campaignData.brand : ''
      let destinationURL = campaignData.destinationURL ? campaignData.destinationURL : ''
      let tactics = campaignData.tactics ? campaignData.tactics : ''
      let jobNumber = campaignData.jobNumber ? campaignData.jobNumber : ''
      let externalCampaignId = campaignData.externalCampaignId ? campaignData.externalCampaignId : ''
      let poNumber = campaignData.poNumber ? campaignData.poNumber : ''
      let startDate = campaignData.startDate ? new Date(campaignData.startDate) : null
      let endDate = campaignData.endDate ? new Date(campaignData.endDate) : null
      let mm_campaign_id = campaignData.mm_campaign_id ? campaignData.mm_campaign_id : ''
      let facebookCampaignId = campaignData.facebookCampaignId ? campaignData.facebookCampaignId : ''
      let flashTalkingCampaignId = campaignData.flashTalkingCampaignId ? campaignData.flashTalkingCampaignId : ''
      //let digital_investment_level = campaignData.digital_investment_level ? campaignData.digital_investment_level : ''
      //let store_investment_level = campaignData.store_investment_level ? campaignData.store_investment_level : ''
      //let wmxEnabled = campaignData.wmxEnabled ? campaignData.wmxEnabled : false
      let flashTalkingEnabled = campaignData.flashTalkingEnabled ? campaignData.flashTalkingEnabled : false
      let facebookEnabled = campaignData.facebookEnabled ? campaignData.facebookEnabled : false
      let mmEnabled = campaignData.mmEnabled ? campaignData.mmEnabled : false
      let runbySSX = campaignData.runbySSX ? campaignData.runbySSX : false

      this.state = { editing: true, buttonStatus: 'regular', campaignName: campaignName , subgbu: subgbu, brand: brand, externalCampaignId, jobNumber, poNumber,
      client: client, jobNumber: jobNumber, startDate: startDate, endDate: endDate,  mm_campaign_id: mm_campaign_id ,
      facebookCampaignId: facebookCampaignId, digital_investment_level: '', store_investment_level: '', tactics: tactics, destinationURL: destinationURL,
      facebookEnabled : facebookEnabled, flashTalkingEnabled:flashTalkingEnabled, flashTalkingCampaignId: flashTalkingCampaignId,
      mmEnabled: mmEnabled, MMbudget: MMbudget, runbySSX: runbySSX}
    }

updateCampaign(e){
  e.preventDefault()


  if (!this.state.endDate || this.state.campaignName === '' || !this.state.startDate){
    this.setState({errorMessage: <b style={{color: "red"}}>Please provide campaign name and end date</b>})
  } else  {

    console.log('saving')

      this.setState({errorMessage: null, buttonStatus: 'loading'})
      //random campaign uid
      var campaignId = this.props.campaignData.ssx_id

      //var startDateMili = this.state.startDate.getTime()
      //var endDateMili = this.state.endDate.getTime()
      //have to remove the timezone offset on dates. this makes it utc time
      //let serverStartDate = this.state.startDate.getTime() - this.state.startDate.getTimezoneOffset()*60000
      //let serverEndDate = this.state.endDate.getTime() - this.state.endDate.getTimezoneOffset()*60000

      //let startDateUTC = moment.utc(parseInt(startDateMili))
      //let endDateUTC = moment.utc(parseInt(endDateMili))

      let  {startDate, endDate, jobNumber, mm_campaign_id, campaignName,facebookCampaignId, brand, subgbu, client, tactics,
           destinationURL, facebookEnabled, mmEnabled, MMbudget, runbySSX, flashTalkingEnabled, flashTalkingCampaignId, externalCampaignId, poNumber } = this.state

      var updates = {}
      updates['/campaigns/' + campaignId + '/details/startDate'] = startDate.getTime()
      updates['/campaigns/' + campaignId + '/details/endDate'] = endDate.getTime()
      updates['/campaigns/' + campaignId + '/details/jobNumber'] = jobNumber
      updates['/campaigns/' + campaignId + '/details/poNumber'] = jobNumber
      updates['/campaigns/' + campaignId + '/details/externalCampaignId'] = externalCampaignId
      updates['/campaigns/' + campaignId + '/details/mm_campaign_id'] = mm_campaign_id
      //updates['/campaigns/' + campaignId + '/details/digital_investment_level'] = this.state.digital_investment_level
      //updates['/campaigns/' + campaignId + '/details/store_investment_level'] = this.state.store_investment_level
      updates['/campaigns/' + campaignId + '/details/campaignName'] = campaignName
      updates['/campaigns/' + campaignId + '/details/facebookCampaignId'] = facebookCampaignId
      updates['/campaigns/' + campaignId + '/details/flashTalkingCampaignId'] = flashTalkingCampaignId
      updates['/campaigns/' + campaignId + '/details/brand'] = brand
      updates['/campaigns/' + campaignId + '/details/subgbu'] = subgbu
      updates['/campaigns/' + campaignId + '/details/client'] = client
      updates['/campaigns/' + campaignId + '/details/tactics'] = tactics
      updates['/campaigns/' + campaignId + '/details/destinationURL'] = destinationURL
      updates['/campaigns/' + campaignId + '/details/facebookEnabled'] = facebookEnabled
      updates['/campaigns/' + campaignId + '/details/mmEnabled'] = mmEnabled
      updates['/campaigns/' + campaignId + '/details/flashTalkingEnabled'] = flashTalkingEnabled
      updates['/campaigns/' + campaignId + '/details/MMbudget'] =  MMbudget
      updates['/campaigns/' + campaignId + '/details/runbySSX'] = runbySSX

      updates['/campaigns/' + campaignId + '/searchData/details/campaignName' ] = campaignName
      updates['/campaigns/' + campaignId + '/searchData/details/brand' ] = brand
      updates['/campaigns/' + campaignId + '/searchData/details/ssx_id' ] = campaignId
      updates['/campaigns/' + campaignId + '/searchData/objectID'] = campaignId
      updates['/campaigns/' + campaignId + '/searchData/details/startDate' ] = startDate.getTime()
      updates['/campaigns/' + campaignId + '/searchData/details/endDate' ] = endDate.getTime()
      updates['/campaigns/' + campaignId + '/searchData/details/createdBy' ] = this.props.campaignData.createdBy
      updates['/campaigns/' + campaignId + '/searchData/details/dateCreated' ] = this.props.campaignData.dateCreated
      updates['/campaigns/' + campaignId + '/searchData/details/runbySSX' ] =  this.props.campaignData.runbySSX
      updates['/campaigns/' + campaignId + '/searchData/warnings/mmId' ] = !mm_campaign_id && mmEnabled ? true : false

      firebase.database().ref().update(updates).then( ()=>{
        this.setState({buttonStatus: 'saved', errorMessage: null})
      }).catch( (err)=> {
        this.setState({buttonStatus: 'regular', errorMessage: err.message})
        console.log(err)
      })

  }


}

render(){

  /*
  var startDate = moment.utc(parseInt(this.state.startDate.getTime())).local().toDate()
  var endDate = moment.utc(parseInt(this.state.endDate.getTime())).local().toDate()

  console.log( 'state end date: ' + this.state.endDate )
  console.log( endDate )


  console.log(endDate)
*/

console.log(this.props)

  if (this.state.editing === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress />
      </div>
    );
  }  else  {

      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

              <EditPageHeader pageTitle='Campaign Details' campaignName={this.state.campaignName} />

              <div className='col-8'>
                <TextField
                    label='Campaign Name'
                    onChange={ (e) => this.setState({ campaignName: e.target.value}) }
                    placeholder="Gillette Justice League"
                    value={this.state.campaignName}
                    fullWidth={true}
                />
              </div>

              <div className='col-4 d-flex align-items-center'>
              <FormControl  style={{width: '100%'}}>
                <InputLabel>Client</InputLabel>
                <Select  value={this.state.client} onChange={ (e) => this.setState({ client: e.target.value}) }>
                  <MenuItem value={'PGWGCT'} >PGWGCT</MenuItem>
                  <MenuItem value={'PGSams'}  >PGSams</MenuItem>
                  <MenuItem value={'Essilor'}  >Essilor</MenuItem>
                  <MenuItem value={'Coke'}  >Coke</MenuItem>
                  <MenuItem value={'Duracell'} >Duracell</MenuItem>
                  <MenuItem value={'Coty'}  >Coty</MenuItem>
                  <MenuItem value={'Keurig'} >Keurig</MenuItem>
                  <MenuItem value={'Tracfone'} >Tracfone</MenuItem>
                  <MenuItem value={'3M'} >3M</MenuItem>
                  <MenuItem value={'Paqui'} >Paqui</MenuItem>
                  <MenuItem value={'americanGreetings'} >American Greetings</MenuItem>
                </Select>
              </FormControl>
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <DatePicker
                    floatingLabelText='Start Date'
                    dialogContainerStyle={{backgroundColor: ''}}
                    hintText="Start Date"
                    value={this.state.startDate}
                    onChange={ (hold, date)=> this.setState({startDate: date}) }
                 />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <DatePicker
                    floatingLabelText='End Date'
                    //minDate={new Date()}
                    hintText="End Date"
                    value={this.state.endDate}
                    onChange={ (hold, date)=> this.setState({ endDate: date }) }
                 />
              </div>

              {this.state.client === 'PGWGCT' ?
              <div className='col-4 d-flex align-items-center'>
              <FormControl  style={{width: '100%'}}>
                <InputLabel>Category</InputLabel>
                <Select  value={this.state.subgbu} onChange={ (e) => this.setState({ subgbu: e.target.value}) }>
                  <MenuItem disabled={true} value={''}>{this.state.subgbu}</MenuItem>
                  <MenuItem value={'APDO'}  >APDO</MenuItem>
                  <MenuItem value={'Grooming'}  >Grooming</MenuItem>
                  <MenuItem value={'Oral Care'} >Oral Care</MenuItem>
                  <MenuItem value={'Home Care'}   >Home Care</MenuItem>
                  <MenuItem value={'Hair'}  >Hair</MenuItem>
                  <MenuItem value={'Family Care'}>Family Care</MenuItem>
                  <MenuItem value={'Skin Care'} >Skin Care</MenuItem>
                  <MenuItem value={'FFAI'} >FFAI</MenuItem>
                  <MenuItem value={'PHC'} >PHC</MenuItem>
                  <MenuItem value={'Hair'} >Hair</MenuItem>
                  <MenuItem value={'Baby Care'}  >Baby Care</MenuItem>
                  <MenuItem value={'Surface Care'} >Surface Care</MenuItem>
                  <MenuItem value={'PCC'} >PCC</MenuItem>
                  <MenuItem value={'Fabric Care'} >Fabric Care</MenuItem>
                  <MenuItem value={'Scale'} >Scale</MenuItem>
                  <MenuItem value={'Trial Travel'}  >Trial Travel</MenuItem>
                </Select>
              </FormControl>
              </div>
              :
              null }

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <TextField
                    label='Primary Brand' fullWidth
                    onChange={ (e) => this.setState({ brand: e.target.value}) }
                    placeholder="Pampers"
                    value={this.state.brand}
                />
              </div>

              <div className='col-12 d-flex align-items-center' >
                <TextField
                    label='Destination URL' fullWidth
                    onChange={ (e) => this.setState({ destinationURL: e.target.value}) }
                    placeholder="www.walmart.com"
                    value={this.state.destinationURL}
                />
              </div>

              <div className='col-12 d-flex align-items-center' >
                <TextField label='Tactics' multiline
                    onChange={ (e) => this.setState({ tactics: e.target.value}) }
                    placeholder="WPAs, Catapults"
                    value={this.state.tactics}
                    fullWidth={true}
                />
              </div>

              
              <div className='col-12 col-md-4' style={{paddingRight:'10px'}}>
                <TextField fullWidth
                    label='Job Number'
                    onChange={ (e) => this.setState({ jobNumber: e.target.value }) }
                    placeholder="12524"
                    value={this.state.jobNumber}
                />
              </div>

              <div className='col-12 col-md-4' style={{paddingRight:'10px'}}>
                <TextField fullWidth
                    label='PO Number'
                    onChange={ (e) => this.setState({ poNumber: e.target.value }) }
                    placeholder="12524"
                    value={this.state.poNumber}
                />
              </div>

              <div className='col-12 col-md-4' style={{paddingRight: '20px'}}>
                <TextField fullWidth
                    label='Campaign Id'
                    onChange={ (e) => this.setState({ externalCampaignId: e.target.value }) }
                    placeholder="12524"
                    value={this.state.externalCampaignId}
                />
              </div>          

              <div className="col-12">
                {this.state.errorMessage}
              </div>


            <div className="col-12 d-flex justify-content-around" style={{paddingTop: '20px'}}>
                <div>
                  <Button variant='outlined' color='default' onClick={ ()=> this.props.history.push('/campaign/'+this.props.campaignData.ssx_id) }>Back</Button>
                </div>
                <div>
                  <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save' savedLabel='Saved!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='save' regularButtonClick={event => this.updateCampaign(event)} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
                </div>
            </div>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import ListStrategies from './strategies/liststrategies'
import ListCreatives from './creatives/listcreatives'
import UploadStorageFile from './uploads/uploadstoragefile'
import UploadDataFile from './uploads/uploaddatafile'
import ListTrackers from './trackers/listtrackers'

export default class ExternalReport extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignDetails: 'loading', creativeParameters: null, strategyParameters: null }
    }


    componentDidMount(){

      let campaignDetails
      let reportName

      firebase.database().ref('/campaigns/' + this.props.ssxId + '/details').once('value').then( topsnap => {
        if ( !topsnap.val()){ throw new Error('Error, no campaign data.') }
        campaignDetails = topsnap.val()
        return firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/reportName' ).once('value')
      }).then ( snap=> {

        reportName = snap.val()
        return firebase.database().ref('/util/parameters/').once('value')

      }).then ( snap=> {
        let utilParameters = snap.val()
        let creativeParameters = utilParameters && utilParameters.creatives
        let strategyParameters = utilParameters && utilParameters.strategies

        this.setState({ campaignDetails: campaignDetails, reportName: reportName, creativeParameters: creativeParameters, strategyParameters: strategyParameters  })

      }).catch( error => {
        console.log(error)
      });

    }


render(){

  if (this.state.campaignDetails === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>


              <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                <h5><b>Campaign:</b> {this.state.campaignDetails.campaignName}</h5>
                <h5><b>Report:</b>  {this.state.reportName}</h5>
              </div>

              <div className='row' style={{margin: '0px', padding: '0px'}}>
                  <div className='col-12 col-lg-6' style={{padding: '5px', margin: '0px'}}>
                    <UploadStorageFile campaignDetails={this.state.campaignDetails} reportId={this.props.reportId}/>
                  </div>

                  <div className='col-12 col-lg-6' style={{padding: '5px', margin: '0px'}}>
                    <UploadDataFile campaignName={this.state.campaignDetails.campaignName} reportName={this.state.reportName} reportId={this.props.reportId} ssxId={this.props.ssxId}/>
                  </div>
              </div>

              <div className='col-12 col-lg-6' style={{padding: '5px', margin: '0px'}}>
                <ListStrategies {...this.state} {...this.props} />
              </div>

              <div className='col-12 col-lg-6' style={{padding: '5px', margin: '0px'}}>
                <ListCreatives {...this.state} {...this.props}/>
              </div>

              <div className='col-12' style={{padding: '5px', margin: '0px'}}>
                <ListTrackers {...this.state} {...this.props}/>
              </div>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import  {Link} from 'react-router-dom';
import Divider from 'material-ui/Divider';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import * as firebase from 'firebase';
import LinearProgress from 'material-ui/LinearProgress';
import DatePicker from 'material-ui/DatePicker';

export default class BoxCreateCampaign extends Component {

    constructor(props) {
      super(props);
      this.state = {  optimizationDate:  new Date(), comments: '', loading: false };
    }

    optimizationSubmitted(e){
      e.preventDefault()

      if (!this.state.optimizationDate || this.state.comments == ''){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide comments</b>})
      }  else  {

          let newOptimizationId = firebase.database().ref().push().key;
          this.setState({errorMessage: null, loading: true})
          var today = Date.now();
          var newObject = {
                    dateEdited: today,
                    optimizationDate: this.state.optimizationDate.getTime(),
                    editedBy: this.props.email,
                    comments: this.state.comments,
                    id: newOptimizationId
          }

          firebase.database().ref('/campaigns/' + this.props.ssx_id + '/optimizations/' + newOptimizationId).set(newObject).then( ()=>{
            this.setState({loading: false, errorMessage: null} , this.props.passedSetState('createOptimizationBoxOpen', false) )
          }).catch( (err)=> {
            this.setState({errorMessage: err})
            console.log(err)
          })

      }


    }


render(){

    return(

      <div className='row' style={{padding: '20px'}}>

        <div className="col-12">

                  <form onSubmit={ event => console.log('fire') } style={{width: '100%'}}>

                  <div className='col-12 d-flex align-items-center'>
                    <DatePicker
                        floatingLabelText="Optimization Date"
                        dialogContainerStyle={{backgroundColor: ''}}
                        hintText="Start Date"
                        value={this.state.optimizationDate}
                        autoOk={true}
                        onChange={ (hold, date)=> this.setState({optimizationDate: date}) }
                     />
                  </div>


                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        onChange={ (event, value) => this.setState({ comments: value}) }
                        hintText="Raised mobile impressions"
                        multiLine={true}
                        fullWidth={true}
                        floatingLabelText="Optimization Comments"
                        value={this.state.comments}
                    />
                  </div>

                  <div className="col-12">
                  {this.state.errorMessage}
                  </div>
                  </form>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '10px 0px'}}>
                        {this.state.loading == false ?
                          <RaisedButton
                              label="Add"
                              fullWidth={false}
                              secondary={true}
                              type="submit"
                              onClick={ event => this.optimizationSubmitted(event) }
                              />
                        :
                          <RaisedButton
                            style={{height: '100%', backgroundColor: 'white'}}
                            secondary={true}
                            children={ <div style={{padding: '2px', backgroundColor: 'white'}} key={1}><LinearProgress /></div>}
                            fullWidth={false}
                           />
                        }
                    </div>
          </div>

      </div>

      )
    }
}

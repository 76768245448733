import React, {Component} from 'react';
import * as firebase from 'firebase';
import LoadingButton from '../../../util/loadingbutton'
import { parseStrategyName } from './parseMethods/parse.js';
import Button from '@material-ui/core/Button';
import ListParameters from '../../../util/labelparameters/listparameters'
export default class UpdateStrategy extends Component {

    constructor(props) {
      super(props);
      this.state = { targetingChannel: '', partner: '', targetSegment: '', device: '', mediaType: '', buttonStatus: 'regular' }
    }


    componentDidMount(){

        let strategy_id = this.props.selectedStrategy && this.props.selectedStrategy.strategy_id ? this.props.selectedStrategy.strategy_id : null
        let strategy_name = this.props.selectedStrategy && this.props.selectedStrategy.strategy_name ? this.props.selectedStrategy.strategy_name : null
        let mm_strategies_queue = this.props.mm_strategies_queue ?  this.props.mm_strategies_queue : null
        let itemInQueue = mm_strategies_queue ? mm_strategies_queue[strategy_id] : null

      //if an item in in our queue then grab the data that exist in queue, else default to normal
        if (itemInQueue){
          this.setState({  buttonStatus : 'saved' , targetingChannel: itemInQueue.targetingChannel , partner: itemInQueue.partner, targetSegment : itemInQueue.targetSegment,  device: itemInQueue.device,  mediaType: itemInQueue.mediaType })
        } else {
          let parsedStrategyName = parseStrategyName(strategy_name)
          //else just use paramers from the strategy name
          //parseTargetingChannel(1,2)
          this.setState({  buttonStatus: 'regular',  targetingChannel: parsedStrategyName.targetingChannel, partner: parsedStrategyName.partner, targetSegment: parsedStrategyName.targetSegment, mediaType: parsedStrategyName.mediaType, device: parsedStrategyName.device })
        }

    }

    addItemsToQueue = () => {


        this.setState({buttonStatus: 'loading'})

        let {selectedStrategy} = this.props
        let strategy_id = this.props.selectedStrategy.strategy_id
        let partner_campaign_id = selectedStrategy && selectedStrategy.partner_campaign_id ? selectedStrategy.partner_campaign_id : null

        let parameters = { partner_campaign_id,
                           strategy_id: strategy_id,
                           strategy_name : this.props.selectedStrategy.strategy_name,
                           targetingChannel : this.state.targetingChannel,
                           partner : this.state.partner,
                           targetSegment : this.state.targetSegment,
                           device : this.state.device,
                           mediaType : this.state.mediaType,
                             }

        //queue at the campaign level, not the user level
        //queue lives under the campaign
        var updates = {}

        updates['/campaigns/' + this.props.ssx_id + '/mm_strategies_queue/' + strategy_id ] = parameters
        updates['/campaigns/' + this.props.ssx_id + '/mm_strategies/' + strategy_id + '/saved_in_tb/' ] = 'queued'

        firebase.database().ref().update(updates).then( () => {
          this.setState({buttonStatus: 'saved' , errorMessage: null })
        }).catch (err =>{
          this.setState({buttonStatus: 'regular' , errorMessage: err.message })
        });

        //}

  }

  //this is called when person clicks next or back button
  nextStrategy = (position) => {

    let strategy_id = this.props.selectedStrategy.strategy_id
    let indexOfSelected = this.props.arrayOfStrategyIds.indexOf(strategy_id)
    let positionDirection = position === 'next' ? 1 : -1
    let nextIndex = indexOfSelected + positionDirection
    let nextStrategy_id = this.props.arrayOfStrategyIds[nextIndex]

    this.setState({buttonStatus: 'regular'})
    this.props.passedAddedToQUpdateSelected(nextStrategy_id)
  }

  passedSetState = (state,value) => {
    this.setState({ [state]: value})
  }


render(){



    let selectedStrategy = this.props.selectedStrategy
    let mm_strategy_name = this.props.selectedStrategy && this.props.selectedStrategy.mm_strategy_name ? this.props.selectedStrategy.mm_strategy_name : null

          return (
            <div className='row' style={{maring: '0px', padding: '10px 0px 60px'}}>

                  <div className='col-12' style={{padding: '0px 20px'}}>
                    <h4>{selectedStrategy.strategy_name} ({selectedStrategy.strategy_id})</h4>
                    {mm_strategy_name &&  <label style={{color: 'grey'}}>Original Name: {mm_strategy_name} </label> }
                  </div>

                  <ListParameters {...this.state} parameters={this.props.utilParameters.strategies.parameters} passedSetState={this.passedSetState}  />

                  <div className='col-12 d-flex justify-content-end' style={{paddingTop:'20px'}}>
                    {this.state.errorMessage}<br/>
                  </div>
                  <div className='col-12 d-flex justify-content-between' style={{paddingTop:'20px'}}>
                    <div>
                    <Button onClick={ () => this.nextStrategy('back')} color='default' variant='outlined' style={{margin: '0px 1px'}}><i className="material-icons" >keyboard_arrow_left</i> </Button>
                    <Button onClick={ () => this.nextStrategy('next')} color='default' variant='outlined' style={{margin: '0px 1px'}}><i className="material-icons" >keyboard_arrow_right</i> </Button>
                    </div>
                    <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Add To Queue' savedLabel='Added!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='queue' regularButtonClick={this.addItemsToQueue} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
                  </div>

            </div>
          )
        }

}

import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import LoadingButton from '../../../util/loadingbutton';
import DatePicker from 'material-ui/DatePicker';
import  {Link} from 'react-router-dom';

export default class CampaignInfo extends Component {

    constructor(props) {
      super(props);
      let campaignData = this.props.campaignData
      let campaignName = campaignData.campaignName ? campaignData.campaignName : ''
      let startDate = campaignData.startDate ? new Date(campaignData.startDate) : ''
      let endDate = campaignData.endDate ? new Date(campaignData.endDate) : ''
      let totalSpend = campaignData.total_spend ? campaignData.total_spend : ''
      let brand = campaignData.brand ? campaignData.brand : ''
      let destinationURL = campaignData.destinationURL ? campaignData.destinationURL : ''
      let tactics = campaignData.tactics ? campaignData.tactics : ''
      let subgbu = campaignData.subgbu ? campaignData.subgbu : ''

      this.state = { deleteButtonStatus: 'regular', editing: true, buttonStatus: 'regular', campaignName: campaignData.campaignName ,
      startDate: startDate, endDate: endDate, totalSpend: totalSpend, brand: brand, subgbu: subgbu, tactics: tactics, destinationURL:destinationURL }
    }

updateCampaign(e){
  e.preventDefault()

  if (!this.state.endDate || this.state.campaignName == '' || !this.state.startDate){
    this.setState({errorMessage: <b style={{color: "red"}}>Please provide campaign name and end date</b>})
  }  else  {

      this.setState({errorMessage: null, buttonStatus: 'loading'})
      let campaignId = this.props.campaignData.ssx_id

      var updates = {}
      updates['/nationalbrandcampaigns/' + campaignId + '/details/campaignName'] = this.state.campaignName
      updates['/nationalbrandcampaigns/' + campaignId + '/details/brand'] = this.state.brand
      updates['/nationalbrandcampaigns/' + campaignId + '/details/subgbu'] = this.state.subgbu
      updates['/nationalbrandcampaigns/' + campaignId + '/details/tactics'] = this.state.tactics
      updates['/nationalbrandcampaigns/' + campaignId + '/details/destinationURL'] = this.state.destinationURL

      console.log(updates)

      firebase.database().ref().update(updates).then( ()=>{
        this.setState({buttonStatus: 'saved', errorMessage: null})
      }).catch( (err)=> {
        this.setState({buttonStatus: 'regular', errorMessage: err.message})
        console.log(err)
      })

  }


}

deleteCampaign(){
  firebase.database().ref('/nationalbrandcampaigns/' + this.props.campaignData.ssx_id).remove()
}

render(){

  if (this.state.editing == 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

              <div className='col-12'>
                <TextField
                    floatingLabelText='Campaign Name'
                    onChange={ (event, value) => this.setState({ campaignName: value}) }
                    hintText="Gillette Justice League"
                    value={this.state.campaignName}
                    fullWidth={true}
                />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <DatePicker
                    disabled={true}
                    floatingLabelText='Start Date'
                    dialogContainerStyle={{backgroundColor: ''}}
                    hintText="Start Date"
                    value={this.state.startDate}
                    onChange={ (hold, date)=> this.setState({startDate: date}) }
                 />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <DatePicker
                    disabled={true}
                    floatingLabelText='End Date'
                    minDate={new Date()}
                    hintText="End Date"
                    value={this.state.endDate}
                    onChange={ (hold, date)=> this.setState({endDate: date}) }
                 />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <TextField
                    disabled={true}
                    floatingLabelText='Total Spend'
                    onChange={ (event, value) => this.setState({ totalSpend: value}) }
                    hintText="100000"
                    value={this.state.totalSpend}
                    fullWidth={true}
                />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <TextField
                    floatingLabelText='Sub-GBU/Category'
                    onChange={ (event, value) => this.setState({ subgbu: value}) }
                    hintText="Baby"
                    value={this.state.subgbu}
                />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <TextField
                    floatingLabelText='Brand'
                    onChange={ (event, value) => this.setState({ brand: value}) }
                    hintText="Pampers"
                    value={this.state.brand}
                />
              </div>

              <div className='col-12 col-md-4 d-flex align-items-center'>
                <TextField
                    floatingLabelText='Destination URL'
                    onChange={ (event, value) => this.setState({ destinationURL: value}) }
                    hintText="www.walmart.com"
                    value={this.state.destinationURL}
                    multiLine={true}
                    fullWidth={true}
                />
              </div>

              <div className='col-12 d-flex align-items-center'>
                <TextField
                    floatingLabelText='Tactics'
                    onChange={ (event, value) => this.setState({ tactics: value}) }
                    hintText="WPAs, Catapults"
                    value={this.state.tactics}
                    multiLine={true}
                    fullWidth={true}
                />
              </div>

            <div className="col-12 d-flex justify-content-around" style={{paddingTop: '20px'}}>
                <div>
                  <RaisedButton containerElement={<Link to='/nationalbrandcampaigns' />} label='Back' primary={true} />
                </div>
                <div>
                  <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save' savedLabel='Saved!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='save' regularButtonClick={event => this.updateCampaign(event)} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
                </div>
            </div>

            <div style={{position: 'absolute', top: '0px', right: '15px'}}>
              <LoadingButton buttonStatus={this.state.deleteButtonStatus} regularLabel='Delete Campaign' savedLabel='Click To Confirm Delete' regularbuttonColor='black'  savedbuttonColor='red' icon='delete' regularButtonClick={event => this.setState({deleteButtonStatus: 'saved'})} savedButtonClick={ ()=> this.deleteCampaign()} />
            </div>

        </div>
      )
    }
  }
}

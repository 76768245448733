import React, {Component} from 'react';
import * as firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import  {Link} from 'react-router-dom';

import * as algoliasearch from 'algoliasearch';
var client = algoliasearch('FKWKD7663I', '75e62fadd51d3dd0357d74b338b4d4e3');
var index = client.initIndex('partners_list');
export default class Campaign extends Component {

    constructor(props) {
      super(props);
      this.state = { partners: 'loading' }
    }

    componentDidMount(){


      firebase.database().ref('/partners/').once('value').then( snap => {
        if ( !snap.val()){
          throw new Error('Error, no parnters.')
        }

        let tempArray = []

        snap.forEach( current => {
          tempArray.push( current.val() )
        })

        this.setState({partners: tempArray})
      }).catch( error => {
        this.setState({partners: 'ERROR'})
      })


    }

    //this is called, and waits before actually asking algolia to query the data
    typedQuery = (searchTerm) =>{
      this.setState({searchTerm: searchTerm})

      clearTimeout(this.state.timeout);
      // Make a new timeout set to go off in 1000ms
      let tempTimeout = setTimeout( ()=> {
           this.search(searchTerm)
      }, 700);

      this.setState({ timeout: tempTimeout, loading: true})

    }

    search = (searchTerm) =>{

    console.log('CALLING SEARCH: ' +  searchTerm)
    let filter = 'details.runbySSX:' + !this.state.filterNationalOnly

      index.search({ query: searchTerm, filters: filter}, (err, content)=> {
            if (err) throw err;
            console.log(content)
            this.filterCampaigns(content.hits)
          }
        );
    }


render(){


  if (this.state.partners === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

    let partnersList = this.state.partners.map((current, index) => {
      console.log(current)
          //return <CampaignItem key={index} campaign={ currentCampaign }/>
          return ( <div key={index} style={{border: '1px #ddd solid', borderRadius: '3px', padding: '5px'}}>
                      <Link to={'/partner/' + current.details.id} key={index}>{current.details.name}</Link>
                   </div>
                 ) });


      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

            <div className='col-12' style={{margin: '0px', padding: '20px'}}>
                <span style={{fontSize: '30px'}}>Partners</span>

                <TextField  placeholder='Search "snapchat" or "augmented reality"'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                        <i className="material-icons" >search</i>
                     </InputAdornment>
                                 ),
                            }}
                 fullWidth={true} value={this.state.searchTerm}
                onChange={ (e)=> this.typedQuery(e.target.value)} />
            </div>

            <div className='col-12' style={{margin: '0px', padding: '0px 20px'}}>
              {partnersList}
            </div>

        </div>
      )
    }
  }
}

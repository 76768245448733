import React, {Component} from 'react';
import * as firebase from 'firebase';
import LinearProgress from 'material-ui/LinearProgress';
import Papa from 'papaparse';
import LoadingButton from '../util/loadingbutton'
import UploadFileTable from './uploadFileTable'
import RaisedButton from 'material-ui/RaisedButton';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';

export default class UploadFileSQL extends Component {

    constructor(props) {
      super(props);
      this.state = {  reportType: '', errorMessage: null, status: 'preview', file: null, reportTypeCSVColumns: null, wmxWeek: '', wmxFY: '2019', uploadSQLMessage: '' }
    }

    isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    }

//this is called when the dropdown is selected
getReportTypeData = (event, index, reportType) => {

console.log('reportType')
console.log(reportType)

  this.setState({reportType: 'loading'})

    firebase.database().ref('/uploadFileSQL/reportTypes/' + reportType + '/csvcolumns').once('value').then( snap => {
        if ( !snap.val()){
          this.setState({reportType: 'Error', errorMessage: 'Error: ' + reportType + ' report does not exist'})
          return
        }

      this.setState({reportType: reportType, reportTypeCSVColumns: snap.val(), errorMessage: null })

    }).catch( error => {
        this.setState({reportType: 'Error', errorMessage: error.message})
    })

  }

  componentWillUnmount(){
    //stop listenting for this campaign
    if (this.state.firebaseListener){
      this.state.firebaseListener.off()
    }
  }


parseFile(e){

  if (this.state.reportType === ''){
     //this is weird, but we have to reset the form. Otherwise input won't recognize the next upload
     document.getElementById("input-form").reset();
     this.setState({errorMessage: 'Please select report type'})
  } else if (this.state.reportType === 'wmxWeekly' && this.state.wmxWeek === '' ){
    document.getElementById("input-form").reset();
    this.setState({errorMessage: 'Please add Walmart week'})
  } else if (this.state.reportType === 'wmxWeekly' && this.state.wmxFY === '' ) {
    document.getElementById("input-form").reset();
    this.setState({errorMessage: 'Please add Walmart Fiscal Year'})
  } else {

  this.setState({errorMessage: null})
  var file = e.target.files[0]
  if (!file){
    return null
  }

  Papa.parse(file, {
     header: true,
     download: true,
     skipEmptyLines: true,
     //only do the first 5 lines. We preview/confirm on browser, and send heavy work to server
     preview: 10,
     error: this.papaError,
     complete: this.papaComplete
   });

 }


}

papaComplete = (result, file) => {
    const data = result.data;

    //object assing copies the object instead of using it as a reference
    let deleteReportObject = Object.assign({}, this.state.reportTypeCSVColumns)


    //loop through our report required csv columns
     for (let current in this.state.reportTypeCSVColumns) {

        //if our current require sql column exist in our data delete if from deleteReportObject
        //this will leave us with an object of required columns not in csv
        if (current in data[0]){
          delete deleteReportObject[current]
        }

     }

     if ( this.isEmpty(this.state.reportTypeCSVColumns) ){
       document.getElementById("input-form").reset();
       this.setState({errorMessage: 'Error with report type. Please contact support.'})
       return
     } else if ( !this.isEmpty(deleteReportObject) ){
       let missingColumns = ''
        for (let key in deleteReportObject) {
          missingColumns+= key + ', '
        }
        //remove the last comma
       let slicedString = missingColumns.slice(0, -2)
       document.getElementById("input-form").reset();
       this.setState({errorMessage: 'Error! CSV Missing Columns: '+ slicedString})
     } else {
       this.setState({status: 'readyForStorage', jsonData: data, file: file})
     }

  }

papaError = (error) => {
      console.log('Papa ERROR')
      console.log(error)
      this.setState({errorMessage: 'Papaparse Error! Contact Support.'})
  }


uploadFile = () => {

    this.setState({status: 'uploadingSQL', uploadSQLMessage: 'Adding file to database' })

    let newUploadId = firebase.database().ref().push().key;
    var file = this.state.file
    var filename =  file.name
    var storageref = firebase.storage().ref('uploadFileSQL/' + this.state.reportType + '/' +  newUploadId + '/' + filename )
    var task = storageref.put(this.state.file)

    task.on('state_changed',
    //progress
      (snapshot) => {

    }, //error
     (err) => {

    }, //complete
      (data)=> {


      storageref.getDownloadURL().then( url => {
        let today = Date.now()
        let inverseToday = today * -1
        var object = {
          fileurl: url,
          fileName: filename,
          reportType: this.state.reportType,
          wmxFY: this.state.reportType === 'wmxWeekly' ? this.state.wmxFY : null,
          wmxWeek: this.state.reportType === 'wmxWeekly' ? this.state.wmxWeek : null,
          status: 'uploadingSQL',
          message: 'Adding data to SQL table',
          id: newUploadId,
          dateCreated: today,
          inverseDateCreated: inverseToday
        }

        return firebase.database().ref('uploadFileSQL/uploads/' + newUploadId ).update(object)

      }).then (err=> {
          console.log('SUCCESS')
          window.location = '/listuploads'
        }).catch (err=> {
          this.setState({status: 'preview', errorMessage: err.message})
          console.log(err)
        })

    }

    )

  }


render(){

  /*
  <MenuItem value={'wmxWeekly'} primaryText={`wmxWeekly`} />
  <MenuItem value={'pgWmtEcommSearchWebMetrics'} primaryText={`pgWmtEcommSearchWebMetrics`} />
  <MenuItem value={'pgWmtEcommOmWeekSales'} primaryText={`pgWmtEcommOmWeekSales`} />
  */

  if (this.state.reportType === 'loading' || this.state.reportType === '' ){


    return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>

          <div className='col-12'>
          <h4>Upload File to SQL Tables</h4>
          </div>

          <div className='col-12' >
            <SelectField
                 floatingLabelText="Report Type"
                 value={this.state.reportType}
                 onChange={ this.getReportTypeData }
                 autoWidth={true}
               >
              <MenuItem value={'pgWmtEcommSales'} primaryText={`pgWmtEcommSales`} />
            </SelectField>
          </div>

          {this.state.reportType === 'loading'?
            <div style={{ width: '255px'}}>
              <LinearProgress mode="indeterminate" />
            </div>
            :
            null
          }

          <div className='col-12' style={{padding: '10px'}}>
          <h6 style={{color:'red', margin: '0px'}}><b>{this.state.errorMessage}</b></h6>
          </div>

        </div>
      );
} else if (this.state.status === 'preview'){
//if (1 == 'preview'){

  const styles = {
  button: {margin: 12, },
  exampleImageInput: { cursor: 'pointer', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, width: '100%', opacity: 0,},};


    return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>

        <div className='col-12'>
        <h4>Upload File to SQL Tables</h4>
        </div>

        <div className='col-12 d-flex align-items-center'>
          <SelectField
               floatingLabelText="Report Type"
               value={this.state.reportType}
               onChange={ this.getReportTypeData }
               autoWidth={true}
             >
            <MenuItem value={'wmxWeekly'} primaryText={`wmxWeekly`} />
            <MenuItem value={'pgWmtEcommSales'} primaryText={`pgWmtEcommSales`} />
            <MenuItem value={'pgWmtEcommSearchWebMetrics'} primaryText={`pgWmtEcommSearchWebMetrics`} />
            <MenuItem value={'pgWmtEcommOmWeekSales'} primaryText={`pgWmtEcommOmWeekSales`} />
          </SelectField>
        </div>

        {this.state.reportType === 'wmxWeekly' ?
        <div className='row' style={{padding:'0px', margin: '0px'}}>
        <div className='col-12 d-flex align-items-center'>
          <SelectField
          floatingLabelText="Walmart Week"
          value={this.state.wmxWeek} onChange={ (event, index, value) => {this.setState({wmxWeek: value})} }>
            <MenuItem value={'00'} primaryText={`00`} />
            <MenuItem value={'01'} primaryText={`01`} />
            <MenuItem value={'02'} primaryText={`02`} />
            <MenuItem value={'03'} primaryText={`03`} />
            <MenuItem value={'04'} primaryText={`04`} />
            <MenuItem value={'05'} primaryText={`05`} />
            <MenuItem value={'06'} primaryText={`06`} />
            <MenuItem value={'07'} primaryText={`07`} />
            <MenuItem value={'08'} primaryText={`08`} />
            <MenuItem value={'09'} primaryText={`09`} />
            <MenuItem value={'10'} primaryText={`10`} />
            <MenuItem value={'11'} primaryText={`11`} />
            <MenuItem value={'12'} primaryText={`12`} />
            <MenuItem value={'13'} primaryText={`13`} />
            <MenuItem value={'14'} primaryText={`14`} />
            <MenuItem value={'15'} primaryText={`15`} />
            <MenuItem value={'16'} primaryText={`16`} />
            <MenuItem value={'17'} primaryText={`17`} />
            <MenuItem value={'18'} primaryText={`18`} />
            <MenuItem value={'19'} primaryText={`19`} />
            <MenuItem value={'20'} primaryText={`20`} />
            <MenuItem value={'21'} primaryText={`21`} />
            <MenuItem value={'22'} primaryText={`22`} />
            <MenuItem value={'23'} primaryText={`23`} />
            <MenuItem value={'24'} primaryText={`24`} />
            <MenuItem value={'25'} primaryText={`25`} />
            <MenuItem value={'26'} primaryText={`26`} />
            <MenuItem value={'27'} primaryText={`27`} />
            <MenuItem value={'28'} primaryText={`28`} />
            <MenuItem value={'29'} primaryText={`29`} />
            <MenuItem value={'30'} primaryText={`30`} />
            <MenuItem value={'31'} primaryText={`31`} />
            <MenuItem value={'32'} primaryText={`32`} />
            <MenuItem value={'33'} primaryText={`33`} />
            <MenuItem value={'34'} primaryText={`34`} />
            <MenuItem value={'35'} primaryText={`35`} />
            <MenuItem value={'36'} primaryText={`36`} />
            <MenuItem value={'37'} primaryText={`37`} />
            <MenuItem value={'38'} primaryText={`38`} />
            <MenuItem value={'39'} primaryText={`39`} />
            <MenuItem value={'40'} primaryText={`40`} />
            <MenuItem value={'41'} primaryText={`41`} />
            <MenuItem value={'42'} primaryText={`42`} />
            <MenuItem value={'43'} primaryText={`43`} />
            <MenuItem value={'44'} primaryText={`44`} />
            <MenuItem value={'45'} primaryText={`45`} />
            <MenuItem value={'46'} primaryText={`46`} />
            <MenuItem value={'47'} primaryText={`47`} />
            <MenuItem value={'48'} primaryText={`48`} />
            <MenuItem value={'49'} primaryText={`49`} />
            <MenuItem value={'50'} primaryText={`50`} />
            <MenuItem value={'51'} primaryText={`51`} />
            <MenuItem value={'52'} primaryText={`52`} />
          </SelectField>
        </div>

        <div className='col-12 d-flex align-items-center'>
          <SelectField  floatingLabelText="Walmart Fiscal Year"
          value={this.state.wmxFY} onChange={ (event, index, value) => {this.setState({wmxFY: value})} }>
            <MenuItem value={'2018'} primaryText={`2018`} />
            <MenuItem value={'2019'} primaryText={`2019`} />
            <MenuItem value={'2020'} primaryText={`2020`} />
            <MenuItem value={'2021'} primaryText={`2021`} />
          </SelectField>
        </div>

        </div>
        : null }

        <div className='col-12' style={{padding: '10px'}}>
        <h6 style={{color:'red', margin: '0px'}}><b>{this.state.errorMessage}</b></h6>
        </div>

        {this.state.reportType === '' ? null :
        <div className='col-12 d-flex justify-content-center'>
        <RaisedButton
          label="Select File" labelPosition="before" style={styles.button} containerElement="label"
          icon={<i style={{ cursor: 'pointer'}} className="material-icons" >file_upload</i>}  >
          <form id="input-form">
            <input type="file" accept=".csv" style={styles.exampleImageInput} onChange={(e) => this.parseFile(e)} />
          </form>
        </RaisedButton>
        </div>
        }

        </div>
      )
  } else  {

    let loadingButtonStatus = this.state.status === 'readyForStorage' ? 'regular' : 'loading'
    let pageTitle

    if (this.state.reportType === 'wmxWeekly'){
      pageTitle = 'Upload ' + this.state.reportType + ' for Walmart week ' + this.state.wmxWeek + ' and fiscal year ' + this.state.wmxFY
    } else {
      pageTitle= 'Upload File to SQL Tables'
    }

    return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>

            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
            <h4>{pageTitle}</h4>
            </div>

            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
            <span style={{color:'grey'}}>Here is a preview of the {this.state.reportType} upload:</span>
            </div>

            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
              <UploadFileTable jsonData={this.state.jsonData} reportTypeCSVColumns={this.state.reportTypeCSVColumns} />
            </div>

            <div className='col-12' style={{padding: '10px'}}>
            <h6>{this.state.errorMessage}</h6>
            </div>


            <div className='col-12 d-flex justify-content-center'>
              <LoadingButton buttonStatus={loadingButtonStatus} regularLabel='Upload Now' savedLabel='Uploaded!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='file_upload' regularButtonClick={ () => this.uploadFile() } savedButtonClick={ ()=> {return null} } />
            </div>

        </div>


      )
    }
  }
}

import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

export default function ListParameters(props){

      let parameters = props.parameters[props.tabOpen].parameters
      let listParameter = []

          //loop through all the paramter options
          for (let key in parameters){
            let currentParameter = parameters[key]
            let options = []

            if (currentParameter.type !== 'select'){
              options =  (
                <ListItem >
                  <span>Open ended value</span>
                </ListItem>
                          )
            } else {
              for (let key in currentParameter.options){
                let currentOption = currentParameter.options[key]
                  options.push(
                  <ListItem key={currentOption.value} >
                    <ListItemText primary={currentOption.label} secondary={'SQL value: ' + currentOption.value} />
                    <ListItemSecondaryAction>
                          <IconButton onClick={ ()=> props.deleteOption(currentParameter.id, currentOption.id)} >
                            <i className="material-icons" >delete</i>
                          </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
            }

          //add the add item at the end
          options.push(
            <ListItem key={'addValue'} button style={{borderTop: '1px solid #ddd'}} onClick={ ()=> props.openAddBox(  true,  currentParameter.title,  currentParameter.id )}>
              <ListItemIcon>
                <i style={{color: '#4caf50'}} className="material-icons" >add_box</i>
              </ListItemIcon>
              <ListItemText primary='Add Item' />
            </ListItem>
          )

        }


        listParameter.push(
            <div className='col-4' key={currentParameter.id} style={{padding: '5px'}}>
            <b>{currentParameter.title}</b>
              <List style={{border: '1px #ddd solid'}}>
                {options}
              </List>
            </div>
          )

      }




      return (
        <div className='col-12 row no-gutters' style={{paddingTop: '20px'}}>
          {listParameter}
        </div>
      )
}

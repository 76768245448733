import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import * as firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class BoxCreateBudgetPlanner extends Component {

    constructor(props) {
      super(props);
      this.state = { name: '', loading: false };
    }

    submit(e){
      e.preventDefault()

      if ( this.state.partner === '' || this.state.reportName === ''  ){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide all inputs</b>})
      }  else  {

          //unique key unique to the entire world/db
          let newId = firebase.database().ref().push().key;

          return firebase.database().ref('/campaigns/' + this.props.ssx_id + '/budgetplanners/' + newId ).set({ name: this.state.name, id: newId}).then( ()=>{
            this.setState({loading: false, errorMessage: null} , this.props.passedSetState('createBoxOpen', false) )
          }).catch( (err)=> {
            this.setState({errorMessage: err})
            console.log(err)
          })

      }


    }


render(){

    return(
      <>
      <DialogTitle id="alert-dialog-title">{"Add Budget Plan"}</DialogTitle>
      <div className='row' style={{padding: '20px', minWidth: '450px'}}>
        <div className="col-12" >

                  <form onSubmit={ event => console.log('fire') } style={{width: '100%', padding: '0px', margin: '0px'}} className="row" >

                  <div className='col-12'>
                    <TextField
                        onChange={ (e) => this.setState({ name: e.target.value }) }
                        placeholder="Facebook"
                        label="Budget Name"
                        value={this.state.name}
                    />
                  </div>

                  <div className="col-12">
                  {this.state.errorMessage}
                  </div>
                  </form>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '10px 0px'}}>
                        {this.state.loading === false ?
                          <Button variant="contained" type="submit" color="primary" onClick={ event => this.submit(event) }>
                            Add
                          </Button>
                        :
                          <LinearProgress />
                        }
                    </div>
          </div>

      </div>
      </>

      )
    }
}

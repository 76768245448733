import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import  {Link} from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import BoxCreate from './boxcreatebudgetplanner';

export default class ListBudgetPlanner extends Component {

    constructor(props) {
      super(props);
      this.state = { budgetPlanners: 'loading', ssx_id: this.props.ssx_id, createBoxOpen: false }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
      this.state.firebaseListener.off()
    }

    componentDidMount(){

      let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssx_id + '/budgetplanners')
      firebaseListener.on('value', (snap)=> {
        let tempArray = []
        let budgetPlanners = snap.val() ? snap.val() :null

          for (let current in budgetPlanners){
            tempArray.push(budgetPlanners[current])
          }

        this.setState({campaignData: snap.val(), budgetPlanners: tempArray , firebaseListener: firebaseListener})
      });

    }

    handleDialogClose = () => this.setState({createBoxOpen: false}) ;

    passedSetState = (state, value) => {

      this.setState({[state]: value})
    }

render(){

  if (this.state.budgetPlanners === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      const budgetPlanners = this.state.budgetPlanners.map((current, index) => {
            return (
              <Link to={'/edit/budgetplanner/' + this.props.ssx_id + '/' + current.id} className='row' key={index} style={{margin: '0px', border: '1px solid', borderColor: '#ddd', backgroundColor:'', borderRadius:'1px', padding: '10px'}} >
                {current.name}
              </Link>
            )
        })

      return (
        <div className='row' style={{margin: '0px', padding: '30px'}}>

              <div className='col-12' style={{padding: '0px 0px 0px 0px', margin: '0px'}}>
                <b style={{fontSize: '20px'}}>Budget Planners:</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({createBoxOpen: true})}>add_box</i> <br />
              </div>

              <div className='col-12' style={{padding: '0px'}}>
                    {budgetPlanners}
              </div>

              <Dialog
                  open={ this.state.createBoxOpen === true  }
                  onClose={this.handleDialogClose} >
                  <BoxCreate email={this.props.email} ssx_id={this.props.ssx_id} passedSetState={this.passedSetState}   />
              </Dialog>

        </div>
      )
    }
  }
}

/*
<div className='col-12' style={{padding: '0px 0px 0px 0px', margin: '0px'}}>
<h4>{this.state.campaignData.details.campaignName}</h4>
  <b style={{fontSize: '20px'}}>Budget Planners:</b>
  <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({createBoxOpen: true})}>add_box</i> <br />
</div>
*/

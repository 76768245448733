import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BoxStrategyDetails from './boxstrategydetails'
import StrategyItem from './strategyitem'

export default class ExternalReport extends Component {

    constructor(props) {
      super(props);
      this.state = { strategies: 'loading', boxStrategyDetails: false}
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }


    componentDidMount(){

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/strategies' )
        firebaseListener.on('value', (snap)=> {
          let tempArray = []
          let strategies = snap.val()
          for (let current in strategies){
                tempArray.push(strategies[current])
          }

          this.setState({ strategies: tempArray, firebaseListener: firebaseListener})
        });


    }

//this is called when the create button is clicked, but first we need to create
//a new strategy id
createNewStrategy = () =>{

  let strategyId
  firebase.database().ref('util/nextStrategyID').transaction( (id) => {
    console.log('StrategyId: ' + id)
        //if (!id) { throw {message: 'Error connect to database. Please try again later.'} }
        strategyId = 't' + id
        return id + 1

  }).then( ()=> {

  this.setState({boxStrategyDetails: true, strategyId: strategyId})

  })
}


handleDialogClose = () => this.setState({boxStrategyDetails: false}) ;

passedSetState = (state, value) => { this.setState({[state]: value}) }

render(){

  if (this.state.strategies === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

    const externalStrategies = this.state.strategies.map((current, index) => {
          return (
              <StrategyItem key={index} campaignDetails={this.props.campaignDetails} currentStrategy={current} reportId={this.props.reportId} strategyParameters={this.props.strategyParameters} passedSetState={this.passedSetState}/>
          )
      })

      return (
        <div className='row' style={{margin: '0px', padding: '0px'}}>

              <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                <b style={{fontSize: '17px'}}>Strategies:</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={this.createNewStrategy}>add_box</i> <br />
              </div>


              <div className='col-12' style={{padding: '0px'}}>
                  {externalStrategies}
              </div>

              <Dialog open={ this.state.boxStrategyDetails === true  } onClose={this.handleDialogClose} >
                <DialogTitle>{"Create Strategy"}</DialogTitle>
                <DialogContent>
                  <BoxStrategyDetails strategyId={this.state.strategyId} campaignDetails={this.props.campaignDetails} reportId={this.props.reportId} parameters={this.props.strategyParameters.parameters}  passedSetState={this.passedSetState}    />
                </DialogContent>
              </Dialog>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import Button from '@material-ui/core/Button';
import RedirectItem from './redirectItem';

export default class Redirect extends Component {

    constructor(props) {
      super(props);
      this.state = { redirects: 'loading'}
    }

    componentDidMount(){

      let campaignName

      firebase.database().ref('/campaigns/' + this.props.ssxId + '/details/campaignName').once('value').then( snap => {
        if ( !snap.val()){ throw new Error('Error, no campaign data.') }

        campaignName = snap.val()
        return  firebase.database().ref('/campaigns/' + this.props.ssxId + '/redirects').once('value')

      }).then ( (redirectSnap)=> {
        this.setState({ campaignName: campaignName, redirects: redirectSnap.val() })

      }).catch( error => {
        console.log(error)
        this.setState({ errorMessage: error.message })
      });

    }

    createNewRedirect = () =>{

      let id = firebase.database().ref().push().key;
      let newObject = { id, fbPixelId: "415128432692739", gaId: "UA-147140364-1", medium: "", redirectURL: "", ssxId: this.props.ssxId}
      let tempRedirects = this.state.redirects ? this.state.redirects : {}
      tempRedirects[id] = newObject
      this.setState({redirects: tempRedirects})
    }

    downloadData = async () =>{

      try{

        let rowsArray = []
        let dailyData = {}
        let dataSnap = await firebase.database().ref("campaigns/" + this.props.ssxId + "/redirectAnalytics/list").once('value')
        dataSnap.forEach( current => {
          let {mediums = {} } = current.val()
          let mediumsKeys = Object.keys(mediums)
          mediumsKeys.forEach( mediumId => {
            let {daily} = mediums[mediumId]
            let dailyKeys = Object.keys(daily)
            dailyKeys.forEach( dayId => {
              let {summary} = daily[dayId]
              dailyData[dayId] = dailyData[dayId] ? dailyData[dayId]: {}
              dailyData[dayId][mediumId] = dailyData[dayId] && dailyData[dayId][mediumId] ? dailyData[dayId][mediumId] : {}
              dailyData[dayId][mediumId] =  summary
            })
          })
        })

        let dailyDataKeys = Object.keys(dailyData)
        dailyDataKeys.forEach( dayId => {
          let mediumsObject = {date: dayId}
          let daySummary = 0
          let mediums = dailyData[dayId]
          let mediumsKeys = Object.keys(mediums)
            mediumsKeys.forEach( mediumId => {
              let summary = mediums[mediumId]
              daySummary += summary
              mediumsObject[mediumId] = summary
          })
          mediumsObject.total = daySummary
          rowsArray.push(mediumsObject)
        })

        let csvContent = "";
        let columns = ""
        let rows = ""
        let fileName = "redirect_data.csv"
        rowsArray.forEach( (currentRow, rowIndex) => {
            let currentRowKeys = Object.keys(currentRow)
            currentRowKeys.forEach( (key) => {
              //build out top columns
              if (rowIndex === 0) columns += key + ","
              let current = currentRow[key]
              rows += '"' + current + '",'
            })
            //remove comma
            rows = rows.slice(0, -1);
            //add new line
            rows += "\r\n";
        });
        columns += "\r\n";
        csvContent = csvContent + columns + rows
        //var encodedUri = encodeURI(csvContent);
        //window.open(encodedUri);
        var blob = new Blob([csvContent]);
        if (window.navigator.msSaveOrOpenBlob){
            // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, fileName);
        } else {
            var a = window.document.createElement("a");
            a.href = window.URL.createObjectURL(blob, {type: "text/plain"});
            a.download = fileName;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
        }



    } catch(err){
      console.error(err.message)
    }

    }


render(){

  if (this.state.redirects ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress />
        {this.state.errorMessage}
      </div>
    );
  }  else  {

      let redirectKeys = this.state.redirects ? Object.keys(this.state.redirects) : []
      redirectKeys.reverse()
      let showRedirects = redirectKeys.map( key =>{
        let current = this.state.redirects[key]
        return <RedirectItem key={current.id} current={current} ssxId={this.props.ssxId} />
      })

      return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>

              <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                <h3>Redirects for: {this.state.campaignName}</h3>
              </div>

              <div className='col-12' style={{marginBottom: '15px', display: "flex", justifyContent: "space-between"}}>
                <Button variant="contained" color="secondary" onClick={ ()=> this.createNewRedirect() }>
                  Add New
                </Button>
                <Button variant="outlined" onClick={ ()=> this.downloadData() }>
                  <i className="material-icons" style={{fontSize: "18px", marginRight: "5px"}} >download</i>
                  Download Analytics
                </Button>
              </div>

              {showRedirects}

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LoadingButton from '../../../util/loadingbutton'
import { parseCreativeName } from './parseMethods/parse.js';
import ListParameters from '../../../util/labelparameters/listparameters'

export default class UpdateCreative extends Component {

    constructor(props) {
      super(props);
      this.state = {   buttonStatus: 'regular', creativeCampaignName: '', creativeImage: '', cta: '', mediaFormat: '', landingPage: '', size: '', customOne: '', customTwo: '' }
    }

    componentDidMount(){

      //when to component first loads, we need to see if values already exist in que, or parse values from creative name
      //the key on the comp that calls this component will force react to re-render state
      //key={this.state.selectedCreative.creative_id}

        let creative_id = this.props.selectedCreative && this.props.selectedCreative.creative_id ? this.props.selectedCreative.creative_id : null
        let creative_name = this.props.selectedCreative && this.props.selectedCreative.creative_name ? this.props.selectedCreative.creative_name : null
        let mm_creatives_queue = this.props.mm_creatives_queue ?  this.props.mm_creatives_queue : null
        let itemInQueue = mm_creatives_queue ? mm_creatives_queue[creative_id] : null

      //if an item in in our queue then grab the data that exist in queue, else default to normal
        if (itemInQueue){
          this.setState({  buttonStatus : 'saved' , creativeCampaignName: itemInQueue.creativeCampaignName , creativeImage: itemInQueue.creativeImage, cta : itemInQueue.cta,  mediaFormat: itemInQueue.mediaFormat,  landingPage: itemInQueue.landingPage, size: itemInQueue.size, customOne: itemInQueue.customOne, customTwo: itemInQueue.customTwo  })
        } else {
          let parsedCreativeName = parseCreativeName(creative_name)
          //else just use paramers from the creative name
          this.setState({  buttonStatus: 'regular',  creativeCampaignName: parsedCreativeName.creativeCampaignName, creativeImage: parsedCreativeName.creativeImage, cta: parsedCreativeName.cta, landingPage: parsedCreativeName.landingPage, mediaFormat: parsedCreativeName.mediaFormat, size: parsedCreativeName.size, customOne: parsedCreativeName.customOne, customTwo: parsedCreativeName.customTwo })
        }

    }


    addItemsToQueue = () => {


       this.setState({buttonStatus: 'loading'})

       let {selectedCreative} = this.props
       let partner_campaign_id = selectedCreative && selectedCreative.partner_campaign_id ? selectedCreative.partner_campaign_id : null


       let creative_id = selectedCreative.creative_id
       let parameters = {  partner_campaign_id,
                           creative_id: creative_id,
                           creative_name : selectedCreative.creative_name,
                           creativeCampaignName : this.state.creativeCampaignName,
                           creativeImage : this.state.creativeImage,
                           cta : this.state.cta,
                           mediaFormat : this.state.mediaFormat,
                           landingPage : this.state.landingPage,
                           size: this.state.size,
                           customOne: this.state.customOne,
                           customTwo: this.state.customTwo

                             }

        //queue at the campaign level, not the user level
        //queue lives under the campaign
        var updates = {}

        updates['/campaigns/' + this.props.ssx_id + '/mm_creatives_queue/' + creative_id ] = parameters
        updates['/campaigns/' + this.props.ssx_id + '/mm_creatives/' + creative_id + '/saved_in_tb/' ] = 'queued'

        firebase.database().ref().update(updates).then( () => {
          this.setState({buttonStatus: 'saved' , errorMessage: null })
        }).catch (err =>{
          this.setState({buttonStatus: 'regular' , errorMessage: err.message })
        });

        //}

  }

  //this is called when person clicks next or back button
  nextCreative = (position) => {

    let creative_id = this.props.selectedCreative.creative_id
    let indexOfSelected = this.props.arrayOfCreativeIds.indexOf(creative_id)
    let positionDirection = position === 'next' ? 1 : -1
    let nextIndex = indexOfSelected + positionDirection
    let nextCreative_id = this.props.arrayOfCreativeIds[nextIndex]

    this.setState({buttonStatus: 'regular'})
    this.props.passedAddedToQUpdateSelected(nextCreative_id)
  }

  passedSetState = (state,value) => {
    this.setState({ [state]: value})
  }

render(){
  let creative_name = this.props.selectedCreative && this.props.selectedCreative.creative_name ? this.props.selectedCreative.creative_name : null
  let mm_creative_name = this.props.selectedCreative && this.props.selectedCreative.mm_creative_name ? this.props.selectedCreative.mm_creative_name : null

        return (
          <div className='row' style={{maring: '0px', padding: '10px 0px 60px'}}>

                <div className='col-12' style={{padding: '0px 20px'}}>
                  <h4>{creative_name} ({this.props.selectedCreative.creative_id})</h4>
                  {mm_creative_name &&  <label style={{color: 'grey'}}>Original Name: {mm_creative_name} </label> }
                </div>

                <div className='col-12 d-flex align-items-center' style={{padding: '2px 15px'}}>
                  <TextField
                      label="Campaign Name (optional)"
                      fullWidth
                      InputLabelProps={{ shrink: true}}
                      onChange={ (e) => this.setState({creativeCampaignName: e.target.value}) }
                      placeholder="GilletteJusticeLeague"
                      value={this.state.creativeCampaignName}
                  />
                </div>

              <ListParameters {...this.state} parameters={this.props.utilParameters.creatives.parameters} passedSetState={this.passedSetState}  />

              <div className='col-12 d-flex justify-content-end' style={{paddingTop:'20px'}}>
                {this.state.errorMessage}<br/>
              </div>
              <div className='col-12 d-flex justify-content-between' style={{paddingTop:'20px'}}>
                <div>
                <Button onClick={ () => this.nextCreative('back')} color='default' variant='outlined' style={{margin: '0px 1px'}}><i className="material-icons" >keyboard_arrow_left</i> </Button>
                <Button onClick={ () => this.nextCreative('next')} color='default' variant='outlined' style={{margin: '0px 1px'}}><i className="material-icons" >keyboard_arrow_right</i> </Button>
                </div>
                <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Add To Queue' savedLabel='Added!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='queue' regularButtonClick={this.addItemsToQueue} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
              </div>

        </div>
      )
    }
  }

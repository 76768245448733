import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import RaisedButton from 'material-ui/RaisedButton';
import Papa from 'papaparse';
import DatePicker from 'material-ui/DatePicker';

export default class BoxCreateDataFile extends Component {

    constructor(props) {
      super(props);
      this.state = { loading: false, errorMessage: null, startDate: null , endDate: null}
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

//use this to see if csv is empty
    isEmpty(obj) {
      for(var key in obj) {
          if(obj.hasOwnProperty(key))
              return false;
      }
      return true;
    }

      parseFile(e){

        console.log('parsefile')

        if (!this.state.startDate || !this.state.endDate){
          //this is weird, but we have to reset the form. Otherwise input won't recognize the next upload
          document.getElementById("input-form").reset();
          this.setState({errorMessage: <b style={{color:'red'}}>Please provide start and end date</b>, loading: false})
          return
        }

        this.setState({errorMessage: null, loading: true})
        var file = e.target.files[0]
        if (!file){
          return null
        }

        Papa.parse(file, {
           header: true,
           download: true,
           skipEmptyLines: true,
           //only do the first 10 lines. We preview/confirm on browser, and send heavy work to server
           preview: 10,
           error: this.papaError,
           complete: this.papaComplete
         });

       }


      papaComplete = (result, file) => {
          const data = result.data;
          console.log(data)

          if (data.length == 0){
            //this is weird, but we have to reset the form. Otherwise input won't recognize the next upload
            document.getElementById("input-form").reset();
            this.setState({errorMessage: <b style={{color:'red'}}>CSV does not contain any data</b>, loading: false})
            return
          }


          //object assing copies the object instead of using it as a reference
          let CSVColumns = { StrategyId: true, CreativeId: true, Impressions: true, Uniques: true, Clicks: true, CPM: true, TotalSpend: true}
          let deleteReportObject = Object.assign({}, CSVColumns)

          //loop through our report required csv columns
           for (let current in CSVColumns) {

             console.log('looping through: ' + current)
              //if our current require sql column exist in our data delete if from deleteReportObject
              //this will leave us with an object of required columns not in csv
              console.log(deleteReportObject[current])
              if (current in data[0]){
                delete deleteReportObject[current]
              }

           }

           if ( !this.isEmpty(deleteReportObject) ){
             let missingColumns = ''
              for (let key in deleteReportObject) {
                missingColumns+= key + ', '
              }
              //remove the last comma
              let slicedString = missingColumns.slice(0, -2)
              this.setState({errorMessage: <b style={{color:'red'}}>Error! CSV Missing Columns: {slicedString}</b>, loading: false})
           } else {

             //upload file
             this.uploadFile(file)
             //this.setState({status: 'readyForStorage', jsonData: data, file: file})
           }

        }

      papaError = (error) => {
            console.log('Papa ERROR')
            console.log(error)
            this.setState({errorMessage: 'Papaparse Error! Contact Support.'})
        }


      uploadFile = (file) => {

          let newUploadId = firebase.database().ref().push().key;
          var fbuserID = this.props.firebaseUID
          var filename =  file.name
          var storageref = firebase.storage().ref('externalReports/' + this.props.reportId + '/dataUploads/' + file.name )
          var task = storageref.put(file)
          //not sure how to do fat arrow functions, so need to set this to self
          var self = this

          task.on('state_changed',
          //progress
            (snapshot) => {

          }, //error
           (err) => {

          }, //complete
            (data)=> {


            storageref.getDownloadURL().then( url => {
              let today = Date.now()
              let inverseToday = today * -1
              var object = {
                fileurl: url,
                fileName: filename,
                status: 'uploadingSQL',
                message: 'Adding data to SQL table',
                id: newUploadId,
                dateCreated: today,
                startDate: this.state.startDate.getTime(),
                endDate:  this.state.endDate.getTime(),
                ssxId : this.props.ssxId,
                reportId: this.props.reportId
              }

              return firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/dataUploads/' + newUploadId).update(object)

            }).then (err=> {
                console.log('SUCCESS')
                this.setState({loading: false, errorMessage: null}, ()=> { this.props.passedSetState('boxDataFileOpen', false) })
              }).catch (err=> {
                this.setState({status: 'preview', errorMessage: err.message})
                console.log(err)
              })

          }

          )

        }


render(){


    const styles = {
      uploadButton: { verticalAlign: 'middle'},
      uploadInput: { cursor: 'pointer', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0, width: '100%', opacity: 0},
                  };


      return (
        <div className='row' style={{padding: '0px', margin: '0px'}}>
              <div className='col-12'>
                <label className='label'>Please provide start and end date for data. Reminder: data uploaded here will override existing data for this report.</label>
              </div>

              <div className='col-6 col-sm-6'>
                <DatePicker
                    dialogContainerStyle={{backgroundColor: ''}}
                    autoOk={true}
                    floatingLabelText="Start Date"
                    hintText="Start Date"
                    value={this.state.startDate}
                    onChange={ (hold, date)=> this.setState({startDate: date}) }
                 />
              </div>

              <div className='col-12 col-sm-6'>
                <DatePicker
                    autoOk={true}
                    floatingLabelText="End Date"
                    hintText="End Date"
                    value={this.state.endDate}
                    onChange={ (hold, date)=> this.setState({endDate: date}) }
                 />
              </div>

              <div className="col-12" style={{paddingBottom: '0px'}}>
                  {this.state.errorMessage}
              </div>

            {this.state.loading == true ?
              <div className="col-12 d-flex justify-content-end" style={{paddingBottom: '0px'}}>
                        <div style={{padding: '10px'}}>
                          <RaisedButton
                            style={{height: '100%'}}
                            children={ <div style={{padding: '3px'}} key={1}><LinearProgress /></div>}
                            />
                        </div>
               </div>
                  :
               <div className="col-12 d-flex justify-content-end" style={{paddingBottom: '0px'}}>
                        <div style={{padding: '10px'}}>
                          <RaisedButton
                             label="Upload"
                             labelPosition="after"
                             primary={true}
                             containerElement="label"
                             icon={<i className="material-icons" style={{color: 'white'}} >file_upload</i>}
                             >
                             <form id="input-form">
                              <input type="file" accept=".csv" style={styles.uploadInput}  onChange={(e) => this.parseFile(e)} />
                             </form>
                          </RaisedButton>
                        </div>
                </div>
              }

          </div>

      )

  }
}

import React, {Component} from 'react';
import CampaignItem from './campaignitem' ;
import BoxCreateCampaign from './create/boxcreatecampaign'
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import * as algoliasearch from 'algoliasearch';

var client = algoliasearch('FKWKD7663I', '75e62fadd51d3dd0357d74b338b4d4e3');
var index = client.initIndex('ssx_campaign_list');

export default class HomePage extends Component {

    constructor(props) {
      super(props);
      this.state = { campaigns: 'loading', createCampaignBoxOpen: false, clientFilter: 'All', hideExpiredCampaigns: true, filterNationalOnly: false, searchTerm: '' };
    }

    componentDidMount(){

        this.search('')

          }
    /*
      filterCampaigns = () => {
        let query

        let filter = this.state.clientFilter

        //.equalTo(false)

        if (this.state.filterNationalOnly){
          this.setState({campaigns: 'loading'})
          query = firebase.database().ref('/campaigns/').orderByChild("details/runbySSX").equalTo(false)
        } else if (filter === 'none' || filter === 'All'){
          this.setState({campaigns: 'loading'})
          query = firebase.database().ref('/campaigns/').orderByChild("details/runbySSX").equalTo(true)
        } else {
          this.setState({campaigns: 'loading'})
          query = firebase.database().ref('/campaigns/').orderByChild("details/client").equalTo(filter)
        }

        query.once('value').then( (snap)=> {

            let tempCampaigns = []
            snap.forEach( current => {
              var today = Date.now();

              let campaign = current.val()
              //if hide epired campaigns is checked just remove expired campaigns from the client side
              if (this.state.hideExpiredCampaigns && campaign.details.endDate < today ){
                //console.log('campaign has expired!')
              } else {
                tempCampaigns.push(current.val())
              }
            })
                   if (tempCampaigns.length > 0) {

                        this.setState({ campaigns: tempCampaigns.reverse()})
                    } else {
                      this.setState({ campaigns: []})
                    }
                }).catch( (error)=> {
                    console.log("Error getting campaigns:", error);
                });

      }
      */

      sortCampaigns(parameter){
            let tempArray = this.state.campaigns.sort( (a, b)=> {
                                return a.details[parameter] - b.details[parameter];
                            });
            console.log(tempArray)
            this.setState({campaigns: tempArray})
      }

      search = (searchTerm) =>{

      console.log('CALLING SEARCH: ' +  searchTerm)
      let filter = 'details.runbySSX:' + !this.state.filterNationalOnly

        index.search({ query: searchTerm, filters: filter}, (err, content)=> {
              if (err) throw err;
              console.log(content)
              this.filterCampaigns(content.hits)
            }
          );
      }

      filterCampaigns = (campaigns) => {

        let tempCampaigns = []
        var today = Date.now();
        campaigns.forEach(current => {

          if (this.state.hideExpiredCampaigns && current.details.endDate < today ){
            //console.log('campaign has expired!')
          } else {
            tempCampaigns.push(current)
          }
        })

        this.setState({campaigns: tempCampaigns})

      }

      //this is called, and waits before actually asking algolia to query the data
      typedQuery = (searchTerm) =>{
        this.setState({searchTerm: searchTerm})

        clearTimeout(this.state.timeout);
        // Make a new timeout set to go off in 1000ms
        let tempTimeout = setTimeout( ()=> {
             this.search(searchTerm)
        }, 700);

        this.setState({ timeout: tempTimeout, loading: true})


      }

      handleDialogClose = () => this.setState({createCampaignBoxOpen: false}) ;


render(){

 if (this.state.campaigns === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  } else {

    var campaignItemsList = this.state.campaigns.map((currentCampaign, index) => {
          return <CampaignItem key={index} campaign={ currentCampaign }/>
          });

    return(
        <div className='' style={{margin: '0px', padding:'5px 15px'}}>

            <div className='row' style={{margin: '0px', padding:'0px', minHeight: '56px'}} >

                <div className='col-6 col-lg-2 d-flex align-items-center' style={{paddingTop: '5px'}}>
                  <b style={{fontSize: '20px'}}>Campaigns</b>
                  <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({createCampaignBoxOpen: true})}>add_box</i> <br />
                </div>

                <div className='col-6 col-lg-4 d-flex align-items-center' style={{paddingTop: '5px'}} >
                    <TextField  placeholder='Search for campaigns'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                            <i className="material-icons" >search</i>
                         </InputAdornment>
                                     ),
                                }}
                     fullWidth={true} value={this.state.searchTerm}
                    onChange={ (e)=> this.typedQuery(e.target.value)} />
                </div>

                <div className='col-6 col-lg-3 d-flex align-items-center' style={{paddingTop: '15px'}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.hideExpiredCampaigns}
                        onChange={ (e)=> this.setState({hideExpiredCampaigns: !this.state.hideExpiredCampaigns},  ()=> this.search(this.state.searchTerm) )}
                      />
                    }
                    label="Hide Expired"
                  />
                </div>
                <div className='col-6 col-lg-3 d-flex align-items-center' style={{paddingTop: '15px'}}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.filterNationalOnly}
                        onChange={ (e)=> this.setState({filterNationalOnly: !this.state.filterNationalOnly},  ()=> this.search(this.state.searchTerm) )}
                      />
                    }
                    label="National Only"
                  />
                </div>
            </div>

            <Dialog onClose={this.handleDialogClose} open={ this.state.createCampaignBoxOpen === true  } >
              <DialogTitle >Create New Campaign</DialogTitle>
              <div>
                <BoxCreateCampaign {...this.props} />
              </div>
            </Dialog>

          <div className='col-12 row no-gutters' style={{margin: '0px', border: '2px solid', borderColor: 'grey', borderRadius:'1px', padding: '10px'}} >
              <div className='col-1'>
                <b>Status</b>
              </div>
              <div className='col-7'>
                <b>Campaign Name</b>
              </div>
              <div className='col-2 d-flex align-items-center'>
                <b>Start Date</b>
                <i style={{paddingLeft: '4px', cursor: 'pointer'}} onClick={ () => this.sortCampaigns('startDate') } className="material-icons" >sort</i>
              </div>
              <div className='col-2 d-flex align-items-center'>
                <b>End Date</b>
                <i style={{paddingLeft: '4px', cursor: 'pointer'}} onClick={ () => this.sortCampaigns('endDate') } className="material-icons" >sort</i>
              </div>
          </div>

          <div className='col-12' style={{margin: '0px', padding:'0px'}} >
            {campaignItemsList}
          </div>

        </div>
      );
    }
  }
}

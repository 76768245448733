import React, {Component} from 'react';
import ListParameters from '../../../../util/labelparameters/listparameters'
import LoadingButton from '../../../../util/loadingbutton'
import * as firebase from 'firebase';


export default class BoxCreativeDetails extends Component {

    constructor(props) {
      super(props);
      let details = this.props.currentCreative && this.props.currentCreative.details ? this.props.currentCreative.details : null
      let size = details && details.size ? details.size : ''
      let creativeImage = details && details.creativeImage ? details.creativeImage : ''
      let cta = details && details.cta ? details.cta : ''
      let mediaFormat = details && details.mediaFormat ? details.mediaFormat : ''
      let landingPage = details && details.landingPage ? details.landingPage : ''
      let customOne = details && details.customOne ? details.customOne : ''
      let customTwo = details && details.customTwo ? details.customTwo : ''

      this.state = { buttonStatus: 'regular', size: size, creativeImage: creativeImage, cta: cta, mediaFormat: mediaFormat, landingPage: landingPage, customOne: customOne , customTwo: customTwo};
    }

    passedSetState = (state,value) => {
      this.setState({ [state]: value})
    }

    submit = (e) =>{
      e.preventDefault()
      this.setState({buttonStatus: 'loading'})

            let reportId = this.props.reportId
            let creativeId = this.props.creativeId
            let ssxId = this.props.campaignDetails.ssx_id
            var updates = {}
            let path = '/campaigns/' + ssxId + '/externalReports/' + reportId + '/creatives/' + creativeId + '/details'

            updates[path + '/creativeId'] = creativeId
            updates[path + '/size'] = this.state.size
            updates[path + '/creativeImage'] = this.state.creativeImage
            updates[path + '/cta'] = this.state.cta
            updates[path + '/mediaFormat'] = this.state.mediaFormat
            updates[path + '/landingPage'] = this.state.landingPage
            updates[path + '/customOne'] = this.state.customOne
            updates[path + '/customTwo'] = this.state.customTwo

          firebase.database().ref().update(updates).then( ()=>{
            this.setState({buttonStatus: 'saved', errorMessage: null} , ()=> this.props.passedSetState('boxCreativeDetails', false) )
          }).catch( (err)=> {
            this.setState({buttonStatus: 'regular', errorMessage: err.message})
            console.log(err)
          })

      }

render(){

    return(

      <div className='row' style={{padding: '20px'}}>

                  <ListParameters {...this.state} parameters={this.props.parameters} passedSetState={this.passedSetState}   />

                  <div className="col-12">
                  {this.state.errorMessage}
                  </div>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '10px 0px'}}>
                    <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save' savedLabel='Uploaded!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='save' regularButtonClick={this.submit} savedButtonClick={ ()=> this.setState({ buttonStatus: 'regular'}) } />
                  </div>

      </div>

      )
    }
}

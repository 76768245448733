import React, {Component} from 'react';
import LoadingButton from '../../../util/loadingbutton';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import PhaseItem from './phaseItem'
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
var moment = require('moment');
moment().format();

export default class BudgetPlanner extends Component {

    constructor(props) {
      super(props);
      this.state = { budgetPlanner: 'loading', totalPartnerBudget: 10000, boxTactic: false, errorMessages: null }
    }

    componentDidMount(){

      let budgetPlanner
      let budgetName
      let totalPartnerBudget

      firebase.database().ref('/campaigns/' + this.props.ssxId + '/budgetplanners/' + this.props.planId  ).once('value').then( snap => {

        let data = snap.val()
        budgetPlanner = data && data.budgetPlanner ? data.budgetPlanner : {}
        budgetName = data && data.name ? data.name : 'Budget Planner'
        totalPartnerBudget = data && data.totalPartnerBudget ? data.totalPartnerBudget : 0
        return firebase.database().ref('/campaigns/' + this.props.ssxId + '/details' ).once('value')

      }).then( (campaignDetailsSnap)=> {

        let campaignDetails = campaignDetailsSnap.val()
        let campaignName = campaignDetails && campaignDetails.campaignName ? campaignDetails.campaignName : 'Campaign Name'

        this.setState({ budgetPlanner, campaignName, budgetName, totalPartnerBudget,  errorMessage: null })

      }).catch( error => {
        console.log(error)
        this.setState({ errorMessage: error.message })
      });

    }

    saveBudgetPlanner = () => {
      let {budgetPlanner, totalPartnerBudget} = this.state
      let updates = {}
      let ref = 'campaigns/' + this.props.ssxId + '/budgetplanners/' + this.props.planId

      updates[ref + '/budgetPlanner'] = budgetPlanner
      updates[ref + '/totalPartnerBudget'] = totalPartnerBudget
      updates[ref + '/budgetPlanner'] = budgetPlanner

      firebase.database().ref().update(updates).then( ()=>{
        this.setState({buttonStatus: 'saved', errorMessage: null})
      }).catch( (err)=> {
        this.setState({buttonStatus: 'regular', errorMessage: err.message})
        console.log(err)
      })

    }

    handlePhaseChange = (phaseId, name, value) => {

      let tempBudgetPlanner = this.state.budgetPlanner
      tempBudgetPlanner[phaseId][name] = value

      if (name === 'phaseTotalBudget'){
        //if user manually updates phaseTotalBudget, then remove %
        tempBudgetPlanner[phaseId].phaseTotalBudgetPerc = null
      }

      this.setState({ budgetPlanner: tempBudgetPlanner });

    };

    handleStrategyChange = (phaseId, strategyId, name, value) => {

      let {budgetPlanner} = this.state
      budgetPlanner[phaseId].phaseStrats[strategyId][name] = value

      if (name === 'stratTotalBudget'){
        //if user manually updates phaseTotalBudget, then remove %
        budgetPlanner[phaseId].phaseStrats[strategyId].stratTotalBudgetPerc = ""
      }

      this.setState({ budgetPlanner });

    };

    handlePhaseDateChange = (phaseId, name, date) => {

        //conver data to miliseconds
        let miliDate = date.getTime()

        let {budgetPlanner} = this.state
        budgetPlanner[phaseId][name] = miliDate

        let phaseDurationDays = this.dateGetDiffInDays(budgetPlanner, phaseId )
        budgetPlanner[phaseId].numOfDays = phaseDurationDays

        //and make sure daily budget is null, which will force user to re-calc
        budgetPlanner[phaseId].phaseDailyBudget = ''
        budgetPlanner[phaseId].phaseTotalBudget = ''


        //remove budgets from stratigies too
        let phaseStrats = budgetPlanner[phaseId].phaseStrats ? budgetPlanner[phaseId].phaseStrats : {}
        let phaseStratsKeys = Object.keys(phaseStrats)
        phaseStratsKeys.forEach( (key, index) => {
          budgetPlanner[phaseId].phaseStrats[key].stratDailyBudget = ''
          budgetPlanner[phaseId].phaseStrats[key].stratTotalBudget = ''
        })

        this.setState({ budgetPlanner });

    };


    calcPhaseBudgets = () =>{

      let {budgetPlanner, totalPartnerBudget} = this.state
      let errorMessages = this.state.errorMessages ? this.state.errorMessages  : []

      let totalPercent =  this.calcTotalPercentageOfPhases(budgetPlanner)
      if (totalPercent !== 100 && totalPercent !== "100"){
        let tempErrorMessages = errorMessages
        tempErrorMessages.push('Phase % of Total Does Not Equal 100')
        this.setState({errorMessages: tempErrorMessages})
        return
      }

      //% is 100, now calculate each phase total budget

      let budgetPlannerKeys = Object.keys(budgetPlanner)
      budgetPlannerKeys.forEach( (phaseId, index) => {

        //for each phase, compute new phaseTotalBudget
        let currentPhase = budgetPlanner[phaseId]
        let phaseTotalBudgetPerc = currentPhase.phaseTotalBudgetPerc ? currentPhase.phaseTotalBudgetPerc  : 0
        phaseTotalBudgetPerc =  parseInt(phaseTotalBudgetPerc, 10 )  / 100

        let phaseTotalBudget = totalPartnerBudget * phaseTotalBudgetPerc
        let phaseDurationDays = this.dateGetDiffInDays(budgetPlanner, phaseId )

        //if phaseDurationDays is 0, just return total budget
        let phaseDailyBudget = phaseDurationDays === 0 ? phaseTotalBudget : phaseTotalBudget / phaseDurationDays

        phaseTotalBudget = Math.round(phaseTotalBudget * 100) / 100
        phaseDailyBudget = Math.round(phaseDailyBudget * 100) / 100

        budgetPlanner[phaseId].phaseTotalBudget = phaseTotalBudget
        budgetPlanner[phaseId].phaseDailyBudget = phaseDailyBudget

      })

      this.setState({budgetPlanner, errorMessages: null})

    }

    calcStratBudgets = (phaseId) => {

      let {budgetPlanner} = this.state
      let errorMessages = this.state.errorMessages ? this.state.errorMessages  : []

      let totalPercent =  this.calcTotalPercentageOfStrats(budgetPlanner, phaseId)
      if (totalPercent !== 100 && totalPercent !== "100"){
        let tempErrorMessages = errorMessages
        tempErrorMessages.push('Strategy % of Total Does Not Equal 100')
        this.setState({errorMessages: tempErrorMessages})
        return
      }

      //% is 100, now calculate each phase total budget
      let phaseStrats = budgetPlanner[phaseId].phaseStrats
      let phaseStratsKeys = Object.keys(phaseStrats)

      phaseStratsKeys.forEach( (key, index) => {

        //for each strat, compute new stratTotalBudget
        let currentStrat = phaseStrats[key]

        let strategyId = currentStrat.strategyId
        let stratTotalBudgetPerc = currentStrat.stratTotalBudgetPerc ? currentStrat.stratTotalBudgetPerc  : 0
        stratTotalBudgetPerc =  parseInt(stratTotalBudgetPerc, 10 )  / 100

        let totalPhaseBudget = budgetPlanner[phaseId].phaseTotalBudget ? budgetPlanner[phaseId].phaseTotalBudget  : 0
        if (totalPhaseBudget === 0){
          let tempErrorMessages = errorMessages
          tempErrorMessages.push('Phase Does Not Have Total Budget')
          this.setState({errorMessages: tempErrorMessages})
          return
        }
        let stratTotalBudget = totalPhaseBudget * stratTotalBudgetPerc
        let phaseDurationDays = this.dateGetDiffInDays(budgetPlanner, phaseId )
        //if phaseDurationDays is 0, just return total budget
        let stratDailyBudget = phaseDurationDays === 0 ? stratTotalBudget : stratTotalBudget / phaseDurationDays

        stratTotalBudget = Math.round(stratTotalBudget * 100) / 100
        stratDailyBudget = Math.round(stratDailyBudget * 100) / 100

        budgetPlanner[phaseId].phaseStrats[strategyId].stratTotalBudget = stratTotalBudget
        budgetPlanner[phaseId].phaseStrats[strategyId].stratDailyBudget = stratDailyBudget

      })

      this.setState({budgetPlanner, errorMessages: null})

    }

    calcTotalPercentageOfPhases = (budgetPlanner) =>{

      let budgetPlannerKeys = Object.keys(budgetPlanner)
      let totalPercent = 0

      budgetPlannerKeys.forEach( (key, index) => {
        let currentPhase = budgetPlanner[key]
        let phaseTotalBudgetPerc = currentPhase.phaseTotalBudgetPerc ? parseInt(currentPhase.phaseTotalBudgetPerc, 10)  : 0
        totalPercent += phaseTotalBudgetPerc
      })

      return totalPercent
    }

    calcTotalPercentageOfStrats = (budgetPlanner, phaseId) =>{

      let phaseStrats = budgetPlanner[phaseId].phaseStrats
      let phaseStratsKeys = Object.keys(phaseStrats)
      let totalPercent = 0

      phaseStratsKeys.forEach( (key, index) => {
        let currentStrat = phaseStrats[key]
        let stratTotalBudgetPerc = currentStrat.stratTotalBudgetPerc ? parseInt(currentStrat.stratTotalBudgetPerc, 10)  : 0
        totalPercent += stratTotalBudgetPerc
      })

      return totalPercent
    }


    dateGetDiffInDays = (budgetPlanner, phaseId) => {

      let miliStartDate = budgetPlanner[phaseId].startDate
      let miliEndDate = budgetPlanner[phaseId].endDate

      var momentStartDate = moment( miliStartDate )
      var momentEndDate = moment( miliEndDate )
      let phaseDurationDays = momentEndDate.diff(momentStartDate, 'days', false);

      //campaignDurationDays will return a decimal number. have to round up
      //campaignDurationDays = Math.ceil(campaignDurationDays)
      //have to add a day to include the end date
      phaseDurationDays++
      //also need to calc days numOfDays

      return phaseDurationDays
    }

    addPhase = () => {
      let {budgetPlanner} = this.state
      let phaseId = firebase.database().ref().push().key;
      budgetPlanner[phaseId] =  { phaseId: phaseId, name: 'New Phase', phaseTotalBudget: 0, dailyPhaseBudget: 0, phaseTotalBudgetPerc: 0, startDate: new Date().getTime(), endDate: new Date().getTime(), numOfDays: '', phaseStrats: {} }
      this.setState({ budgetPlanner })
    }

    addStrategy = (phaseId) => {

      let {budgetPlanner} = this.state
      let strategyId = firebase.database().ref().push().key;
      budgetPlanner[phaseId].phaseStrats[strategyId] = { strategyId: strategyId, name: 'New Strategy', stratTotalBudget: 0, stratPhaseBudget: 0, stratTotalBudgetPerc: 0, stratDailyBudget: 0,  phaseStrats: {} }
      this.setState({ budgetPlanner })
    }

    deleteRow = ( phaseId, strategyId ) => {

      let {budgetPlanner} = this.state

      if (strategyId){
        delete budgetPlanner[phaseId].phaseStrats[strategyId]
      } else {
        delete budgetPlanner[phaseId]
      }

      this.setState({ budgetPlanner })
    }


render(){


  let {budgetPlanner, totalPartnerBudget, errorMessages} = this.state

  if (this.state.budgetPlanner ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress />
        {this.state.errorMessage}
      </div>
    );
  }  else  {

      let budgetPlannerKeys = Object.keys(budgetPlanner)

      let phases = budgetPlannerKeys.map( (key, index) => {
        let currentPhase = budgetPlanner[key]
        let phaseNumber = index + 1
        return (
          <PhaseItem key={index} phaseNumber={phaseNumber} currentPhase={currentPhase} {...this.state} handlePhaseChange={this.handlePhaseChange} handlePhaseDateChange={this.handlePhaseDateChange} handleStrategyChange={this.handleStrategyChange} addStrategy={this.addStrategy} calcStratBudgets={this.calcStratBudgets} deleteRow={this.deleteRow} />
        )
      })

      let columnHeaderStyle = { flex: 1, fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold'}

      let listErrorMessages
      if (errorMessages){
         listErrorMessages = errorMessages.map( (currentMessage, index) => {
          return <li key={index}>{currentMessage}</li>
        })
      }

      return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>

              <div className='col-12'>
                <h3>{this.state.campaignName}</h3>
              </div>

              <div className='col-6' style={{padding: '5px'}}>
                  <TextField label="Total Partner Budget" value={totalPartnerBudget} onChange={ (e)=> this.setState({ totalPartnerBudget: e.target.value}) } fullWidth type="number"
                  InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                  />
              </div>

              <div className='col-6 justify-content-end' style={{padding: '5px'}}>
                <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save' savedLabel='Saved!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='save' regularButtonClick={  this.saveBudgetPlanner } savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
              </div>

              <div className='col-12' style={{display: 'flex', border: '2px #ddd solid', padding: '5px', margin: '0px', height: '48px'}} >

                    <div  style={{ width: '25px', fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold'}} />

                    <div  style={columnHeaderStyle}>
                      Name
                    </div>
                    <div  style={columnHeaderStyle}>
                      Start On
                    </div>
                    <div style={columnHeaderStyle}>
                      End On
                    </div>

                    <div style={columnHeaderStyle}>
                      <span># of days</span>
                    </div>

                    <div  style={columnHeaderStyle}>
                      Daily Budget
                    </div>

                    <div style={columnHeaderStyle}>
                      % of Total
                    </div>

                    <div style={columnHeaderStyle}>
                      Phase Budget
                    </div>

                    <div  style={{ width: '25px', fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold'}} />

              </div>

              <div className='col-12 row' style={{padding: '0px', margin: '0px'}}>
                {phases}
              </div>

              {listErrorMessages &&
              <div className='col-12 errorMessage' >
                {listErrorMessages}
              </div>
              }

              <div className="col-12 justify-content-center" style={{paddingTop: '10px'}}>
                <Button onClick={ this.addPhase} color='default' variant='text' style={{ marginRight: '5px'}}>
                  <i className="material-icons" >add</i>
                  Add Phase
                </Button>

                <Button onClick={ this.calcPhaseBudgets} color='default' variant='outlined' style={{ marginLeft: '5px'}}>
                  % To Total
                </Button>
              </div>


        </div>
      )
    }
  }
}

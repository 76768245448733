import React, {Component} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default class ListStrategies extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignData: 'loading' }
    }


render(){

  let listItems = []
  let mm_strategies = this.props.mm_strategies
      if (this.props.mm_strategies){

        for (let key in mm_strategies) {

            let imageIcon
            let imageColor
            if (mm_strategies[key].saved_in_tb === true){
              imageIcon = 'check'
              imageColor = 'green'
            } else if (mm_strategies[key].saved_in_tb === 'queued'){
              imageIcon = 'queue'
              imageColor = '#FFD923'
            } else {
              imageIcon = 'error'
              imageColor = 'red'
            }

            //set backgroundColor grey to selected item
            let backgroundColor = this.props.selectedStrategy && this.props.selectedStrategy.strategy_id === mm_strategies[key].strategy_id ? '#ddd' : ''

            listItems.push(
                <ListItem key={key} button onClick={ ()=> this.props.passedSetState('selectedStrategy', mm_strategies[key])}
                  style={{border: '1px #ddd solid', borderRadius: '3px', backgroundColor: backgroundColor, overflow: 'hidden'}}>
                  <ListItemIcon><i style={{color: imageColor, cursor: 'pointer'}}className="material-icons d-flex align-items-center" >{imageIcon}</i></ListItemIcon>
                  <ListItemText primary={mm_strategies[key].strategy_name} />
                </ListItem>
                          )
          }


        } else {
            listItems.push( <ListItem style={{border: '1px #ddd solid', borderRadius: '3px'}}>
                              <ListItemIcon>
                                <i className="material-icons d-flex align-items-center" >access_time</i>
                              </ListItemIcon>
                              <ListItemText  primary='No creatives. Check back a day after you add the media math campaign id.' />
                            </ListItem>
                )
              }

      return (
        <div>
            <List>
              {listItems}
            </List>
        </div>
      )
  }
}

import React, {Component} from 'react';
import ListParameters from '../../../../util/labelparameters/listparameters'
import * as firebase from 'firebase';
import LoadingButton from '../../../../util/loadingbutton';

export default class ExternalDetails extends Component {

    constructor(props) {
      super(props);
      let details = this.props.currentStrategy && this.props.currentStrategy.details ? this.props.currentStrategy.details : null
      let partner = details && details.partner ? details.partner : ''
      let device = details && details.partner ? details.device : ''
      let mediaType = details && details.mediaType ? details.mediaType : ''
      let targetingChannel = details && details.targetingChannel ? details.targetingChannel : ''
      let targetSegment = details && details.targetSegment ? details.targetSegment : ''

      this.state = { partner: partner, buttonStatus: 'regular', loading: false,
      mediaType: mediaType, targetingChannel: targetingChannel, device: device, targetSegment: targetSegment };
    }

    passedSetState = (state,value) => {
      this.setState({ [state]: value})
    }


    submit(e){
      e.preventDefault()

      if (  this.state.partner === '' || this.state.targetSegment === '' || this.state.mediaType === '' || this.state.targetingChannel === '' || this.state.device === ''  ){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide all inputs</b>})
      }  else  {

          this.setState({errorMessage: null, buttonStatus: 'loading'})

          let reportID = this.props.reportId
          let strategyId = this.props.strategyId
          let ssxID = this.props.campaignDetails.ssx_id
          var updates = {}

          updates['/campaigns/' + ssxID + '/externalReports/' + reportID + '/strategies/' + strategyId  + '/details/strategyId'] = strategyId
          updates['/campaigns/' + ssxID + '/externalReports/' + reportID + '/strategies/' + strategyId  + '/details/partner'] = this.state.partner;
          updates['/campaigns/' + ssxID + '/externalReports/' + reportID + '/strategies/' + strategyId  + '/details/targetSegment'] = this.state.targetSegment;
          updates['/campaigns/' + ssxID + '/externalReports/' + reportID + '/strategies/' + strategyId  + '/details/targetingChannel'] = this.state.targetingChannel;
          updates['/campaigns/' + ssxID + '/externalReports/' + reportID + '/strategies/' + strategyId  + '/details/mediaType'] = this.state.mediaType;
          updates['/campaigns/' + ssxID + '/externalReports/' + reportID + '/strategies/' + strategyId  + '/details/device'] = this.state.device;


          firebase.database().ref().update(updates).then( ()=>{
              this.setState({buttonStatus: 'saved', errorMessage: null}, ()=> this.props.passedSetState('boxStrategyDetails', false))
          }).catch( (err)=> {
              this.setState({buttonStatus: 'regular', errorMessage: err.message})
              console.log(err)
          })

      }


    }


render(){


    return(

      <div className='col-12 row' style={{padding: '10px 0px'}}>
                  <div className='col-12'>
                    <b>Details:</b>
                  </div>

                  <ListParameters {...this.state} parameters={this.props.parameters} passedSetState={this.passedSetState}   />

                  <div className='col-12 d-flex' style={{padding: '20px'}}>
                    <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save Strategy' savedLabel='Saved!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='save' regularButtonClick={event => this.submit(event)} savedButtonClick={ (event)=> this.setState({buttonStatus: 'regular'})} />
                  </div>

                  <div className="col-12">
                  {this.state.errorMessage}
                  </div>
          </div>

      )
    }
}

/*
<div className='col-6 col-lg-4 d-flex align-items-center'>
  <DatePicker
      dialogContainerStyle={{backgroundColor: ''}}
      autoOk={true}
      floatingLabelText="Start Date"
      hintText="Start Date"
      value={this.state.startDate}
      onChange={ (hold, date)=> this.setState({startDate: date}) }
   />
</div>

<div className='col-6 col-lg-4 d-flex align-items-center'>
  <DatePicker
      autoOk={true}
      floatingLabelText="End Date"
      hintText="End Date"
      value={this.state.endDate}
      onChange={ (hold, date)=> this.setState({endDate: date}) }
   />
</div>
*/

import React, {Component} from 'react';
import * as firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListParameters from './listparameters'
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import BoxCreateParameterOption from './boxCreateParameterOption'

export default class Parameters extends Component {

    constructor(props) {
      super(props);
      this.state = { parameters: 'loading', tabOpen: 'strategies', createParameterOption: false}
    }


    componentDidMount(){

      firebase.database().ref('/util/parameters').on('value', snap => {

        console.log('new param dat')

        if ( !snap.val()){
          throw new Error('Error, no parameter data.')
        }
        this.setState({parameters: snap.val() })


      })

    }

    openAddBox = ( value1, value2, value3 ) => {
      this.setState({ createParameterOption: value1, 'selectedParameter': value2, 'selectedParameterId': value3 })
    }

    deleteOption = (selectedParameterId, index)=> {
      firebase.database().ref('/util/parameters/' + this.state.tabOpen + '/parameters/' + selectedParameterId + '/options/' + index ).set(null)
    }

    handleDialogClose = () => this.setState({createParameterOption: false}) ;
    passedSetState = (state, value) => this.setState({[state]: value})

render(){

  if (this.state.parameters === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      return (
      <div className='row no-gutters' style={{margin: '0px', padding: '10px'}}>

        <div className='col-12 row no-gutters' style={{ padding: '10px 0px'}}>
            <div className='col-12'>
              <h3>Parameters</h3>
            </div>

            <Tabs className='col-12' fullWidth value={this.state.tabOpen} onChange={ (e, value)=> this.setState({tabOpen : value})} style={{border: '1px #ddd solid'}}>
              <Tab value="strategies" label="strategies" />
              <Tab value="creatives" label="creatives" />
            </Tabs>

            <ListParameters {...this.state} deleteOption={this.deleteOption} openAddBox={this.openAddBox} />

        </div>

        <Dialog onClose={this.handleDialogClose} open={ this.state.createParameterOption === true  } >
          <DialogTitle >Add New {this.state.selectedParameter} Option</DialogTitle>
          <div>
            <BoxCreateParameterOption {...this.props} {...this.state} passedSetState={this.passedSetState} />
          </div>
        </Dialog>


      </div>
      )
    }
  }
}

import React, {Component} from 'react';
import Listcreatives from './listcreatives'
import UpdateCreative from './updatecreative'
import QueuedCreatives from './queuedcreatives'

export default class Campaigncreatives extends Component {

    constructor(props) {
      super(props);
      this.state = { selectedCreative: null, queuedItems: {}, arrayOfCreativeIds: []  }
    }

    componentDidMount(){
      let array = []
      let mm_creatives = this.props.mm_creatives
      for (let key in mm_creatives) {
          array.push(mm_creatives[key].creative_id)
      }

      this.setState({arrayOfCreativeIds : array })
    }



    passedSetState = (state, value) => {
      this.setState({[state]: value})
    }

    //this is used by the back and next button to quickly get from Creative to the next
    passedAddedToQUpdateSelected = (nextCreative_id) => {

      let mm_creatives = this.props.mm_creatives
      let nextselectedCreative = mm_creatives[nextCreative_id]
      this.setState({selectedCreative: nextselectedCreative  })

    }

render(){

let selectedCreative = this.state.selectedCreative
      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

              <div className='col-4'>
              <h4 className='d-flex justify-content-center'>Creatives</h4>
                <Listcreatives mm_creatives={this.props.mm_creatives} passedSetState={this.passedSetState} selectedCreative={selectedCreative}/>
              </div>


              <div className='col-8'>
                <div className='row' style={{maring: '0px', padding: '0px'}}>

                    <div className='col-12'>
                      <QueuedCreatives queuedItems={this.state.queuedItems} passedSetState={this.passedSetState} mm_creatives_queue={this.props.mm_creatives_queue} ssx_id={this.props.ssx_id}/>
                    </div>

                    <div className='col-12 ' style={{padding: '10px'}}>
                      {selectedCreative ?
                        <UpdateCreative key={this.state.selectedCreative.creative_id}
                        {...this.state} {...this.props}
                        passedSetState={this.passedSetState}
                        passedAddedToQUpdateSelected={this.passedAddedToQUpdateSelected}/>
                        :
                      null
                      }
                    </div>
                </div>

              </div>
        </div>
      )
    }
  }

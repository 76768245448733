import React, {Component} from 'react';

export default class UploadFileTable extends Component {

    constructor(props) {
      super(props);
      this.state = {  test: '',  }
    }




render(){

  let csvcolumns = this.props.reportTypeCSVColumns
  let header = []

       //loop through and create header
       for (let current in csvcolumns) {
            header.push(<th key={current} style={{padding: '5px'}}>{current}</th>)
        }

  let rows = []

    this.props.jsonData.forEach( (current, index) => {

      //loop through all the columns and create row
      let row = []
      for (let key in csvcolumns) {
        row.push(<td key={key} style={{padding: '5px'}}>{current[key]}</td>)
       }
      rows.push( <tr key={index} style={{padding: '5px'}}>{row}</tr>)

    })

    return (

      <table style={{display: 'block', overflowX: 'auto', whiteSpace: 'nowrap'}}>
        <tr>
          {header}
        </tr>
        {rows}
      </table>

      )
  }
}

import React, { Component } from 'react';
import  {Link} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';


export default class NavbarDrawer extends Component {

  constructor(props) {
    super(props);
    this.state = { open : false };
  }

  render() {
    return (
      <Drawer anchor="left" width={200} open={this.props.open} onClose={ () => this.props.passedSetState(false)} >

        <div className="d-flex align-items-center" style={{padding: '0px 10px', height: '64px', backgroundColor: '#ee2d24'}}>
        <i style={{color:'white', fontSize:'34px', cursor: 'pointer' }} className="material-icons" onClick={ () => this.props.passedSetState(false)}>keyboard_arrow_left</i>
        </div>

        <Link to='/campaigns'>
        <MenuItem onClick={this.props.handleClose}>
          Campaigns
        </MenuItem>
        </Link>

        <Link to='/partners'>
        <MenuItem onClick={this.props.handleClose}>
          Partners
        </MenuItem>
        </Link>

        <Link to='/baform'>
        <MenuItem onClick={this.props.handleClose} >
          Ba Form
        </MenuItem>
        </Link>

        <Link to='/listuploads'>
        <MenuItem onClick={this.props.handleClose}>
          Upload Files To SQL
        </MenuItem>
        </Link>

        <Link to='https://reviewer-ff8f7.firebaseapp.com/' target='_blank'>
          <MenuItem onClick={this.props.handleClose}>
            Reviewer
          </MenuItem>
        </Link>


      </Drawer>
    );
  }
}

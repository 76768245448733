import React, {Component} from 'react';
import  {Link} from 'react-router-dom';
import Button from '@material-ui/core/Button';

export default class CampaignItem extends Component {

    constructor(props) {
      super(props);

      this.state = { buttonList: [] };
    }

    componentDidMount(){

      let totalStrategies = 0
      let strategiesSavedInTB = 0
      let strategiesNeededLabeling = 0
      let strategyIconColor
      let mm_strategies = this.props.campaign.mm_strategies
      for (let current in mm_strategies) {

            totalStrategies++
            if (mm_strategies[current].saved_in_tb === true){
              strategiesSavedInTB ++
                }
          }

      let totalCreatives = 0
      let creativesSavedInTB = 0
      let creativesNeededLabeling = 0
      let mm_creatives = this.props.campaign.mm_creatives
      for (let current in mm_creatives) {

            totalCreatives++
            if (mm_creatives[current].saved_in_tb === true){
              creativesSavedInTB ++
                }
          }

      creativesNeededLabeling = totalCreatives - creativesSavedInTB
      strategiesNeededLabeling = totalStrategies - strategiesSavedInTB
      if (totalStrategies === strategiesSavedInTB && totalCreatives === creativesSavedInTB ){
          strategyIconColor = 'green'
      } else {
          strategyIconColor = 'red'
      }

      let campaignDetailsIconColor
      let campaignDetailsText
      let campaignDetails = this.props.campaign.details
      let wmx_id = campaignDetails.wmx_id
      let mm_campaign_id = campaignDetails.mm_campaign_id
      let wmxEnabled = campaignDetails.wmxEnabled === true ? true : false
      let mmEnabled = campaignDetails.mmEnabled === true ? true : false

      if (!mm_campaign_id && mmEnabled && !wmx_id && wmxEnabled){
        campaignDetailsIconColor = 'red'
        campaignDetailsText = 'Campaign needs a WMX and Media Math ID'
      } else if (!mm_campaign_id && mmEnabled ){
        campaignDetailsIconColor = 'red'
        campaignDetailsText = 'Campaign needs a Media Math ID'
      } else if (!wmx_id && wmxEnabled ){
        campaignDetailsIconColor = 'red'
        campaignDetailsText = 'Campaign needs a WMX ID'
      } else {
        //do nothing
        campaignDetailsIconColor = 'green'
        campaignDetailsText = 'Campaign is ready to rock and roll!'
      }

      let optimizations =  this.props.campaign.optimizations
      let totalOptimizations = 0
      for (let current in optimizations) {
            totalOptimizations++
          }

      let totalOptimizationsColor = totalOptimizations > 0 ? 'green' : 'red'
      let ssxId = this.props.campaign.details.ssx_id

      let buttonList = [ { name: 'Campaign Details', icon: campaignDetailsIconColor === 'red' ? 'error' :'settings', details: campaignDetailsText, color: campaignDetailsIconColor, href: '/edit/campaigndetails/' + ssxId },
                      //{ name: 'Strategy & Creative', icon: strategyIconColor === 'red' ? 'error' :'label', details: strategiesNeededLabeling + ' strategies and ' + creativesNeededLabeling +  ' creatives need labeling', color: strategyIconColor, href: '/edit/strategycreative/' + ssxId },
                      //{ name: 'Optimizations', icon: 'show_chart', details: totalOptimizations + ' optimization(s) for this campaign', color: totalOptimizationsColor, href: '/edit/optimizations/' + ssxId },
                      { name: 'Budget Planner', icon: 'table_chart', details: 'Plan your spend', color: '#3b5998', href: '/edit/budgetplanners/' + ssxId },
                      //{ name: 'Media Plan', icon: 'live_tv', details: 'Media plan details', color: '#000', href: '/edit/mediaplan/' + ssxId },
                      { name: 'Campaign Files', icon: 'folder_open', details: 'Quick storage for excel, pdf, etc.', color: '#000', href: '/edit/uploadfolder/' + ssxId },
                      //{ name: 'External Reports *Beta', icon: 'assessment', details: 'Campaign performance from external vendors', color: '#000', href: '/edit/externalreports/' + ssxId },
                      //{ name: 'Media CD *Beta', icon: 'assignment', details: 'Planner and performace input for all media spends', color: '#000', href: '/edit/mediacd/' + ssxId },
                      //{ name: 'Item List *Beta', icon: 'receipt', details: 'Campaign item list', color: '#000', href: '/edit/items/' + ssxId },
                      { name: 'Redirects', icon: 'link', details: 'Shorten, create and share powerful links you can track in FB and GA', color: '#000', href: '/edit/redirect/' + ssxId },
                    ]
      this.setState({buttonList: buttonList })

    }



render(){


  let buttonList = this.state.buttonList.map( current=> {
      return (
        <Button key={current.name} component={Link} to={current.href} className='col-12 col-md-4'  style={{border: '1px #ddd solid'}}>
            <div className='row d-flex align-items-center' style={{margin: '0px', padding: '10px 5px'}}>
                <div className='col-12 d-flex justify-content-center'>
                  <i style={{color: current.color, fontSize: '50px'}}className="material-icons" >{current.icon}</i>
                </div>

                <div className='col-12'>
                    <h5 className='d-flex justify-content-center' style={{margin: '0px', textTransform: 'none'}}>
                      {current.name}
                   </h5>
                   <p style={{margin: '0px', textTransform: 'none', textAlign: 'center'}}>{current.details}</p>
                </div>

            </div>
        </Button>
      )
  })

    return(

        <div className='row no-gutters'>
          {buttonList}
        </div>



      )
    }
}

import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import Dialog from 'material-ui/Dialog';
import BoxCreateTracker from './boxcreatetracker'
import TrackerItem from './trackeritem'
export default class ListTrackers extends Component {

    constructor(props) {
      super(props);
      this.state = {  trackers: 'loading', errorMessage: null, boxCreateTracker: false }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

    componentDidMount(){

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/trackers' )
        firebaseListener.on('value', (snap)=> {
          let tempArray = []
          let trackers = snap.val()
          for (let current in trackers){
                tempArray.push(trackers[current])
          }

          this.setState({ trackers: tempArray, firebaseListener: firebaseListener})
        });

    }

handleDialogClose = () => this.setState({ boxCreateTracker: false}) ;
passedSetState = (state, value) => { this.setState({[state]: value}) };

render(){

  let campaignDetails = this.props.campaignDetails
  let wmxID = campaignDetails && campaignDetails.wmx_id
  let wmxEnabled = campaignDetails && campaignDetails.wmxEnabled
  let client = campaignDetails.client

  if (this.state.trackers ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }   else if (this.state.trackers){


      const trackers = this.state.trackers.map((current, index) => {
            return (
                <TrackerItem key={index} currentTracker={current} reportId={this.props.reportId} ssxId={this.props.ssxId} />
            )
        })

    return (
      <div className='row' style={{padding: '0px', margin: '0px', height: '100%'}}>
            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
              <b style={{fontSize: '17px'}}>Click Affiliate Trackers:</b>
              <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({boxCreateTracker: true})}>add_box</i> <br />
            </div>

            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
              {trackers}
            </div>


            <Dialog
                  title='Add Tracker'
                  modal={false}
                  open={ this.state.boxCreateTracker === true  }
                  onRequestClose={this.handleDialogClose} >
              <BoxCreateTracker reportId={this.props.reportId} campaignDetails={this.props.campaignDetails} passedSetState={this.passedSetState} ssxId={this.props.ssxId}   />
            </Dialog>

      </div>
    )
  } else if (wmxID && wmxEnabled && client === 'PGWGCT' ){
        return (
          <div className='col-12 row' style={{padding: '10px 0px'}}>

            <div className='col-12' style={{padding: '0px', margin: '0px'}}>
              <b style={{fontSize: '17px'}}>Trackers:</b>
              <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({boxCreateTracker: true})}>add_box</i> <br />
            </div>

            <Dialog
                  title='Add Tracker'
                  modal={false}
                  open={ this.state.boxCreateTracker === true  }
                  onRequestClose={this.handleDialogClose} >
              <BoxCreateTracker reportId={this.props.reportId} campaignDetails={this.props.campaignDetails} passedSetState={this.passedSetState}    />
            </Dialog>

          </div>
          )
      } else if (wmxEnabled && client === 'PGWGCT'){
          return (
            <div className='col-12' style={{padding: '10px 0px'}}>
              <b>Trackers:</b><br />
               Must add a WMX ID before we can setup trackers.
            </div>
            )
        } else {
          //not a wmx campaign so no trackers
          return (
              <div />
              )
          }
    }

}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import  {Link} from 'react-router-dom';
import LinearProgress from 'material-ui/LinearProgress';
//import CampaignItem from './campaignlistitem/campaignitem' ;
import BoxCreateCampaign from './create/boxcreatecampaign'
import Dialog from 'material-ui/Dialog';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import Checkbox from 'material-ui/Checkbox';

export default class ListNationalBrandCampaigns extends Component {

    constructor(props) {
      super(props);
      this.state = { campaigns: 'loading', createCampaignBoxOpen: false, clientFilter: 'All', hideExpiredCampaigns: true };
    }

    componentDidMount(){

        this.filterCampaigns('none')

          }

      filterCampaigns = () => {
        let query

        let filter = this.state.clientFilter

        if (filter === 'none' || filter === 'All'){
          this.setState({campaigns: 'loading'})
          query = firebase.database().ref('/nationalbrandcampaigns/')
        } else {
          this.setState({campaigns: 'loading'})
          query = firebase.database().ref('/nationalbrandcampaigns/').orderByChild("details/client").equalTo(filter)
        }

        query.once('value').then( (snap)=> {

            let tempCampaigns = []
            snap.forEach( current => {
              var today = Date.now();

              let campaign = current.val() && current.val().details ? current.val().details : null

              //if hide epired campaigns is checked just remove expired campaigns from the client side
              if (this.state.hideExpiredCampaigns && campaign.endDate < today ){
                //console.log('campaign has expired!')
              } else {
                tempCampaigns.push(current.val().details)
              }


            })
                   if (tempCampaigns.length > 0) {
                        this.setState({ campaigns: tempCampaigns})
                    } else {
                      this.setState({ campaigns: []})
                    }
                }).catch( (error)=> {
                    console.log("Error getting campaigns:", error);
                });

      }

      handleDialogClose = () => this.setState({createCampaignBoxOpen: false}) ;


render(){

 if (this.state.campaigns === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  } else {

    var campaignItemsList = this.state.campaigns.map((currentCampaign, index) => {
          //return <CampaignItem key={index} campaign={ currentCampaign }/>
          return <div key={index} style={{border: '1px #ddd solid', borderRadius: '3px', padding: '5px'}}> <Link to={'/edit/nationalbrandcampaigndetails/' + currentCampaign.ssx_id} key={index}>{currentCampaign.campaignName}</Link></div>
          });

    return(
        <div className='' style={{margin: '0px', padding:'10px 15px'}}>

            <div className='row' style={{margin: '0px', padding:'0px', minHeight: '56px'}} >

              <div className='col-6 d-flex align-items-center' style={{paddingTop: '5px'}}>
                <b style={{fontSize: '25px'}}>National Campaigns</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({createCampaignBoxOpen: true})}>add_box</i> <br />
              </div>

              <div className='col-3 d-flex justify-content-end align-items-center'>
                <b style={{paddingTop: '10px'}}>Team:</b>
                <DropDownMenu  value={this.state.clientFilter} onChange={ (event, index, value) => this.setState({clientFilter: value}, ()=> this.filterCampaigns(value) ) }>
                  <MenuItem value={'All'} primaryText={`All`} />
                  <MenuItem value={'PGWGCT'} primaryText={`PGWGCT`} />
                  <MenuItem value={'PGSams'} primaryText={`PGSams`} />
                  <MenuItem value={'Essilor'} primaryText={`Essilor`} />
                  <MenuItem value={'Coke'} primaryText={`Coke`} />
                  <MenuItem value={'Duracell'} primaryText={`Duracell`} />
                  <MenuItem value={'Coty'} primaryText={`Coty`} />
                  <MenuItem value={'Keurig'} primaryText={`Keurig`} />
                </DropDownMenu>
              </div>

              <div className='col-3 d-flex justify-content-end align-items-center' style={{paddingTop: '15px'}}>
                <Checkbox
                    label="Hide Expired"
                    checked={this.state.hideExpiredCampaigns}
                    onCheck={ (event, checked)=> this.setState({hideExpiredCampaigns: checked},  ()=> this.filterCampaigns() )}
                  />
              </div>
            </div>

            <Dialog
                title='Create New National Campaign'
                modal={false}
                autoScrollBodyContent={true}
                open={ this.state.createCampaignBoxOpen === true  }
                onRequestClose={this.handleDialogClose} >
            <BoxCreateCampaign email={this.props.email} firebaseUID={this.props.firebaseUID} />
            </Dialog>

          <div className='col-12' style={{margin: '0px', padding:'0px'}} >
            {campaignItemsList}
          </div>

        </div>
      );
    }
  }
}

/*
<div className='col-12 '>
  <i style={{cursor: 'pointer', margin: '0px', padding: '10px'}} className="material-icons" onClick={ ()=> this.searchCampaign()}>search</i>
  <TextField
      floatingLabelText='Search'
      floatingLabelFixed={true}
      fullWidth={false}
      type="text"
      value={this.state.email}
      onChange={ (event, value) => this.setState({ email : value})}
    />
</div>
*/

import React, {Component} from 'react';
import axios from 'axios';
import  {Link} from 'react-router-dom';
import Divider from 'material-ui/Divider';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import SelectField from 'material-ui/SelectField';
import * as firebase from 'firebase';
import LinearProgress from 'material-ui/LinearProgress';
import DatePicker from 'material-ui/DatePicker';
import DropDownMenu from 'material-ui/DropDownMenu';
import MenuItem from 'material-ui/MenuItem';
import {RadioButton, RadioButtonGroup} from 'material-ui/RadioButton';

export default class BoxCreateCampaign extends Component {

    constructor(props) {
      super(props);
      this.state = { errorMessage: null, loading: false, startDate:  new Date(), endDate: null, campaignName: '', totalSpend: 0.00, subgbu: '', brand: '', destinationURL: '', tactics: '' };
    }

    campaignSubmitted(e){
      e.preventDefault()

      if (!this.state.endDate || this.state.campaignName == '' || this.state.totalSpend == 0.00){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide all data.</b>})
      } else {

          this.setState({errorMessage: null, loading: true})
          var today = Date.now();
          var tempStartDate = this.state.startDate.getTime()
          var tempEndDate = this.state.endDate.getTime()
          //random campaign uid
          let campaignId

          return firebase.database().ref('util/nextCampaignId').transaction( (id) => {
            console.log('campaignId: ' + id)
                //if (!id) { throw {message: 'Error connect to database. Please try again later.'} }

                campaignId = id
                return id + 1
              }).then ( ()=> {
                if (!campaignId) { throw {message: 'Error connect to database. Please try again later.'} }

              //first we need to add this campaign to SQL DB
              // Make a request for a user with a given ID
              //https://us-central1-tableau-180613.cloudfunctions.net/addBrandCampaignToTB?startDate=1522386000000&endDate=1522818000000&campaign_name=campaignNAMEE&ssx_id=12345&totalSpend=1200
              var queryString = '?startDate=' + tempStartDate + '&endDate=' + tempEndDate + '&campaign_name=' + this.state.campaignName + '&ssx_id=' + campaignId + '&totalSpend=' + this.state.totalSpend
              return axios.get('https://us-central1-tableau-180613.cloudfunctions.net/addBrandCampaignToTB' + queryString)

          }).then( (response)=> {

              console.log(response);
              return firebase.database().ref('/nationalbrandcampaigns/' + campaignId).set({
                  details: { dateCreated: today,
                  startDate: tempStartDate,
                  endDate: tempEndDate,
                  ssx_id: campaignId,
                  client: 'PGWGCT',
                  createdBy: this.props.email,
                  campaignName: this.state.campaignName,
                  total_spend: this.state.totalSpend,
                  subgbu: this.state.subgbu,
                  brand: this.state.brand,
                  destinationURL: this.state.destinationURL,
                  tactics: this.state.tactics,
                  runbySSX: false
                }
              })

            }).then( () => {
              console.log('SUCCESS')
              window.location = '/edit/nationalbrandcampaigndetails/' + campaignId
              //this.setState({errorMessage: null, loading: false})
            }).catch( (error) => {
              this.setState({errorMessage: error.message, loading: false})
              console.log(error);
            });


      }


    }


render(){

    return(

      <div className='row' style={{padding: '20px'}}>

        <div className="col-12">

                  <form onSubmit={ event => console.log('fire') } style={{width: '100%'}}>
                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        floatingLabelText='Campaign Name'
                        onChange={ (event, value) => this.setState({ campaignName: value}) }
                        hintText="Scale Winter Olympics"
                        value={this.state.campaignName}
                    />
                  </div>


                  <div className='col-12 d-flex align-items-center'>
                    <DatePicker
                        floatingLabelText='Start Date'
                        dialogContainerStyle={{backgroundColor: ''}}
                        hintText="Start Date"
                        value={this.state.startDate}
                        onChange={ (hold, date)=> this.setState({startDate: date}) }
                     />
                  </div>

                  <div className='col-12 d-flex align-items-center'>
                    <DatePicker
                        floatingLabelText='End Date'
                        hintText="End Date"
                        value={this.state.endDate}
                        onChange={ (hold, date)=> this.setState({endDate: date}) }
                     />
                  </div>


                  <div className='col-12 d-flex align-items-center'>
                    <DropDownMenu  value={this.state.subgbu}
                    autoWidth={false}
                    maxHeight={300}
                    style={{width: '256px'}}
                    underlineStyle={{padding: '0px', margin: '0px'}}
                    labelStyle={{padding: '0px', margin: '0px'}}
                    onChange={ (event, index, value) => this.setState({ subgbu: value}) }>
                      <MenuItem disabled={true} value={''} primaryText={`Select Sub-GBU/Category`} />
                      <MenuItem value={'APDO'} primaryText={`APDO`} />
                      <MenuItem value={'Grooming'} primaryText={`Grooming`} />
                      <MenuItem value={'Oral Care'} primaryText={`Oral Care`} />
                      <MenuItem value={'Home Care'} primaryText={`Home Care`} />
                      <MenuItem value={'Hair'} primaryText={`Hair`} />
                      <MenuItem value={'Family Care'} primaryText={`Family Care`} />
                      <MenuItem value={'Skin Care'} primaryText={`Skin Care`} />
                      <MenuItem value={'FFAI'} primaryText={`FFAI`} />
                      <MenuItem value={'PHC'} primaryText={`PHC`} />
                      <MenuItem value={'Hair'} primaryText={`Hair`} />
                      <MenuItem value={'Baby Care'} primaryText={`Baby Care`} />
                      <MenuItem value={'Fabric Care'} primaryText={`Fabric Care`} />
                      <MenuItem value={'Scale'} primaryText={`Scale`} />
                      <MenuItem value={'Trial Travel'} primaryText={`Trial Travel`} />
                    </DropDownMenu>
                  </div>



                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        floatingLabelText='Brand'
                        onChange={ (event, value) => this.setState({ brand: value}) }
                        hintText="Pampers"
                        value={this.state.brand}
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        floatingLabelText='Destination URL'
                        onChange={ (event, value) => this.setState({ destinationURL: value}) }
                        hintText="www.walmart.com"
                        value={this.state.destinationURL}
                        multiLine={true}
                        fullWidth={true}
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        floatingLabelText='Tactics'
                        onChange={ (event, value) => this.setState({ tactics: value}) }
                        hintText="WPAs, Catapults"
                        value={this.state.tactics}
                        multiLine={true}
                        fullWidth={true}
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center'>
                    <TextField
                        floatingLabelText='Total Spend'
                        onChange={ (event, value) => this.setState({ totalSpend: value}) }
                        hintText="100000"
                        value={this.state.totalSpend}
                        type='number'
                    />
                  </div>


                  <div className="col-12">
                  {this.state.errorMessage}
                  </div>
                  </form>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '10px 0px'}}>
                        {this.state.loading == false ?
                          <RaisedButton
                              label="Create"
                              fullWidth={false}
                              secondary={true}
                              type="submit"
                              onClick={ event => this.campaignSubmitted(event) }
                              />
                        :
                          <RaisedButton
                            style={{height: '100%', backgroundColor: 'white'}}
                            secondary={true}
                            children={ <div style={{padding: '2px', backgroundColor: 'white'}} key={1}><LinearProgress /></div>}
                            fullWidth={false}
                           />
                        }
                    </div>
          </div>

      </div>

      )
    }
}

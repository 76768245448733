import React, {Component} from 'react';
import  {Link} from 'react-router-dom';

export default class CampaignItem extends Component {

    constructor(props) {
      super(props);
      this.state = { statusColor: 'loading'  };
    }

    /*
    componentDidMount(){

      let totalStrategies = 0
      let strategiesSavedInTB = 0
      let strategiesNeededLabeling = 0
      let statusColor
      let mm_strategies = this.props.campaign.mm_strategies
      for (let current in mm_strategies) {

            totalStrategies++
            if (mm_strategies[current].saved_in_tb == true){
              strategiesSavedInTB ++
                }
          }

      let totalCreatives = 0
      let creativesSavedInTB = 0
      let mm_creatives = this.props.campaign.mm_creatives
      for (let current in mm_creatives) {

            totalCreatives++
            if (mm_creatives[current].saved_in_tb === true){
              creativesSavedInTB ++
                }
          }

      creativesNeededLabeling = totalCreatives - creativesSavedInTB
      strategiesNeededLabeling = totalStrategies - strategiesSavedInTB

      if (totalStrategies === strategiesSavedInTB && totalCreatives === creativesSavedInTB ){
          statusColor = 'green'
      } else {
          statusColor = 'red'
      }

      let campaignDetails = this.props.campaign.details
      let wmx_id = campaignDetails.wmx_id
      let mm_campaign_id = campaignDetails.mm_campaign_id
      let wmxEnabled = campaignDetails.wmxEnabled == true ? true : false
      let mmEnabled = campaignDetails.mmEnabled == true ? true : false

      if (!mm_campaign_id && mmEnabled ){
        statusColor = 'red'
      } else if (!wmx_id && wmxEnabled ){
        statusColor = 'red'
      } else {

      }

      this.setState({statusColor: statusColor})
    }
    */


render(){

  let campaignDetails = this.props.campaign.details
  let warnings = this.props.campaign.warnings
  let statusColor
  if (warnings.mmId || warnings.creative || warnings.strategy || warnings.wmxId){
    statusColor = 'red'
  } else {
    statusColor = 'green'
  }
  //let dateCreated = new Date(campaignDetails.dateCreated).toLocaleDateString()
  let startDate = new Date(campaignDetails.startDate).toLocaleDateString()
  let endDate = new Date(campaignDetails.endDate).toLocaleDateString()
  let dateStyle = {color: 'grey', fontSize: '12px'}

      return(

      <div className='' style={{margin: '0px', border: '1px solid', borderColor: '#ddd', borderRadius:'1px', padding: '10px'}} >
          <div style={{cursor: 'pointer' }} className='row d-flex  no-gutters'  >
              <Link to={'/campaign/' + campaignDetails.ssx_id} className='col-12 row no-gutters' >
                  <div className='col-12 row no-gutters' >
                    <div className='col-1' >
                      <i style={{color: statusColor}}className="material-icons" >{ statusColor === 'red' ? 'error' :'check_circle'}</i>
                    </div>
                    <div className='col-7'>
                      <span style={{margin:'0px', color: 'black'}}>{campaignDetails.campaignName}</span>
                    </div>
                    <div className='col-2'>
                      <span style={dateStyle}>{startDate}</span>
                    </div>
                    <div className='col-2'>
                      <span style={dateStyle}>{endDate}</span>
                    </div>
                  </div>
              </Link>
          </div>
        </div>
    );
  }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import LinearProgress from 'material-ui/LinearProgress';
import IconButton from 'material-ui/IconButton';

export default class TrackerItem extends Component {

    constructor(props) {
      super(props);
      this.state = {  strategyDetails: 'loading' }
    }

    componentDidMount(){

      //need to get the names and values of the trackers, not just ids
      let creativeDetails
      let strategyDetails

      firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/creatives/' + this.props.currentTracker.creativeId + '/details' ).once('value').then( snap=> {

        creativeDetails = snap.val()
        return firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/strategies/' + this.props.currentTracker.strategyId + '/details' ).once('value')

      }).then (snap => {
        strategyDetails = snap.val()

        this.setState({creativeDetails: creativeDetails, strategyDetails: strategyDetails})
      }).catch ( (err) => {
        console.log(err)
      })

    }

render(){

  if (this.state.strategyDetails === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );

  } else {

  let strategyDetails = this.state.strategyDetails
  let creativeDetails = this.state.creativeDetails
  let trackers = this.props.currentTracker.trackers
  let destinationURL = this.props.currentTracker && this.props.currentTracker.destinationURL

  console.log(this.props.currentTracker)
    return (
      <div className='row boxBorder' style={{padding: '0px', margin: '0px'}}>

          <div className='col-12' style={{padding: '0px 10px', margin: '0px'}}>
            <label className='label'>{strategyDetails.targetingChannel} - {strategyDetails.partner} - {strategyDetails.targetSegment} - {strategyDetails.device} - {strategyDetails.mediaType}({strategyDetails.strategyId}) </label><br />
            <label className='label'>{creativeDetails.creativeImage}-{creativeDetails.CTA}-{creativeDetails.mediaFormat}-{creativeDetails.size}-{creativeDetails.landingPage}-{creativeDetails.customOne}-{creativeDetails.customTwo}({creativeDetails.creativeId}) </label><br />
            <a href={destinationURL} target='_blank' className='label'>{destinationURL}</a>
          </div>

          <div className='col-12' style={{padding: '5px', margin: '0px'}}>
            <div style={{padding: '0px'}} className='d-flex align-items-center' >
                <CopyToClipboard text={trackers.clickTracker}>
                      <IconButton iconClassName="material-icons" tooltip="Copy">content_copy</IconButton>
                </CopyToClipboard>
                <b style={{padding: '0px', margin: '0px', fontSize: '12px', overflow: 'hidden'}}>{trackers.clickTracker}</b>
            </div>
          </div>


      </div>
      )
    }
  }
}

/*
<div className='col-6' style={{padding: '5px', margin: '0px'}}>
  <div style={{padding: '0px'}} className='d-flex align-items-center' >
      <CopyToClipboard text={trackers.impressionTracker}>
            <IconButton iconClassName="material-icons" tooltip="Copy">content_copy</IconButton>
      </CopyToClipboard>
      <b style={{padding: '0px', margin: '0px', fontSize: '8px', overflow: 'hidden'}}>{trackers.impressionTracker}</b>
  </div>
</div>
*/


export function parseStrategyName(strategy_name) {

  // "PMP - GroundTruth - Walmart Neighborhood - Mobile - Display"
  let temp_strategy_name = strategy_name.replace(/ /g, '');
  let split_temp_strategy_name = temp_strategy_name.split("-");
  let targetingChannel =  split_temp_strategy_name[0] ? split_temp_strategy_name[0] : ''
  let partner =  split_temp_strategy_name[1] ? split_temp_strategy_name[1] : ''
  let targetSegment =  split_temp_strategy_name[2] ? split_temp_strategy_name[2] : ''
  let device =  split_temp_strategy_name[3] ? split_temp_strategy_name[3] : ''
  let mediaType =  split_temp_strategy_name[4] ? split_temp_strategy_name[4] : ''

  let parsedValues = { targetingChannel : targetingChannel, partner: partner, targetSegment: targetSegment, device: device, mediaType: mediaType}
  return parsedValues
}

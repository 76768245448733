import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import BoxCreativeDetails from './boxcreativedetails';
import CreativeItem from './creativeitem'

export default class ListCreatives extends Component {

    constructor(props) {
      super(props);
      this.state = { creatives: 'loading', boxCreativeDetails: false}
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }


    componentDidMount(){

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssxId + '/externalReports/' + this.props.reportId + '/creatives' )
        firebaseListener.on('value', (snap)=> {

          let tempArray = []
          let creatives = snap.val()
          for (let current in creatives){
              tempArray.push(creatives[current])
          }

          this.setState({ creatives: tempArray, firebaseListener: firebaseListener})
        });


    }


    //this is called when the create button is clicked, but first we need to create
    //a new strategy id
createNewCreative = () =>{

      let creativeId
      firebase.database().ref('util/nextStrategyID').transaction( (id) => {
        console.log('CreativeId: ' + id)
            //if (!id) { throw {message: 'Error connect to database. Please try again later.'} }
            creativeId = 'c' + id
            return id + 1

      }).then( ()=> {

      this.setState({boxCreativeDetails: true, creativeId: creativeId})

      })
}

handleDialogClose = () => this.setState({boxCreativeDetails: false}) ;

passedSetState = (state, value) => { this.setState({[state]: value}) };

render(){

  console.log(this.props)

  if (this.state.creatives ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

    const listCreatives = this.state.creatives.map((current, index) => {
          return ( <CreativeItem creativeId={current.details.creativeId} currentCreative={current} key={current.details.creativeId} campaignDetails={this.props.campaignDetails}  reportId={this.props.reportId} creativeParameters={this.props.creativeParameters} passedSetState={this.passedSetState}/>)
      })


      return (
        <div className='row' style={{padding: '0px', margin: '0px'}}>
              <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                <b>Creatives:</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={this.createNewCreative}>add_box</i> <br />
              </div>

              <div className='col-12' style={{padding: '0px'}}>
                {listCreatives}
              </div>

              <Dialog open={ this.state.boxCreativeDetails } onClose={this.handleDialogClose} >
                <DialogTitle>{"Add Creative"}</DialogTitle>
                <DialogContent>
                  <BoxCreativeDetails campaignDetails={this.props.campaignDetails} creativeId={this.state.creativeId} reportId={this.props.reportId} passedSetState={this.passedSetState} parameters={this.props.creativeParameters.parameters}    />
                </DialogContent>
              </Dialog>

        </div>
      )
    }
  }
}


//<Metrics campaignDetails={this.props.campaignDetails} currentStrategy={this.props.currentStrategy} reportId={this.props.reportId}/>

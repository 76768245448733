import React, {useState} from 'react';
import StrategyItem from './strategyItem'
import Button from '@material-ui/core/Button';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function PhaseItem (props) {

      let [detailsOpen, setDetailsOpen] = useState(false)

      let title = 'Phase ' + props.phaseNumber
      let rowStyle = { flex: 1, fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px'}
      let currentPhase = props.currentPhase
      let phaseStrats = currentPhase.phaseStrats ? currentPhase.phaseStrats : []
      let phaseId = currentPhase.phaseId

      let phaseTotalBudgetPerc = currentPhase && currentPhase.phaseTotalBudgetPerc ? currentPhase.phaseTotalBudgetPerc : ''
      let phaseTotalBudget = currentPhase && currentPhase.phaseTotalBudget ? currentPhase.phaseTotalBudget : ''
      let phaseDailyBudget = currentPhase && currentPhase.phaseDailyBudget ? currentPhase.phaseDailyBudget : ''
      let numOfDays = currentPhase && currentPhase.numOfDays ? currentPhase.numOfDays : ''
      let startDate = currentPhase && currentPhase.startDate ? new Date(currentPhase.startDate) : new Date()
      let endDate = currentPhase && currentPhase.endDate ? new Date(currentPhase.endDate) : new Date()

      let showAdsetsClass = detailsOpen ? 'block' : 'none'

      let phaseStratsKeys = Object.keys(phaseStrats)

      let strats = phaseStratsKeys.map( stratId => {
        let currentStrat = phaseStrats[stratId]
        return <StrategyItem key={currentStrat.strategyId} phaseId={phaseId} currentStrat={currentStrat} startDate={startDate} endDate={endDate} numOfDays={numOfDays} handleStrategyChange={props.handleStrategyChange} deleteRow={props.deleteRow}  />
      })

      return (
        <div className="row col-12" style={{ border: '1px  #ddd solid'}}>

            <div className="row col-12" style={{ padding: '5px'}}>

                  <div  style={{ width: '25px', fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold', cursor: 'pointer'}} onClick={ ()=> setDetailsOpen(!detailsOpen)}>
                    <i className="material-icons" onClick={ ()=> props.deleteRow(phaseId) } >delete</i>
                  </div>

                  <div style={rowStyle} >
                      <input type="text" name="Phase title" className='budgetPlannerInputs' value={title} onChange={ (e) => props.handlePhaseChange(phaseId,'title', e.target.value) } />
                  </div>

                  <div  style={rowStyle}>
                    <DatePicker
                      selected={ startDate }
                      onChange={ (value) => props.handlePhaseDateChange(phaseId,'startDate', value)}
                      className='budgetPlannerInputs'
                    />
                  </div>
                  <div style={rowStyle}>
                    <DatePicker
                      selected={ endDate }
                      onChange={ (value) => props.handlePhaseDateChange(phaseId,'endDate', value) }
                      className='budgetPlannerInputs'
                    />
                  </div>

                  <div style={rowStyle}>
                    <>{numOfDays}</>
                  </div>

                  <div style={rowStyle}>
                    <b>${phaseDailyBudget}</b>
                  </div>

                  <div style={rowStyle}>
                    <input type="number" name="phaseTotalBudgetPerc" className='budgetPlannerInputs' value={phaseTotalBudgetPerc} onChange={ (e) => props.handlePhaseChange(phaseId,'phaseTotalBudgetPerc', e.target.value) } />
                  </div>

                  <div  style={rowStyle}>
                    <input type="number" name="phaseTotalBudget" className='budgetPlannerInputs' value={phaseTotalBudget} onChange={ (e) => props.handlePhaseChange(phaseId,'phaseTotalBudget', e.target.value) } />
                  </div>

                  <div  style={{ width: '25px', fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold', cursor: 'pointer'}} onClick={ ()=> setDetailsOpen(!detailsOpen)}>
                    <i className="material-icons" >expand_more</i>
                  </div>

            </div>

            <div className="row col-12" style={{display: showAdsetsClass}}>
              <div className="col-12" style={{ padding: '5px', backgroundColor: '#ddd'}}>
                {strats}
              </div>


              <div className="col-12 justify-content-center" style={{display: showAdsetsClass, padding: '5px', backgroundColor: '#ddd'}}>
                <Button onClick={ () => props.addStrategy(phaseId)} color='default' variant='text'>
                  <i className="material-icons" >add</i>
                  Add Strategy
                </Button>

                <Button onClick={ ()=> props.calcStratBudgets(phaseId) } color='default' variant='outlined' style={{ marginLeft: '5px'}}>
                  % To Phase
                </Button>

              </div>
            </div>

        </div>

      )
}

import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import CampaignStrategies from './strategy/campaignstrategies';
import CampaignCreatives from './creative/campaigncreatives';
import {Tabs, Tab} from 'material-ui/Tabs';
import EditPageHeader from '../../util/editpageheader'

export default class EditStrategyCreative extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignData: 'loading', tabValue: 'strategy', ssx_id: this.props.ssx_id }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

    componentDidMount(){

      let utilParameters

      firebase.database().ref('/util/parameters/').once('value').then( snap => {
      utilParameters = snap.val()

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssx_id)
        firebaseListener.on('value', (snap)=> {
          console.log('new data')
          this.setState({campaignData: snap.val(), firebaseListener: firebaseListener, utilParameters: utilParameters})
        });

      }).catch( error => {
        this.setState({utilParameters: null, errorMessage: error.message})
      })


    }

    passedSetState = (state, value) => {
      this.setState({[state]: value})
    }

    handleChange = (value) => {
       this.setState({
         tabValue: value,
       });
     }

render(){

  if (this.state.campaignData ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      return (
        <div className='row no-gutters' style={{ padding: '20px'}}>

              <EditPageHeader pageTitle='Strategy and Creative Segmentation' campaignName={this.state.campaignData.details.campaignName} />

              <div className='col-12'>
                  <Tabs value={this.state.tabValue} onChange={this.handleChange} tabItemContainerStyle={{backgroundColor: 'white', border: '1px solid #ddd', borderRadius: '2px'}} >
                      <Tab label="Strategy" value="strategy" style={{color: 'black'}}>
                        <CampaignStrategies mm_strategies={this.state.campaignData.mm_strategies} mm_strategies_queue={this.state.campaignData.mm_strategies_queue}  mm_campaign_id={this.state.campaignData.details.mm_campaign_id} {...this.state}/>
                      </Tab>
                      <Tab label="Creative" value="creative" style={{color: 'black'}}>
                        <CampaignCreatives mm_creatives={this.state.campaignData.mm_creatives} mm_creatives_queue={this.state.campaignData.mm_creatives_queue}  mm_campaign_id={this.state.campaignData.details.mm_campaign_id} {...this.state}/>
                      </Tab>
                    </Tabs>
              </div>
        </div>
      )
    }
  }
}

import React, {useState} from 'react';
import "react-datepicker/dist/react-datepicker.css";

export default function PhaseItem (props) {

      let [detailsOpen, setDetailsOpen] = useState(false)

      let rowStyle = { flex: 1, fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px'}
      let currentStrat = props.currentStrat
      let name = currentStrat.name
      let strategyId = currentStrat.strategyId

      let stratTotalBudgetPerc = currentStrat && currentStrat.stratTotalBudgetPerc ? currentStrat.stratTotalBudgetPerc : ''
      let stratTotalBudget = currentStrat && currentStrat.stratTotalBudget ? currentStrat.stratTotalBudget : ''
      let strategyDailyBudget = currentStrat && currentStrat.stratDailyBudget ? currentStrat.stratDailyBudget : ''


      return (
        <div className="row col-12" style={{ border: '1px  #ddd solid'}}>

                  <div  style={{ width: '25px', fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold', cursor: 'pointer'}} onClick={ ()=> setDetailsOpen(!detailsOpen)}>
                    <i className="material-icons" onClick={ ()=> props.deleteRow(props.phaseId, strategyId) } >delete</i>
                  </div>

                  <div  style={rowStyle}>
                    <input type="text" name="strategyName" className='budgetPlannerInputs' value={name} onChange={ (e) => props.handleStrategyChange(props.phaseId, strategyId,'name', e.target.value) } />
                  </div>
                  <div  style={rowStyle}>

                  </div>
                  <div style={rowStyle}>

                  </div>

                  <div style={rowStyle}>
                    <>{props.numOfDays}</>
                  </div>

                  <div style={rowStyle}>
                    <b>${strategyDailyBudget}</b>
                  </div>

                  <div style={rowStyle}>
                    <input type="number" name="stratTotalBudgetPerc" className='budgetPlannerInputs' value={stratTotalBudgetPerc} onChange={ (e) => props.handleStrategyChange(props.phaseId, strategyId,'stratTotalBudgetPerc', e.target.value) } />
                  </div>

                  <div  style={rowStyle}>
                    <input type="number" name="stratTotalBudget" className='budgetPlannerInputs' value={stratTotalBudget} onChange={ (e) => props.handleStrategyChange(props.phaseId, strategyId,'stratTotalBudget', e.target.value) } />
                  </div>

                  <div  style={{ width: '25px', fontSize: '12px', fontColor: 'grey', display: 'flex', alignItems:'center', padding: '2px', fontWeight: 'bold', cursor: 'pointer'}} onClick={ ()=> setDetailsOpen(!detailsOpen)}>

                  </div>

        </div>

      )
}

/*
<DatePicker
  disabled
  selected={ props.endDate }
  className='budgetPlannerInputs'
/>
*/

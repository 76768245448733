import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import CampaignItemDetails from './campaignitemdetails';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

export default class Campaign extends Component {

    constructor(props) {
      super(props);
      this.state = { campaign: 'loading'}
    }


    componentDidMount(){

      firebase.database().ref('/campaigns/' + this.props.ssx_id).once('value').then( snap => {
        if ( !snap.val()){
          throw new Error('Error, no campaign data.')
        }
        this.setState({campaign: snap.val() })
      }).catch( err => {
        console.error(err.message)
        this.setState({campaign: 'loading', errorMessage: err.message})
      })

    }


render(){

  console.log(this.props)

  if (this.state.campaign === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

    let MMLink  = 'https://us-east-1.online.tableau.com/t/ssx/views/mm_metrics_daily/DailyDashboard?iframeSizedToWindow=true&:embed=y&:showAppBanner=false&:display_count=no&:showVizHome=no&mm_campaign_id=' + this.state.campaign.details.mm_campaign_id
    let TotalMetricsDaily  = 'https://us-east-1.online.tableau.com/#/site/ssx/views/total_metrics_daily/total_metrics_daily?:iid=1&ssx_id=' + this.state.campaign.details.ssx_id
    let ExternalReportLink  = 'https://us-east-1.online.tableau.com/#/site/ssx/views/overall_metrics_view/overall_metrics_view?:iid=6&ssx_id=' + this.state.campaign.details.ssx_id


    let campaignDetails = this.state.campaign && this.state.campaign.details
    let dateCreated = campaignDetails.dateCreated && new Date(campaignDetails.dateCreated).toLocaleDateString()
    let startDate = campaignDetails.startDate && new Date(campaignDetails.startDate).toLocaleDateString()
    let endDate = campaignDetails.endDate && new Date(campaignDetails.endDate).toLocaleDateString()

      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

              <div className='col-12 col-md-3'>
                <img src='/img/superthanks.svg' alt='chart' style={{width: '100%', padding: '25px'}} />
              </div>

              <div className='col-12 col-md-9 d-flex align-items-center' style={{margin: '0px', padding: '20px'}}>
                  <div>
                    <h4>{campaignDetails.campaignName}</h4>
                    <label>Created by {this.state.campaign.details.createdBy} on {dateCreated}</label><br />
                    <label>{startDate}</label> - <label>{endDate}</label>
                  </div>
              </div>

            <div className='col-12' style={{margin: '0px', padding: '20px'}}>
                <CampaignItemDetails campaign={this.state.campaign} />
            </div>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import BoxTactic from './boxtactic'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class TacticItem extends Component {

    constructor(props) {
      super(props);
      this.state = { boxTactic: false }
    }


handleDialogClose = () => this.setState({boxTactic: false}) ;

passedSetState = (state,value) => { this.setState({[state]: value}) }

render(){

let currentDetails = this.props.currentTactic.details
let currentMetrics = this.props.currentTactic.metrics

    return (
      <div className='row boxBorder' style={{margin: '0px', backgroundColor:'', borderRadius:'1px', padding: '10px', width: '100%'}} >

            <div className='col-1  d-flex align-items-center'>
              <i className="material-icons" style={{padding: '5px',cursor: 'pointer', color: '#3E8EDE'}} onClick={ ()=> this.setState({ boxTactic: !this.state.boxTactic  }) } >edit</i>
            </div>
            <div className='col-7  d-flex align-items-center'>
              <span>{currentDetails.tacticName}</span>
            </div>
            <div className='col-2  d-flex align-items-center'>
              <span>${currentMetrics.spend}</span>
            </div>
            <div className='col-2  d-flex align-items-center'>
              <span>{currentMetrics.impressions}</span>
            </div>

          <Dialog onClose={ () => this.setState({boxTactic: false}) } open={this.state.boxTactic} >
            <DialogTitle>Edit Tactic</DialogTitle>
            <DialogContent>
              <BoxTactic  {...this.props} tacticId={currentDetails.id} passedSetState={this.passedSetState}/>
            </DialogContent>
          </Dialog>

      </div>
            )
  }
}

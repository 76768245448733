import React, {Component} from 'react';
import * as firebase from 'firebase';
import axios from 'axios';
import LoadingButton from '../../../util/loadingbutton'

export default class QueuedStategies extends Component {

    constructor(props) {
      super(props);
      this.state = { queuedBoxClass: 'hidden-xs-up', buttonStatus: 'regular' }
    }

    removeItemFromQueue(strategy_id){
      //delete entire key
      let updates = {}
      updates['/campaigns/' + this.props.ssx_id + '/mm_strategies_queue/' + strategy_id ] = null
      updates['/campaigns/' + this.props.ssx_id + '/mm_strategies/' + strategy_id + '/saved_in_tb/' ] = false

      firebase.database().ref().update(updates).then( () => {
        this.setState({ errorMessage: null })
      }).catch (err =>{
        this.setState({ errorMessage: err.message })
      });

    }

addItemsToTBSQL = () => {

    if ( this.isObjEmpty( this.props.mm_strategies_queue) ){
      this.setState({errorMessage: <b style={{color: 'red'}}>Queue is empty, please add items</b>})
    } else {

      this.setState({buttonStatus: 'loading'})

      //make call to endpoint, send ssx_id
      //server will grab the mm_strategies_queue from firebase
      //axios.get('http://localhost:9000/tableau-180613/us-central1/updateTBStrategyMap?ssx_id=' + this.props.ssx_id ).then( response => {
      axios.get('https://us-central1-tableau-180613.cloudfunctions.net/updateTBStrategyMap?ssx_id=' + this.props.ssx_id ).then( response => {

        //button status will be regular because when finished the queue will dissapear
        this.setState({buttonStatus: 'regular', errorMessage : null})
      }).catch( error => {
        console.log(error);
        this.setState({buttonStatus: 'regular', errorMessage : error.message})
      });
    }
}

//use this to see if object is empty
 isObjEmpty(obj) {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

render(){

              let listItems = []
              let mm_strategies_queue = this.props.mm_strategies_queue
              let queueCount = 0

              if ( this.isObjEmpty(mm_strategies_queue) ) {
                return null
              } else {

                    for (let key in mm_strategies_queue) {
                        let current = mm_strategies_queue[key]
                        queueCount++
                        listItems.push(<div key={key} className='row' style={{margin: '0px'}}>
                                          <div  className='col-12 d-flex d-flex justify-content-between' style={{padding: '5px'}}>
                                              <div>
                                                <b>{current.targetingChannel} - {current.partner} - {current.targetSegment} - {current.device} - {current.mediaType} </b><br />
                                              </div>
                                              <div className='d-flex align-items-center'>
                                                <i className="material-icons" style={{cursor: 'pointer' }} onClick={ (index)=> this.removeItemFromQueue(key)  }>delete</i>
                                              </div>
                                          </div>
                                          <div className='col-12' style={{height: '1px', backgroundColor: '#ddd'}} />
                                      </div>)
                                          }
      return (

        <div style={{boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px', position: 'fixed', bottom: '0px', width: '31%', right: '0px', zIndex: '10000', backgroundColor:'white'}} >

            <div style={{cursor: 'pointer', padding: '10px', borderRadius: '1px', margin: '0px', backgroundColor: '#FFD923' }} className='row d-flex align-items-center' onClick={ ()=> this.setState({ queuedBoxClass: this.state.queuedBoxClass === '' ? 'hidden-xs-up' : ''  }) } >
                <div className='col-10 d-flex align-items-center' >
                  <b>Queued Strategies ({queueCount}):</b>
                </div>

                <div className='col-2 d-flex align-items-center justify-content-end' >
                    {this.state.queuedBoxClass === '' ?
                    <i className="material-icons">keyboard_arrow_down</i>
                    :
                    <i className="material-icons">keyboard_arrow_up</i>
                    }
                </div>
            </div>


          <div className={ 'row ' + this.state.queuedBoxClass} style={{padding: '0px', margin:'0px'}}>

              <div className='col-12' style={{padding: '0 10px 10px 10px', maxHeight: '500px', overflow: 'auto'}}>
                {listItems}
              </div>

              <div className='col-12' style={{padding: '10px'}}>
                  <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Upload' savedLabel='Uploaded!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' icon='file_upload' regularButtonClick={this.addItemsToTBSQL} savedButtonClick={ ()=> this.setState({ buttonStatus: 'regular'}) } />
                  <br />
                  {this.state.errorMessage}
              </div>

          </div>

        </div>


      )
    }
  }
}

import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function LoadingButton(props){

//removed type='submit'
let variant = props.variant ? props.variant : 'contained'
let color = props.color ? props.color : 'primary'
let icon = props.icon ? <i style={{color: '#fff'}}className="material-icons" >{props.icon}</i> : null

  if (props.buttonStatus === 'saved') {
    return (
              <Button fullWidth={false} onClick={ props.savedButtonClick} variant={variant} style={{backgroundColor: '#00C18B', color: '#fff'}} >
              <i style={{color: '#fff'}}className="material-icons" >check_circle</i>
              {props.savedLabel}
              </Button>
            );
  } else if (props.buttonStatus === 'loading') {
      return(
              <Button fullWidth={false} variant={variant} color={color}>
                <CircularProgress style={{color: 'white'}} />
              </Button>
            );
  } else {

      return(

          <Button type='submit' fullWidth={false} onClick={ props.regularButtonClick} variant={variant} color={color}>
            {icon}
            {props.regularLabel}
          </Button>

      );
    }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class ListUser extends Component {

    constructor(props) {
      super(props);
      let {user} = props
      let userRoles = user && user.userRoles ? user.userRoles  : {}
      this.state = { userRoles: userRoles }
    }

handleChange = ( roleLevel, readOrWrite, value) => {

  //it's possible role doesn't exist in user data which will throw error unless we create it
  let currentRole = this.state.userRoles[roleLevel] ? this.state.userRoles[roleLevel] : {read: false, write: false }
  currentRole[readOrWrite] = value

  let tempUserRoles = this.state.userRoles
  tempUserRoles[roleLevel] = currentRole

  let updates = {}
  updates['/users/' + this.props.user.meta.uid + '/userRoles/' + roleLevel + '/' + readOrWrite ] = value

  firebase.database().ref().update(updates).then( ()=>{
    this.setState({buttonStatus: 'saved', userRoles: tempUserRoles})
  }).catch( (err)=> {
    this.setState({buttonStatus: 'regular', errorMessage: err.message})
    console.log(err)
  })


}


render(){

  let defaultRoles = ['campaigns', 'parameters', 'partners', 'users']

  let listRoles = defaultRoles.map( key => {
    let current = this.state.userRoles[key] ? this.state.userRoles[key] : {read: false, write: false }
    return (
        <div className='row col-12' key={key}>
          <div className='col-12'>
            <b style={{textTransform: 'capitalize'}}>{key}</b>
          </div>
          <div className='col-6'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={current.read}
                  onChange={ ()=> this.handleChange(key, 'read', !current.read)}
                  color="primary"
                />
              }
              label="Read"
            />
          </div>
          <div className='col-6'>
            <FormControlLabel
              control={
                <Checkbox
                  checked={current.write}
                  onChange={ ()=> this.handleChange(key, 'write', !current.write)}
                  color="primary"
                />
              }
              label="Write"
            />
          </div>
        </div>
      )
  })

      return (

        <ExpansionPanel>

          <ExpansionPanelSummary expandIcon={<i className="material-icons" >keyboard_arrow_down</i>}>
              <div>
                <i className="material-icons" >account_circle</i>
              </div>
              <div >
                <h5 style={{margin: '0px'}}>{this.props.user.meta.email}</h5>
                <label>{this.props.user.meta.company}</label>
              </div>

          </ExpansionPanelSummary>
          <ExpansionPanelDetails>

            <div className='row'>
              {listRoles}
            </div>

            {this.state.errorMessage && <div className='row'>{this.state.errorMessage}</div>}


        </ExpansionPanelDetails>
        </ExpansionPanel>

      )

  }
}

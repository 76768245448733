import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import BoxTactic from './boxtactic';
import DialogTitle from '@material-ui/core/DialogTitle';
import TacticItem from './tacticitem'

export default class MediaPlan extends Component {

    constructor(props) {
      super(props);
      this.state = { mediaPlan: 'loading', boxTactic: false}
    }

    componentWillUnmount(){
      if (this.state.firebaseListener){
        this.state.firebaseListener.off()
      }
    }

    componentDidMount(){

      let campaignName

      firebase.database().ref('/campaigns/' + this.props.ssxId + '/details/campaignName').once('value').then( snap => {
        if ( !snap.val()){ throw new Error('Error, no campaign data.') }

        campaignName = snap.val()
        this.setState({ campaignName: campaignName, errorMessage: null })

        let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssxId + '/mediaPlan')

        firebaseListener.on('value', (snap)=> {

            let tempmediaPlan = []
            snap.forEach( current => {
                tempmediaPlan.push(current.val())
              })
          this.setState({ mediaPlan: tempmediaPlan })
        })

      }).catch( error => {
        console.log(error)
        this.setState({ errorMessage: error.message })
      });

    }

    passedSetState = (state, value) =>{
      this.setState({[state]: value})
    }

    createNewTactic = () =>{

      let tacticId
      firebase.database().ref('util/nextTacticId').transaction( (id) => {
        console.log('StrategyId: ' + id)
            //if (!id) { throw {message: 'Error connect to database. Please try again later.'} }
            tacticId = 'cd' + id
            return id + 1

      }).then( ()=> {

      this.setState({boxTactic: true, tacticId: tacticId})

      })
    }


render(){

  if (this.state.mediaPlan ==='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress />
        {this.state.errorMessage}
      </div>
    );
  }  else  {

      let tactics = this.state.mediaPlan.map(current =>{
        return <TacticItem key={current.details.id} currentTactic={current} {...this.props}/>
      })

      return (
        <div className='row' style={{margin: '0px', padding: '20px'}}>

              <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                <h5><b>Campaign:</b> {this.state.campaignName}</h5>
                <b style={{fontSize: '20px'}}>Tactics:</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={this.createNewTactic}>add_box</i> <br />
              </div>

              <div className='row' style={{margin: '0px', backgroundColor:'', borderRadius:'1px', padding: '5px', width: '100%', border: '2px solid grey'}} >
                  <div className='col-1  d-flex align-items-center'>
                    <i className="material-icons">live_tv</i>
                  </div>
                  <div className='col-7  d-flex align-items-center'>
                    <b>Item</b>
                  </div>
                  <div className='col-2  d-flex align-items-center'>
                    <b>Total Spend</b>
                  </div>
                  <div className='col-2  d-flex align-items-center'>
                    <b>Estimated Impressions</b>
                  </div>
              </div>

                  <div className='col-12' style={{padding: '0px', margin: '0px'}}>
                    {tactics}
                  </div>

              <Dialog onClose={ () => this.setState({boxTactic: false}) } open={this.state.boxTactic} >
                <DialogTitle>Add Tactic</DialogTitle>
                <DialogContent>
                <BoxTactic  {...this.props} passedSetState={this.passedSetState} tacticId={this.state.tacticId} />
                </DialogContent>
              </Dialog>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import Checkbox from '@material-ui/core/Checkbox';

export default class Item extends Component {

    constructor(props) {
      super(props);
      this.state = { item: 'loading', errorMessage: null}
    }



render(){

  let {current} = this.props
  //let highlightResult = current._highlightResult && current._highlightResult.item_name ? current._highlightResult.item_name.value : current.item_name
  //let title = <b>{highlightResult}</b>

  //<a href={'https://www.walmart.com/ip/' + current.item_nbr} target='_blank'>{current.item_name}</a>
      return (
        <div className='row' style={{margin: '0px', padding: '3px', border: '1px solid #ddd', display: 'flex'}}>
            <div style={{width: '48px', height: '48px'}}>
            <Checkbox
              checked={this.props.checked}
              onChange={ ()=> this.props.checkboxClicked(current)}
              value="checkedA"
              color="secondary"
            />
            </div>
            <div className='row no-gutters' style={{flex: 1, padding: '5px 0px 0px 0px'}}>
                <div className='col-12' style={{fontSize: '12px', padding: '0px'}} >
                  <a href={'https://www.walmart.com/ip/' + current.wmt_item_id} target='_blank'>{current.item_name}</a>
                </div>
                <div className='col-12' style={{fontSize: '10px', padding: '0px'}} >
                  Item Id: {current.wmt_item_id}<br />
                  UPC: {current.upc}
                </div>
            </div>
        </div>
      )
  }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import LoadingButton from '../../../util/loadingbutton'
import TacticFrom from './tacticform'

export default class BoxCreateTactic extends Component {

    constructor(props) {
      super(props);
      let  details  = this.props.currentTactic &&  this.props.currentTactic.details ? this.props.currentTactic.details :  null
      let metrics = this.props.currentTactic &&  this.props.currentTactic.metrics ? this.props.currentTactic.metrics :  null
      let tacticName = details && details.tacticName ? details.tacticName : ''
      let spendType = details && details.spendType ? details.spendType : ''
      let tacticType = details && details.tacticType ? details.tacticType : ''
      let startDate = details && metrics.startDate ? new Date(metrics.startDate) : null
      let endDate = details && metrics.endDate ? new Date(metrics.endDate) : null
      let spend = metrics && metrics.spend ? metrics.spend : 0.00
      let impressions = metrics && metrics.impressions ? metrics.impressions : 0
      let comments = details && details.comments ? details.comments : ''
      let destinationUrls = details && details.destinationUrls ? details.destinationUrls : ''

      this.state = { buttonStatus: 'regular', tacticName: tacticName, spendType: spendType ,
      tacticType: tacticType, startDate: startDate, endDate: endDate, spend: spend, impressions: impressions,
      comments: comments, destinationUrls: destinationUrls };
    }

    submit =(e) =>{
      e.preventDefault()
      let {tacticName, spendType, tacticType, startDate, endDate, spend, impressions, comments, destinationUrls} = this.state


      if ( tacticName === '' || spendType === '' || tacticType === '' || startDate == null || endDate == null || spend === 0.00  ){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide all inputs</b>})
      }  else if ( startDate > endDate ){
        this.setState({errorMessage: <b style={{color: "red"}}>End date must come after start date</b>})
      }else  {

          this.setState({buttonStatus: 'loading'})
          //unique key unique to the entire world/db

          let metrics = {
            spend: spend,
            impressions: impressions,
            startDate: startDate.getTime(),
            endDate: endDate.getTime(),
          }
          let details = {
            tacticName: tacticName,
            id: this.props.tacticId,
            spendType: spendType,
            tacticType: tacticType,
            comments: comments,
            destinationUrls: destinationUrls
          }

          return firebase.database().ref('/campaigns/' + this.props.ssxId + '/mediaPlan/' + this.props.tacticId ).set({details: details, metrics: metrics}).then( ()=>{
            this.setState({buttonStatus: 'saved', errorMessage: null} , this.props.passedSetState('boxTactic', false) )
          }).catch( (err)=> {
            this.setState({errorMessage: err})
            console.log(err)
          })

      }


    }

    passedSetState = (state,value) => {
      this.setState({[state]: value})
    }


 render(){

    return(

    <div className='row' style={{padding: '10px 30px'}}>

        <div className="col-12" >

                  <TacticFrom {...this.state} {...this.props} passedSetState={this.passedSetState}  />

                  <div className="col-12 d-flex justify-content-end">
                    {this.state.errorMessage}
                  </div>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '10px 0px'}}>
                    <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Save' savedLabel='Success!' regularButtonClick={this.submit} savedButtonClick={ ()=> {return}} />
                  </div>
          </div>


      </div>

      )
    }
}

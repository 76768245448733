import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import LoadingButton from '../../util/loadingbutton.js';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';

export default class Campaign extends Component {

    constructor(props) {
      super(props);
      this.state = { contacts: 'loading', contactEmail: ''  }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

    addContact = () =>{
      //get post key
      //add them and let listener to its thing
    }


    componentDidMount(){

      /*
      let contacts = []
      //let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssxId + '/uploadFolder/' )
      let firebaseListener = firebase.database().ref(this.props.listFilesRef)
        firebaseListener.on('value', (snap)=> {
          let fileUploads = []
          snap.forEach( current=> {
            contacts.push(current.val())
          })
          this.setState({ contacts: contacts, firebaseListener: firebaseListener})
        });
        */

        this.setState({ contacts: [{email: 'fwtrapp@gmail.com'}] })

    }


render(){

  if (this.state.contacts === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      let contacts = this.state.contacts.map( current => {

        return (
          <MenuItem value={current.email} key={current.email} >
            <CopyToClipboard text={current.email}
              onCopy={() => this.setState({snackbarOpen: true})}>
               <ListItemText primary={current.email} />
            </CopyToClipboard>
          </MenuItem>
              )
          })


      return (

        <div className='row no-gutters' style={{width: '100%'}}>
            <div className='col-12' style={{ padding: '0px'}}>
              <b style={{fontSize: '20px'}}>Contacts</b>
              <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({addContactBoxOpen: true})}>add_box</i> <br />
              {contacts}
            </div>


            <Dialog onClose={ ()=> this.setState({ addContactBoxOpen: false})} open={ this.state.addContactBoxOpen === true  } >
              <DialogTitle >Add Contact</DialogTitle>
              <div className='row no-gutters' style={{padding: '0px 20px 20px 20px'}}>

                <div className='col-12' style={{padding: '10px'}}>
                  <TextField
                      onChange={ (e) => this.setState({contactEmail: e.target.value}) }
                      value={this.state.contactEmail}
                      label="Contact Email"
                      fullWidth
                  />
                </div>

                <div className="col-12 d-flex justify-content-center " style={{padding: '0px'}}>
                    <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Add Contact' savedLabel='Added!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' regularButtonClick={event => this.addContact(event)} savedButtonClick={ ()=> this.setState({addContactBoxOpen: false, contactEmail: ''})} />
                </div>

              </div>
            </Dialog>

              <Snackbar
                open={this.state.snackbarOpen}
                style={{width:'50%'}}
                message="Copied to clipboard"
                autoHideDuration={700}
                onClose={ ()=> this.setState({snackbarOpen: false})}
              />

        </div>
      )
    }
  }
}

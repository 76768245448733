import React, {Component} from 'react';
import ListStrategies from './liststrategies'
import UpdateStrategy from './updatestrategy'
import QueuedStategies from './queuedstategies'

export default class CampaignStrategies extends Component {

    constructor(props) {
      super(props);
      this.state = { selectedStrategy: null, queuedItems: {}, arrayOfStrategyIds: [] }
    }

    componentDidMount(){
      let array = []
      let mm_strategies = this.props.mm_strategies
      for (let key in mm_strategies) {
          array.push(mm_strategies[key].strategy_id)
      }

      this.setState({arrayOfStrategyIds : array })
    }



    passedSetState = (state, value) => {
      this.setState({[state]: value})
    }

    //this is used by the back and next button to quickly get from strategy to the next
    passedAddedToQUpdateSelected = (nextStrategy_id) => {

      let mm_strategies = this.props.mm_strategies
      let nextselectedStrategy = mm_strategies[nextStrategy_id]
      this.setState({selectedStrategy: nextselectedStrategy  })

    }

render(){

let selectedStrategy = this.state.selectedStrategy
      return (
        <div className='row' style={{margin: '0px', padding: '10px'}}>

              <div className='col-4'>
              <h4 className='d-flex justify-content-center'>Strategies</h4>
                <ListStrategies mm_strategies={this.props.mm_strategies} passedSetState={this.passedSetState} selectedStrategy={selectedStrategy}/>
              </div>


              <div className='col-8'>
                <div className='row' style={{maring: '0px', padding: '0px'}}>

                    <div className='col-12'>
                      <QueuedStategies queuedItems={this.state.queuedItems} passedSetState={this.passedSetState} mm_strategies_queue={this.props.mm_strategies_queue} ssx_id={this.props.ssx_id}/>
                    </div>

                    <div className='col-12 ' style={{padding: '10px'}}>
                      {selectedStrategy ?
                        <UpdateStrategy key={this.state.selectedStrategy.strategy_id}
                        {...this.state} {...this.props}
                        passedSetState={this.passedSetState}
                        passedAddedToQUpdateSelected={this.passedAddedToQUpdateSelected}
                        />
                        :
                      null
                      }
                    </div>
                </div>

              </div>
        </div>
      )
    }
  }

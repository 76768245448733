import React, {Component} from 'react';
import TextField from 'material-ui/TextField';
import * as firebase from 'firebase';
import DatePicker from 'material-ui/DatePicker';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';

export default class OptimizationItem extends Component {

    constructor(props) {
      super(props);
      let current = this.props.current
      console.log(current)
      this.state = { editing: false, comments: current.comments, optimizationDate: new Date(current.optimizationDate)  }
    }

saveUpdates = () => {

  let updates = {}
  var today = Date.now();

  updates['/campaigns/' + this.props.ssx_id + '/optimizations/' + this.props.current.id + '/dateEdited'] = today
  updates['/campaigns/' + this.props.ssx_id + '/optimizations/' + this.props.current.id + '/comments'] = this.state.comments
  updates['/campaigns/' + this.props.ssx_id + '/optimizations/' + this.props.current.id + '/editedBy'] = this.props.email
  updates['/campaigns/' + this.props.ssx_id + '/optimizations/' + this.props.current.id + '/optimizationDate'] = this.state.optimizationDate.getTime()

  firebase.database().ref().update(updates).then( ()=> {
      this.setState({editing: false, errorMessage: null})
  }).catch( (err)=> {
      this.setState({editing: false, errorMessage: err.message})
      console.log(err)
  })

}

render(){

let current = this.props.current
let email = this.props.email.split("@")


  if (this.state.editing == false){
    return (
              <div className='row' style={{padding: '5px', margin: '0px', border: '2px #ddd solid', borderRadius: '2px'}}>
                <div className='col-1 d-flex align-items-center' style={{}}>
                  <i style={{color: 'green'}}className="material-icons" >show_chart</i>
                </div>
                <div className='col-3 d-flex align-items-center' style={{}}>
                  {new Date(current.optimizationDate).toLocaleDateString("en-US")}
                </div>
                <div className='col-7 ' style={{}}>
                  {current.comments}<br />
                  <label style={{fontSize: '10px', color: 'grey', margin: '0px'}}> edited by {email[0]}</label>
                </div>
                <div className='col-1 d-flex align-items-center' >
                  <IconMenu
                    iconButtonElement={<i style={{ cursor: 'pointer'}} className="material-icons" >more_horiz</i>}
                  >
                    <MenuItem primaryText="Edit" onClick={ ()=> this.setState({editing: true})} />
                    <MenuItem primaryText="Delete" onClick={ ()=> firebase.database().ref('/campaigns/' + this.props.ssx_id + '/optimizations/' + this.props.current.id).remove() } />
                  </IconMenu>

                </div>
              </div>
            )
      } else {

      return (
        <div className='row' style={{padding: '5px', margin: '0px', border: '2px #ddd solid', borderRadius: '2px'}}>
            <div className='col-1 d-flex align-items-center justify-content-center' >
              <i style={{color: 'green'}}className="material-icons" >show_chart</i>
            </div>
            <div className='col-3 d-flex align-items-center' >
              <DatePicker
                  floatingLabelText="Date"
                  dialogContainerStyle={{backgroundColor: ''}}
                  hintText="Start Date"
                  value={this.state.optimizationDate}
                  autoOk={true}
                  fullWidth={true}
                  onChange={ (hold, date)=> this.setState({optimizationDate: date}) }
               />
            </div>
            <div className='col-7 d-flex align-items-center' >
                <TextField
                  floatingLabelText="Comments"
                  id="comments"
                  multiLine={true}
                  fullWidth={true}
                  onBlur={ ()=> console.log('BLUR')}
                  value={this.state.comments}
                  onChange={ (event, value) =>  { console.log(event)
                    this.setState({ comments: value}) }
                  }
                />
            </div>

            <div className='col-1 d-flex align-items-center' >
              <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={this.saveUpdates} >check_circle</i> <br />
              <i style={{color: 'red', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({editing: false})}>clear</i> <br />
            </div>
        </div>
      )
    }
  }
}

import React from 'react';
import ParameterItem from './parameteritem'

export default function ListParameters(props) {

  console.log(props)

    let parameters = []
    for (let key in props.parameters){
        let current = props.parameters[key]

        parameters.push(
          <div className='col-4 d-flex align-items-center' key={current.id} style={{padding: '10px'}}>
            <ParameterItem currentParameter={current}  paramterValue={ props[current.id] } {...props} />
          </div>
        )
    }

    return(

      <div className='row no-gutters' style={{padding: '20px', width: '100%'}}>
          {parameters}
      </div>

      )

}

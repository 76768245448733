import React, {Component} from 'react';
import LinearProgress from 'material-ui/LinearProgress';
import * as firebase from 'firebase';
import Dialog from 'material-ui/Dialog';
import BoxCreateOptimization from './boxcreateoptimization'
import OptimizationItem from './optimizationitem'

export default class EditOptimizations extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignData: 'loading', ssx_id: this.props.ssx_id, createOptimizationBoxOpen: false }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
      this.state.firebaseListener.off()
    }

    componentDidMount(){

      let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssx_id)
      firebaseListener.on('value', (snap)=> {
        let tempArray = []
        let optimizations = snap.val() && snap.val().optimizations ? snap.val().optimizations : null

          for (let current in optimizations){
            tempArray.push(optimizations[current])
          }

        this.setState({campaignData: snap.val(), optimizations: tempArray, firebaseListener: firebaseListener})
      });

    }

    handleDialogClose = () => this.setState({createOptimizationBoxOpen: false}) ;

    passedSetState = (state, value) => {

      this.setState({[state]: value})
    }

render(){

  if (this.state.campaignData =='loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {


      const optimizationItems = this.state.optimizations.map((current, index) => {
            return (
              <div key={index} >
                <OptimizationItem current={current} ssx_id={this.props.ssx_id} email={this.props.email}/>
              </div>
            )
        })

      return (
        <div className='row' style={{margin: '0px', padding: '40px'}}>

              <div className='col-12' style={{paddingTop: '5px'}}>
              <h4>{this.state.campaignData.campaignName}</h4>
                <b style={{fontSize: '20px'}}>Optimizations:</b>
                <i style={{color: '#4caf50', cursor: 'pointer'}} className="material-icons" onClick={ ()=> this.setState({createOptimizationBoxOpen: true})}>add_box</i> <br />
              </div>

              <div className='col-12' style={{padding: '0px'}}>

                    <div className='row' style={{padding: '5px', margin: '0px', border: '2px black solid', borderRadius: '2px'}}>
                      <div className='col-1 d-flex align-items-center' style={{}}>
                        <i style={{color: 'green'}}className="material-icons" >show_chart</i>
                      </div>
                      <div className='col-3 d-flex align-items-center' style={{}}>
                        <b>Date</b>
                      </div>
                      <div className='col-7 d-flex align-items-center' style={{}}>
                        <b>Comments</b>
                      </div>
                    </div>

                    {optimizationItems}

              </div>

              <Dialog
                  title='Add Optimization Note'
                  modal={false}
                  open={ this.state.createOptimizationBoxOpen == true  }
                  onRequestClose={this.handleDialogClose} >
              <BoxCreateOptimization email={this.props.email} ssx_id={this.props.ssx_id} campaignOptimizations={this.state.campaignOptimizations} passedSetState={this.passedSetState} email={this.props.email}   />
              </Dialog>

        </div>
      )
    }
  }
}

import React, {Component} from 'react';
import * as firebase from 'firebase';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListUser from './listUser'
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import CreateUserBox from './createUserBox'

export default class Users extends Component {

    constructor(props) {
      super(props);
      this.state = { users: 'loading', dialogOpen: false}
    }


    componentDidMount(){

      console.log('componentDidMount')

      try{

            firebase.database().ref('/users').once('value').then( snap => {
              if ( !snap.val()){
                throw new Error('Error, no user data.')
              }

              let tempArray = []
              snap.forEach( current=> {
                tempArray.push(current.val())
              })

              this.setState({users: tempArray })
            }).catch( err => {
              console.error(err.message)
              this.setState({campaign: 'loading', errorMessage: err.message})
            })

        } catch (err){
          console.log(err)
        }

    }

  handleDialogClose = () => {
    this.setState({ dialogOpen: false})
  }

render(){

  console.log(this.state.users)

  if (this.state.users === 'loading'){
    return (
      <div className='row col-12' style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    );
  }  else  {

      let listUsers = this.state.users.map(( user, index) => {
          return <ListUser key={user.meta.uid} user={user} />
      });


      return (
      <div className='row no-gutters' style={{margin: '0px', padding: '10px'}}>

        <div className='col-12' style={{ padding: '10px 0px'}}>
          <Button variant="raised" color="primary" onClick={ ()=> this.setState({dialogOpen: true})}>
            Add User
          </Button>
        </div>

        <div className='col-12' style={{ padding: '10px 0px'}}>
          {listUsers}
        </div>

        <Dialog onClose={this.handleDialogClose} open={this.state.dialogOpen} >
          <DialogTitle >Add User</DialogTitle>
          <div>
            <CreateUserBox {...this.props} handleDialogClose={this.handleDialogClose}/>
          </div>
        </Dialog>

      </div>
      )
    }
  }
}

import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import CampaignInfo from './campaigninfo';

export default class EditCampaign extends Component {

    constructor(props) {
      super(props);
      this.state = { campaignData: 'loading' }
    }

    componentWillUnmount(){
      //stop listenting for this campaign
      this.state.firebaseListener.off()
    }

    componentDidMount(){

      let firebaseListener = firebase.database().ref('/campaigns/' + this.props.ssx_id)
      firebaseListener.on('value', (snap)=> {
        console.log(snap.val())

      let data  = snap.val() && snap.val().details ? snap.val().details : null
      this.setState({campaignData: data, firebaseListener: firebaseListener})

      });

    }

render(){

  console.log(this.props)

  if (this.state.campaignData === 'loading'){
    return (
      <div style={{padding: '20px'}}>
        <LinearProgress  />
      </div>
    );
  }  else if (this.state.campaignData == null){
    return (
      <div style={{padding: '20px'}}>
        <h5>Campaign does not exist</h5>
      </div>
    );
  } else  {

      return (
        <div className='row' style={{maring: '0px', padding: '20px'}}>
              <div className='col-12'>
                <CampaignInfo ssx_id={this.state.ssx_id} campaignData={this.state.campaignData} {...this.props}/>
              </div>
        </div>
      )
    }
  }
}

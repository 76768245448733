import React, {Component} from 'react';
import BoxStrategyDetails from './boxstrategydetails'
import {CopyToClipboard} from 'react-copy-to-clipboard';
import IconButton from 'material-ui/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class StrategyItem extends Component {

    constructor(props) {
      super(props);
      this.state = { boxStrategyDetails: false }
    }


handleDialogClose = () => this.setState({boxStrategyDetails: false}) ;


render(){

let current = this.props.currentStrategy.details
let name = current.strategyId +"_"+ current.targetingChannel+"-"+current.partner+"-"+current.targetSegment+"-"+current.device+"-"+current.mediaType
    return (
      <div className='row boxBorder' style={{margin: '0px', backgroundColor:'', borderRadius:'1px', padding: '10px', width: '100%'}} >
          <div className='col-12  d-flex align-items-center'  >
            <CopyToClipboard text={name}>
              <IconButton iconClassName="material-icons" tooltip="Copy">insert_chart</IconButton>
            </CopyToClipboard>
            <label className='label'>{name}</label>
            <i className="material-icons" style={{padding: '5px',cursor: 'pointer', color: '#3E8EDE', fontSize: '18px'}} onClick={ ()=> this.setState({ boxStrategyDetails: !this.state.boxStrategyDetails  }) } >edit</i>
          </div>

          <Dialog open={ this.state.boxStrategyDetails === true  } onClose={this.handleDialogClose} >
            <DialogTitle>{current.targetingChannel + '-' + current.partner+ '-' +current.targetSegment+ '-' +current.device+ '-' + current.mediaType }</DialogTitle>
            <DialogContent>
              <BoxStrategyDetails campaignDetails={this.props.campaignDetails} currentStrategy={this.props.currentStrategy} strategyId={current.strategyId} reportId={this.props.reportId} parameters={this.props.strategyParameters.parameters} passedSetState={this.props.passedSetState}    />
            </DialogContent>
          </Dialog>

      </div>
            )
  }
}

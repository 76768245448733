import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DatePicker from 'material-ui/DatePicker';

export default function TacticForm(props) {
    return(



    <div className='row' style={{padding: '10px 30px'}}>

        <div className="col-12 row no-gutters" >

                  <div className='col-12' style={{padding: '5px'}}>
                    <TextField
                        fullWidth={true}
                        onChange={ (e) => props.passedSetState('tacticName', e.target.value) }
                        placeholder="Braun catapult"
                        label="Tactic Name"
                        value={props.tacticName}
                    />
                  </div>

                  <FormControl className='col-6' style={{padding: '5px'}}>
                    <InputLabel style={{paddingLeft: '5px'}} >Spend Type</InputLabel>
                      <Select value={props.spendType} autoWidth
                        onChange={ (e) => props.passedSetState('spendType', e.target.value) }>
                        <MenuItem value={'Coop'}>Coop</MenuItem>
                        <MenuItem value={'Triad'}>Triad</MenuItem>
                        <MenuItem value={'SSX'}>SSX</MenuItem>
                      </Select>
                  </FormControl>

                  <FormControl className='col-6' style={{padding: '5px'}}>
                    <InputLabel style={{paddingLeft: '5px'}} >Tactic Type</InputLabel>
                      <Select value={props.tacticType} autoWidth
                        onChange={ (e) => props.passedSetState('tacticType', e.target.value) }>
                        <MenuItem value={'POV'}>POV</MenuItem>
                        <MenuItem value={'Catapult'}>Catapult</MenuItem>
                        <MenuItem value={'WPA'}>WPA</MenuItem>
                        <MenuItem value={'Display'}>Display</MenuItem>
                        <MenuItem value={'Dynamic'}>Dynamic</MenuItem>
                        <MenuItem value={'Email'}>Email</MenuItem>
                        <MenuItem value={'Social'}>Social</MenuItem>
                        <MenuItem value={'Affiliate'}>Affiliate</MenuItem>
                        <MenuItem value={'PLA'}>PLA</MenuItem>
                        <MenuItem value={'Influencer'}>Influencer</MenuItem>
                        <MenuItem value={'Radio'}>Radio</MenuItem>
                        <MenuItem value={'Billboard'}>Billboard</MenuItem>
                        <MenuItem value={'TV'}>TV</MenuItem>
                        <MenuItem value={'Website'}>Website</MenuItem>
                        <MenuItem value={'Promotion'}>Promotion</MenuItem>
                      </Select>
                  </FormControl>

                  <div className='col-6 d-flex' style={{padding: '5px'}}>
                    <DatePicker
                        dialogContainerStyle={{backgroundColor: ''}}
                        autoOk={true}
                        floatingLabelText='Start Date'
                        value={props.startDate}
                        onChange={ (hold, date)=> props.passedSetState('startDate', date) }
                     />
                  </div>

                  <div className='col-6 d-flex' style={{padding: '5px'}}>
                    <DatePicker
                        floatingLabelText='End Date'
                        autoOk={true}
                        value={props.endDate}
                        onChange={ (hold, date)=> props.passedSetState('endDate', date) }
                     />
                  </div>

                  <div className='col-6' style={{padding: '5px'}}>
                    <TextField
                        fullWidth={true}
                        onChange={ (e) => props.passedSetState('spend', e.target.value) }
                        placeholder="Braun catapult"
                        label="Total Spend"
                        type='number'
                        value={props.spend}
                    />
                  </div>

                  <div className='col-6' style={{padding: '5px'}}>
                    <TextField
                        fullWidth={true}
                        onChange={ (e) => props.passedSetState('impressions', e.target.value) }
                        placeholder="Braun catapult"
                        label="Estimated Impressions"
                        type='number'
                        value={props.impressions}
                    />
                  </div>

                  <div className='col-12' style={{padding: '5px'}}>
                    <TextField fullWidth={true} onChange={ (e) => props.passedSetState('comments', e.target.value) }
                        placeholder="Bought all shave terms" label="Comments" value={props.comments}
                    />
                  </div>

                  <div className='col-12' style={{padding: '5px'}}>
                    <TextField multiline fullWidth={true} row={3}
                        onChange={ (e) => props.passedSetState('destinationUrls', e.target.value) }
                        label="Destination Urls" value={props.destinationUrls}
                    />
                  </div>

          </div>


      </div>

      )
}

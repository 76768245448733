import React, {Component} from 'react';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import * as firebase from 'firebase';
import LinearProgress from 'material-ui/LinearProgress';

export default class BoxCreateExternalReport extends Component {

    constructor(props) {
      super(props);
      this.state = { reportName: '', partner: '', loading: false };
    }

    submit(e){
      e.preventDefault()

      if ( this.state.partner === '' || this.state.reportName === ''  ){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide all inputs</b>})
      }  else  {

          //unique key unique to the entire world/db
          let newReportId = firebase.database().ref().push().key;

          return firebase.database().ref('/campaigns/' + this.props.ssx_id + '/externalReports/' + newReportId ).set({ reportName: this.state.reportName, partner: this.state.partner, id: newReportId}).then( ()=>{
            this.setState({loading: false, errorMessage: null} , this.props.passedSetState('createExternalReportBoxOpen', false) )
          }).catch( (err)=> {
            this.setState({errorMessage: err})
            console.log(err)
          })

      }


    }


render(){

    return(

      <div className='row' style={{padding: '20px'}}>

        <div className="col-12" >

                  <form onSubmit={ event => console.log('fire') } style={{width: '100%', padding: '0px', margin: '0px'}} className="row" >

                  <div className='col-12'>
                    <TextField
                        onChange={ (event, value) => this.setState({ reportName: value}) }
                        hintText="Facebook Social"
                        floatingLabelText="Report Name"
                        value={this.state.reportName}
                    />
                  </div>

                  <div className='col-12'>
                    <TextField
                        onChange={ (event, value) => this.setState({ partner: value}) }
                        hintText="Facebook"
                        floatingLabelText="Partner"
                        value={this.state.partner}
                    />
                  </div>


                  <div className="col-12">
                  {this.state.errorMessage}
                  </div>
                  </form>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '10px 0px'}}>
                        {this.state.loading === false ?
                          <RaisedButton
                              label="Add"
                              fullWidth={false}
                              secondary={true}
                              type="submit"
                              onClick={ event => this.submit(event) }
                              />
                        :
                          <RaisedButton
                            style={{height: '100%', backgroundColor: 'white'}}
                            secondary={true}
                            children={ <div style={{padding: '2px', backgroundColor: 'white'}} key={1}><LinearProgress /></div>}
                            fullWidth={false}
                           />
                        }
                    </div>
          </div>

      </div>

      )
    }
}

import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';

export default function ParameterItem(props) {

    let currentParameter = props.currentParameter
    let type = currentParameter.type
    let paramterValue = props.paramterValue
    let menuItems = []

    //BA form has custom sizes aka abilty to select multiple
    if (currentParameter.id ==='size' && props.passedFromBaForm){

          for (let key in currentParameter.options){
            let current = currentParameter.options[key]
            menuItems.push(
              <MenuItem value={current.value} key={current.value} >
                  <Checkbox checked={ props.itemChecked(current.value)} />
                  <ListItemText primary={current.label} />
              </MenuItem>
                          )
          }

          return (
            <FormControl style={{width: '100%'}}>
                <InputLabel shrink={true}>Sizes</InputLabel>
                <Select multiple value={props.sizes}
                    onChange={ (e) => props.passedSetState( 'sizes', e.target.value ) }
                    renderValue={selected => selected.join(', ')}
                  >
                  {menuItems}
                  </Select>
            </FormControl>
          )

    } else if (type === 'select'){

        for (let key in currentParameter.options){
          let current = currentParameter.options[key]
          menuItems.push(<MenuItem value={current.value} key={current.value}>{current.label}</MenuItem>)
        }

        return (
          <FormControl style={{width: '100%'}}>
              <InputLabel shrink={true}>{currentParameter.title}</InputLabel>
              <Select value={paramterValue} onChange={ (e) => props.passedSetState( currentParameter.id, e.target.value ) }>
                <MenuItem disabled={true} value={paramterValue} >{paramterValue}</MenuItem>
                {menuItems}
              </Select>
          </FormControl>
        )

    } else {

        return(
          <TextField fullWidth
              value={paramterValue} onChange={ (e) => props.passedSetState( currentParameter.id, e.target.value ) }
              placeholder="Bluekai"
              label={currentParameter.title}
              InputLabelProps={{ shrink: true}}
          />
        );
    }
  }

import React, {Component} from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as firebase from 'firebase';
import Button from '@material-ui/core/Button';

export default class UploadFiles extends Component {

    constructor(props) {
      super(props);
      this.state = { fileUploads: 'loading', loading: false}
    }

    componentWillUnmount(){
      //stop listenting for this campaign
        if (this.state.firebaseListener){
          this.state.firebaseListener.off()
        }
    }

    componentDidMount(){

        let firebaseListener = firebase.database().ref( this.props.firebasePath )
          firebaseListener.on('value', (snap)=> {
            let fileUploads = []
            snap.forEach( current=> {
              fileUploads.push(current.val())
            })
            this.setState({ fileUploads: fileUploads, firebaseListener: firebaseListener})
          });

          this.setState({ fileUploads: [] })

      }


      uploadReport = (e) =>{

        this.setState({loading: true})
        let updates = {}
        let promiseArray = []

        for (let i = 0; i < e.target.files.length; i++) {
        let file = e.target.files[i]

          var promise = new Promise( (resolve, reject)=> {
            let uploadKey = firebase.database().ref().push().key;
            let storageref = firebase.storage().ref(this.props.uploadPath + uploadKey +  '/' + file.name )
            let task = storageref.put(file)
            //not sure how to do fat arrow functions, so need to set this to self
            task.on('state_changed', (snapshot)=> { },  (err) =>{ console.log(err)  }, (data)=>{
              storageref.getDownloadURL().then( url => {
                updates[this.props.firebasePath  + uploadKey] = {url: url, fileName: file.name}
                resolve(true)
              }).catch( err=>{
                console.log(err)
                reject(err)
              })
            })
          })

          promiseArray.push(promise)

        }

        //once outside map. do promises then upload firebase url

        Promise.all(promiseArray).then( ()=>{

          return firebase.database().ref().update(updates)
        }).then( ()=>{
          this.setState({loading: false, errorMessage: null})
        }).catch( (err)=> {
          this.setState({loading: false, errorMessage: err.message})
          console.log(err)
        })



      }


render(){

  if (this.state.fileUploads === 'loading'){
    return(
      <div style={{padding: '20px'}}>
        <LinearProgress mode="indeterminate" />
      </div>
    )
  } else {

    const styles = {
      uploadInput: { display: 'none'},
                  };
    const fileUploads = this.state.fileUploads.map((current, index) => {
          return (
          <li key={current.url}>
            <a href={current.url} target='_blank' >{current.fileName}</a>
          </li>
          )
      })

      return (
    <div className='row' style={{padding: '0px', margin: '0px', height: '100%'}}>

        <div className='col-12' >
                {this.state.loading === true ?
                  <div style={{padding: '10px'}}>
                      <LinearProgress />
                  </div>
                  :

                  <div style={{padding: '10px'}}>

                    <input
                       style={styles.uploadInput}
                       id="flat-button-file"
                       multiple
                       type="file"
                       onChange={ this.uploadReport}
                     />

                    <label htmlFor="flat-button-file">
                      <Button component="span" >
                      <i className="material-icons" >file_upload</i> Upload File(s)
                      </Button>
                    </label>
                  </div>
                  }
          </div>

          <div className='col-12'>
            {fileUploads}
          </div>

      </div>

      )
    }
  }
}

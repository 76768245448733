import React, {Component} from 'react';
import * as firebase from 'firebase';
import LoadingButton from '../../../util/loadingbutton';
import Switch from '@material-ui/core/Switch';
import DatePicker from 'material-ui/DatePicker';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

export default class BoxCreateCampaign extends Component {

    constructor(props) {
      super(props);
      this.state = { errorMessage: null, loading: false, startDate:  new Date(), endDate: null, campaignName: '', client: '', brand: '', retailer: '', qrtyr_date: '', initiative: '', subgbu: '', externalCampaignId : "", poNumber: "", jobNumber:"", wmxEnabled : false, mmEnabled: false, runbySSX: true };
    }

    campaignSubmitted(e){
      e.preventDefault()

      if (!this.state.endDate || this.state.campaignName === ''){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide campaign name and end date</b>})
      }  else if (this.state.client === '' || this.state.brand === '' || this.state.retailer === '' || this.state.qrtyr_date === '' || this.state.initiative === '' ){
        this.setState({errorMessage: <b style={{color: "red"}}>Please make sure all field are filled out</b>})
      }  else if (this.state.client === 'PGWGCT' && this.state.subgbu === '' ){
        this.setState({errorMessage: <b style={{color: "red"}}>Please provide category</b>})
      } else  {

          this.setState({errorMessage: null, loading: true})
          var today = Date.now();

          //have to remove the timezone offset on dates. this makes it utc time
          //let tempStartDate = this.state.startDate.getTime() - this.state.startDate.getTimezoneOffset()*60000
          //let tempEndDate = this.state.endDate.getTime() - this.state.endDate.getTimezoneOffset()*60000

          //random campaign uid
          var campaignId

          return firebase.database().ref('util/nextCampaignId').transaction( (id) => {
            console.log('campaignId: ' + id)
                //if (!id) { throw {message: 'Error connect to database. Please try again later.'} }

                campaignId = id
                return id + 1
              }).then( ()=> {

          return firebase.database().ref('/campaigns/' + campaignId).set({
              details: {
                  //had this on for when lowes is creating campaigns
                  dateCreated : today,
                  //dateCreated : this.state.startDate.getTime(),
                  startDate: this.state.startDate.getTime(),
                  endDate: this.state.endDate.getTime(),
                  ssx_id: campaignId,
                  createdBy: this.props.email,
                  campaignName: this.state.campaignName,
                  client: this.state.client,
                  wmxEnabled: this.state.wmxEnabled,
                  mmEnabled: this.state.mmEnabled,
                  runbySSX: this.state.runbySSX,
                  brand: this.state.brand,
                  retailer: this.state.retailer,
                  subgbu: this.state.subgbu,
                  externalCampaignId: this.state.externalCampaignId,
                  poNumber: this.state.poNumber,
                  jobNumber: this.state.jobNumber

                      },
              searchData: {
                  objectID: campaignId,
                  details: {
                      ssx_id: campaignId,
                      objectID: campaignId,
                      campaignName : this.state.campaignName,
                      brand: this.state.brand,
                      startDate: this.state.startDate.getTime(),
                      endDate : this.state.endDate.getTime(),
                      createdBy :  this.props.email,
                      //had this on for when lowes is creating campaigns
                      dateCreated : today,
                      //dateCreated : this.state.startDate.getTime(),
                      runbySSX: this.state.runbySSX
                      },
                  warnings: {
                    mmId:  this.state.mmEnabled ,
                    wmxId: this.state.wmxEnabled
                  }
                        }

            })

        }).then( ()=>{
            this.props.history.push('/edit/campaigndetails/' + campaignId)
            //window.location = '/edit/campaigndetails/' + campaignId
          }).catch( (err)=> {
            this.setState({errorMessage: err})
            console.log(err)
          })

      }


    }

    updateCampaignName(parameter, value){

      this.setState({ [parameter]: value}, ()=> {
        let tempCampaignName = this.state.client + '-' + this.state.brand + '-' + this.state.retailer + '-' + this.state.initiative + '-' + this.state.qrtyr_date
        this.setState({campaignName: tempCampaignName})
      }
    )

    }


render(){

    return(

      <div className='row no-gutters' style={{padding: '40px'}}>

                  <div className='col-4 d-flex align-items-center' style={{padding: '10px'}}>
                  <FormControl  style={{width: '100%'}}>
                    <InputLabel>Client</InputLabel>
                    <Select value={this.state.client} onChange={ (e) => this.updateCampaignName( 'client', e.target.value ) }>
                        <MenuItem value={'PGWGCT'}>PGWGCT</MenuItem>
                        <MenuItem value={'PGSams'} >PGSams</MenuItem>                        
                        <MenuItem value={'PGOlay'}>PGOlay</MenuItem>
                        <MenuItem value={'CommunityCoffee'}>Community Coffee</MenuItem>
                        <MenuItem value={'Wendys'} >Wendys</MenuItem>
                        <MenuItem value={'PGOne'} >PGOne</MenuItem>
                    </Select>
                  </FormControl>
                  </div>

                  <div className='col-4 d-flex align-items-center' style={{padding: '10px'}}>
                  <FormControl  style={{width: '100%'}}>
                    <InputLabel>Retailer</InputLabel>
                    <Select value={this.state.retailer} onChange={ (e) => this.updateCampaignName( 'retailer', e.target.value ) }>
                        <MenuItem value={'Walmart'} > Walmart </MenuItem>
                        <MenuItem value={'Sams'} > Sams </MenuItem>
                        <MenuItem value={'Target'}> Target </MenuItem>
                        <MenuItem value={'Amazon'}> Amazon </MenuItem>
                        <MenuItem value={'Best Buy'} >Best Buy </MenuItem>
                        <MenuItem value={'Kroger'} >Kroger</MenuItem>
                        <MenuItem value={'Costco'} >Costco</MenuItem>
                        <MenuItem value={'Instacart'} >Instacart</MenuItem>
                        <MenuItem value={'Multi'} >Multi</MenuItem>
                        <MenuItem value={'Other'} >Other </MenuItem>
                    </Select>
                  </FormControl>
                  </div>

                  <div className='col-4 d-flex align-items-center' style={{padding: '10px'}}>
                  <FormControl  style={{width: '100%'}}>
                    <InputLabel>Quarter/Year</InputLabel>
                    <Select  value={this.state.qrtyr_date} onChange={ (e) => this.updateCampaignName( 'qrtyr_date', e.target.value ) }>
                        
                        <MenuItem value={'Q122'}>Q122</MenuItem>
                        <MenuItem value={'Q222'}>Q222</MenuItem>
                        <MenuItem value={'Q322'}>Q322</MenuItem>
                        <MenuItem value={'Q422'}>Q422</MenuItem>
                        <MenuItem value={'Q123'}>Q119</MenuItem>
                        <MenuItem value={'Q223'}>Q219</MenuItem>
                        <MenuItem value={'Q323'}>Q319</MenuItem>
                        <MenuItem value={'Q423'}>Q419</MenuItem>

                    </Select>
                  </FormControl>
                  </div>


                  {this.state.client === 'PGWGCT' ?
                  <div className='col-4 d-flex align-items-center' style={{padding: '10px'}}>
                  <FormControl  style={{width: '100%'}}>
                    <InputLabel>Category</InputLabel>
                    <Select  value={this.state.subgbu} onChange={ (e) => this.setState({ subgbu: e.target.value}) }>
                      <MenuItem value={'APDO'}  >APDO</MenuItem>
                      <MenuItem value={'Grooming'}  >Grooming</MenuItem>
                      <MenuItem value={'Oral Care'} >Oral Care</MenuItem>
                      <MenuItem value={'PCC'} >PCC</MenuItem>
                      <MenuItem value={'Home Care'}   >Home Care</MenuItem>
                      <MenuItem value={'Hair'}  >Hair</MenuItem>
                      <MenuItem value={'Family Care'}>Family Care</MenuItem>
                      <MenuItem value={'Skin Care'} >Skin Care</MenuItem>
                      <MenuItem value={'FFAI'} >FFAI</MenuItem>
                      <MenuItem value={'PHC'} >PHC</MenuItem>
                      <MenuItem value={'Hair'} >Hair</MenuItem>
                      <MenuItem value={'Baby Care'}  >Baby Care</MenuItem>
                      <MenuItem value={'Fabric Care'} >Fabric Care</MenuItem>
                      <MenuItem value={'Surface Care'} >Surface Care</MenuItem>
                      <MenuItem value={'Scale'} >Scale</MenuItem>
                      <MenuItem value={'Grocery'} >Grocery</MenuItem>
                      <MenuItem value={'Trial Travel'}  >Trial Travel</MenuItem>
                    </Select>
                  </FormControl>
                  </div>
                  :
                  null }

                  <div className='col-8 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        label='Primary Brand' fullWidth
                        onChange={ e => this.updateCampaignName( 'brand', e.target.value ) }
                        placeholder="Pampers"
                        value={this.state.brand}
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        onChange={ (e) => this.updateCampaignName( 'initiative', e.target.value ) }
                        placeholder="Scale Winter Olympics"
                        value={this.state.initiative}
                        label="Initiative"
                        fullWidth
                    />
                  </div>

                  <div className='col-12 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                        onChange={ (e) => this.setState({campaignName: e.target.value}) }
                        value={this.state.campaignName}
                        label="Campaign Name"
                        fullWidth
                    />
                  </div>

                  <div className='col-6 d-flex align-items-center' style={{padding: '10px'}}>
                    <DatePicker
                        autoOk={true}
                        floatingLabelText='Start Date'
                        value={this.state.startDate}
                        onChange={ (hold, date)=> this.setState({startDate: date}) }
                     />
                  </div>

                  <div className='col-6 d-flex align-items-center' style={{padding: '10px'}}>
                    <DatePicker
                        autoOk={true}
                        floatingLabelText="End Date"
                        value={this.state.endDate}
                        onChange={ (hold, date)=> this.setState({endDate: date}) }
                     />
                  </div>

                  <div className='col-6 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                          onChange={ (e) => this.setState({poNumber: e.target.value}) }
                          value={this.state.poNumber}
                          label="PO Number"
                          fullWidth
                      />
                  </div>

                  <div className='col-6 d-flex align-items-center' style={{padding: '10px'}}>
                    <TextField
                          onChange={ (e) => this.setState({jobNumber: e.target.value}) }
                          value={this.state.jobNumber}
                          label="Job Number"
                          fullWidth
                      />
                  </div>
  
                  {this.state.client === 'PGWGCT' || this.state.client === 'PGSams' ?
                      <>
                        <div className='col-6'>
                          <FormControlLabel
                            label="Run by National"
                            control={
                                <Switch
                                  checked={!this.state.runbySSX}
                                  onChange={ ()=> this.setState({runbySSX : !this.state.runbySSX})}
                                />
                              }
                            />
                        </div>
                        <div className='col-6' style={{marginLeft: '15px'}}>
                        <TextField
                              onChange={ (e) => this.setState({externalCampaignId: e.target.value}) }
                              value={this.state.externalCampaignId}
                              label="Campaign Id"
                              fullWidth
                          />
                      </div>
                    </>
                  : null }

                  <div className="col-12">
                      {this.state.errorMessage}
                  </div>

                  <div className="col-12 d-flex justify-content-end " style={{padding: '0px', marginTop: "15px"}}>
                      <LoadingButton buttonStatus={this.state.buttonStatus} regularLabel='Create Campaign' savedLabel='Created!' regularbuttonColor='#EB0028'  savedbuttonColor='#3E8EDE' regularButtonClick={event => this.campaignSubmitted(event)} savedButtonClick={ ()=> this.setState({buttonStatus: 'regular'})} />
                  </div>

      </div>

      )
    }
}

/*
{this.state.client === 'PGWGCT' || this.state.client === 'PGSams' ?
<div className='col-6 d-flex align-items-center'>
        <FormControlLabel
          label="Notify Morgan/WMX"
          control={
              <Switch
                checked={this.state.wmxEnabled}
                onChange={ ()=> this.setState({wmxEnabled : !this.state.wmxEnabled})}
              />
            }
          />
      </div>
: null }
*/
